import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";

export const fetchFrameGroupRequest = () => ({
    type: actionTypes.GET_ALL_FRAME_GROUP_REQUEST,
});

export const fetchFrameGroupSuccess = (data) => ({
    type: actionTypes.GET_ALL_FRAME_GROUP_SUCCESS,
    payload: data,
});

export const fetchFrameGroupFailure = (error) => ({
    type: actionTypes.GET_ALL_FRAME_GROUP_FAILURE,
    payload: error,
});

export const fetchFrameGroupData = () => {
    return async (dispatch) => {
        dispatch(fetchFrameGroupRequest());
        try{
            const data = await api.getAllFrameGroup();
            console.log("frame group action", data);
            dispatch(fetchFrameGroupSuccess(data));
            return data;
        }catch (error) {
            dispatch(fetchFrameGroupFailure(error));
        }
    };
};

export const createFrameGroupData = async (formData) => {
    const data = await api.createFrameGroup(formData)
    return data;
};

export const updateFrameGroupData = async (formData) => {
    const data = await api.updateFrameGroup(formData.id, formData);
    return data;
};

export const deleteFrameGroupData = async (id) => {
    const data = await api.deleteFrameGroup(id);
    return data;
};

export const getSingleFrameTemplate = async (id) => {
    const data = await api.getSingleFrameGroup(id);
    return data;
};
