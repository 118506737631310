import * as actionTypes from '../actionTypes.js';

const initialState = {
    allCardsData: null,
    loading: false,
    error: null,
};

const allCardsReducer = (state= initialState, action) => {
    switch(action.type) {
       
        case actionTypes.FETCH_ALL_CARDS_DATA_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.FETCH_ALL_CARDS_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                allCardsData: action.payload,
            };
        case actionTypes.FETCH_ALL_CARDS_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
      
           

        default:
            return state;
    }
};

export default allCardsReducer;
