import * as actionTypes from '../actionTypes.js';

const initialState = {
    cardsData: null,
    loading: false,
    error: null,
};

const cardsReducer = (state= initialState, action) => {
    switch(action.type) {
        case actionTypes.FETCH_CARDS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.FETCH_CARDS_SUCCESS:
            return {
                ...state,
                loading: false,
                cardsData: action.payload,
            };
        case actionTypes.FETCH_CARDS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case actionTypes.FETCH_ALL_CARDS_DATA_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.FETCH_ALL_CARDS_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                cardsData: action.payload,
            };
        case actionTypes.FETCH_ALL_CARDS_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case actionTypes.CREATE_CARDS_REQUEST:
            return{
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.CREATE_CARDS_SUCCESS:
            state.cardsData.data.rows = [...state.cardsData?.data?.rows,action.payload]
            return{
                ...state,
                loading: false,
                cardsData: state.cardsData,
            };
        case actionTypes.CREATE_CARDS_FAILURE:
            return{
                ...state,
                loading: false,
                error: action.payload
            };
        case actionTypes.SEND_TEMPLATE_ID_REQUEST:
            return{
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.SEND_TEMPLATE_ID_SUCCESS:
            state.cardsData.data.rows = state.cardsData?.data?.rows.map(card =>
                card.id === action.payload.id ? action.payload : card)
            return{
                ...state,
                loading: false,
                cardsData: state.cardsData,
            };
        case actionTypes.SEND_TEMPLATE_ID_FAILURE: 
            return{
                ...state,
                loading: false,
                error: action.payload,
            }
        case actionTypes.UPDATE_CARDS_REQUEST:
            return {
                ...state,
                loading: false,
                error: null,
            };
        case actionTypes.UPDATE_CARDS_SUCCESS:
            state.cardsData.data.rows = state.cardsData?.data?.rows.map(card =>
                card.id === action.payload.id ? action.payload : card)
            return {
                ...state,
                loading: false,
                cardsData: state.cardsData
            };
        case actionTypes.UPDATE_CARDS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case actionTypes.DELETE_CARDS_REQUEST:
            return{
                ...state,
                loading: true,
                error: null
            };
        case actionTypes.DELETE_CARDS_SUCCESS:
            state.cardsData.data.rows = state.cardsData?.data?.rows.filter(card => card.id !== action.payload )
            return{
                ...state,
                loading: false,
                cardsData: state.cardsData
            };
        case actionTypes.DELETE_CARDS_FAILURE:
            return{
                ...state,
                loading: false,
                error: action.payload
            };

        default:
            return state;
    }
};

export default cardsReducer;