import * as actionTypes from '../actionTypes.js';

const initialState = {
    partnerCardFormFieldOptionsData: null,
    loading: false,
    error: null,
};

const partnerCardFormFieldOptionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_PARTNER_CARD_FROM_FIELD_OPTION_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.FETCH_PARTNER_CARD_FROM_FIELD_OPTION_SUCCESS:
            return {
                ...state,
                loading: false,
                partnerCardFormFieldOptionsData: action.payload,
            };
        case actionTypes.FETCH_PARTNER_CARD_FROM_FIELD_OPTION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case actionTypes.CREATE_CARD_FROM_FIELD_OPTIONS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.CREATE_CARD_FROM_FIELD_OPTIONS_SUCCESS:
            state.partnerCardFormFieldOptionsData.data.rows = [...state.partnerCardFormFieldOptionsData?.data?.rows, action.payload];
            return {
                ...state,
                loading: false,
                partnerCardFormFieldOptionsData: state.partnerCardFormFieldOptionsData,
            };
        case actionTypes.CREATE_CARD_FROM_FIELD_OPTIONS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case actionTypes.UPDATE_CARD_FROM_FIELD_OPTIONS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.UPDATE_CARD_FROM_FIELD_OPTIONS_SUCCESS:
            state.partnerCardFormFieldOptionsData.data.rows = state.partnerCardFormFieldOptionsData?.data?.rows.map(card =>
                card.id === action.payload.id ? action.payload : card)
            return {
                ...state,
                loading: false,
                partnerCardFormFieldOptionsData: state.partnerCardFormFieldOptionsData
            }

        case actionTypes.UPDATE_CARD_FROM_FIELD_OPTIONS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state
    }
};
export default partnerCardFormFieldOptionsReducer;