import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";

export const fetchAllDigitalInvitationCardsEntryRequest = () => ({
    type: actionTypes.GET_ALL_DIGITAL_INVITATION_CARD_ENTRY_REQUEST,
});

export const fetchAllDigitalInvitationCardsEntrySuccess = (data) => ({
    type: actionTypes.GET_ALL_DIGITAL_INVITATION_CARD_ENTRY_SUCCESS,
    payload: data,
});

export const fetchAllDigitalInvitationCardsEntryFailure = (error) => ({
    type: actionTypes.GET_ALL_DIGITAL_INVITATION_CARD_ENTRY_FAILURE,
    payload: error,
});

export const fetchAllDigitalInvitationCardsEntryData = () => {
    return async (dispatch) => {
        dispatch(fetchAllDigitalInvitationCardsEntryRequest());
        try{
            const data = await api.getDigitalInvitationCardEntry();
            dispatch(fetchAllDigitalInvitationCardsEntrySuccess(data));
            return data;
        } catch(error){
            dispatch(fetchAllDigitalInvitationCardsEntryFailure());
        };
    };
};

export const fetchSingleDigitalInvitationCardsEntryRequest = () => ({
    type: actionTypes.GET_SINGLE_DIGITAL_INVITATION_CARD_ENTRY_REQUEST,
});

export const fetchSingleDigitalInvitationCardsEntrySuccess = (data) => ({
    type: actionTypes.GET_SINGLE_DIGITAL_INVITATION_CARD_ENTRY_SUCCESS,
    payload: data,
});

export const fetchSingleDigitalInvitationCardsEntryFailure = (error) => ({
    type: actionTypes.GET_SINGLE_DIGITAL_INVITATION_CARD_ENTRY_FAILURE,
    payload: error,
});

export const fetchSingleDigitalInvitationCardsEntryData = (id) => {
    console.log("id for view single entry", id);
    return async (dispatch) => {
        dispatch(fetchSingleDigitalInvitationCardsEntryRequest());
        try{
            const data = await api.getSingleDigitalInvtCardEntry(id);
            console.log("data for view single entry", data);
            dispatch(fetchSingleDigitalInvitationCardsEntrySuccess(data));
            return data;
        } catch(error){
            dispatch(fetchSingleDigitalInvitationCardsEntryFailure());
        };
    };
};

export const getSingleDigitalInvitationCardEntryData = async (id) => {
    const response = await api.getSingleDigitalInvtCardEntry(id);
    return response;
};
export const updateDigitalCardEntryData = async (formData) => {
    const response = await api.updateDigitalCardEntry(formData.id, formData);
    return response;
};
export const deleteDigitalCardEntryData = async (id) =>{
    const response = await api.deleteDigitalCardEntry(id);
    return response;
}