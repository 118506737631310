import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";
import { toast } from 'react-toastify';

export const fetchCardSampleRequest = () => ({
    type: actionTypes.FETCH_CARD_SAMPLE_REQUEST,
});
export const fetchCardSampleSuccess = (data) => ({
    type: actionTypes.FETCH_CARD_SAMPLE_SUCCESS,
    payload: data,
});
export const fetchCardSampleFailure = (error) => ({
    type: actionTypes.FETCH_CARD_SAMPLE_FAILURE,
    payload: error,
});

export const fetchCardSampleData = (cardsData) => {

    return async (dispatch) => {
        console.log("step 1 inside cards Action")
        dispatch(fetchCardSampleRequest());
        try {
            const {data} = await api.cardSample(cardsData);
            console.log("inside cards action", data);
            dispatch(fetchCardSampleSuccess(data));
            return data;
        } catch (error) {
            dispatch(fetchCardSampleFailure(error));
        }
    };
};

export const createCardSampleRequest = () => ({
    type: actionTypes.CREATE_CARD_SAMPLE_REQUEST,
});
export const createCardSampleSuccess = (data) => ({
    type: actionTypes.CREATE_CARD_SAMPLE_SUCCESS,
    payload: data
});
export const createCardSampleFailure = (error) => ({
    type: actionTypes.CREATE_CARD_SAMPLE_FAILURE,
    payload: error,
});

export const createCardSampleData = (newCardData) => {
    return async (dispatch) => {
        dispatch(createCardSampleRequest());
        try{
            const {data} = await api.createCardSample(newCardData);
            if (data?.status === 'success') {
                toast.success('Card Sample submitted successfully!', {
                    autoClose: 3000,
                });
            } else {
                toast.error('Failed to submitted card Sample. Please try again.', {
                    autoClose: 3000,
                });
            }
            dispatch(createCardSampleSuccess(data));
            return data;
        }catch(error) {
            dispatch(createCardSampleFailure(error));
        }
    }
};

export const updateCardSampleRequest = () => ({
    type: actionTypes.UPDATE_CARD_SAMPLE_REQUEST,
});
export const updateCardSampleSuccess = (data) => ({
    type: actionTypes.UPDATE_CARD_SAMPLE_SUCCESS,
    payload: data,
});
export const updateCardSampleFailure = (error) => ({
    type: actionTypes.UPDATE_CARD_SAMPLE_FAILURE,
    payload: error,
});

export const updateCardSampleData = async (updateData) => {
    const response = await api.updateCardSample(updateData.id, updateData);
    return response.data;

    // return async (dispatch) => {
    //     dispatch(updateCardSampleRequest());
    //     try{
    //         const response = await api.updateCardSample(updateData.id, updateData);
    //         const updatedCard = response?.data;
    //         const successAction = updateCardSampleSuccess(updatedCard);
    //         dispatch(successAction);
    //         return successAction;

    //     } catch(error) {
    //         const failureAction = updateCardSampleFailure(error.message);
    //         dispatch(failureAction);
    //         return failureAction;
    //     }
    // };
};

export const deleteCardSampleRequest = () => ({
    type: actionTypes.DELETE_CARD_SAMPLE_REQUEST,
});
export const deleteCardSampleSuccess = (id) => ({
    type: actionTypes.DELETE_CARD_SAMPLE_SUCCESS,
    payload: id,
});
export const deleteCardSampleFailure = (error) => ({
    type: actionTypes.DELETE_CARD_SAMPLE_FAILURE,
    payload: error,
});

export const deleteCardSampleData = async (id) => {
    const data = await api.deleteCardSample(id);
    return data;
    // return async (dispatch) => {
    //     dispatch(deleteCardSampleRequest());
    //     try{
           
    //     } catch(error) {
    //         dispatch(deleteCardSampleFailure(error));
    //     }
    // };
};

export const getSingleCardSampleRequest = () => ({
    type: actionTypes.GET_SINGLE_CARD_SAMPLE_REQUEST,
});
export const getSingleCardSampleSuccess = (card) => ({
    type: actionTypes.GET_SINGLE_CARD_SAMPLE_SUCCESS,
    payload: card,
});
export const getSingleCardSampleFailure = (error) => ({
    type: actionTypes.GET_SINGLE_CARD_SAMPLE_FAILURE,
    payload: error,
});
export const getSingleCardSampleData = (cardData) => {
    console.log("card sample", cardData);
    return async (dispatch) => {
        dispatch(getSingleCardSampleRequest());
        try {

            const response = await api.getSingleCardSample(cardData);
            const Item = response.data;
            const successAction = getSingleCardSampleSuccess(Item);
            dispatch(successAction);
            return successAction;

        } catch (error) {
            dispatch(getSingleCardSampleFailure(error));
        }
    };
};
