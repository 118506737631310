import * as actionTypes from '../actionTypes.js';

const initialState = {
    DigiatalCardEntryData: null,
    loading: false,
    error: null,
};

const digitalInvitationCardEntryReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ALL_DIGITAL_INVITATION_CARD_ENTRY_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.GET_ALL_DIGITAL_INVITATION_CARD_ENTRY_SUCCESS:
            return {
                ...state,
                loading: false,
                DigiatalCardEntryData: action.payload,
            };
        case actionTypes.GET_ALL_DIGITAL_INVITATION_CARD_ENTRY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case actionTypes.GET_SINGLE_DIGITAL_INVITATION_CARD_ENTRY_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.GET_SINGLE_DIGITAL_INVITATION_CARD_ENTRY_SUCCESS:
            return {
                ...state,
                loading: false,
                DigiatalCardEntryData: action.payload,
            };
        case actionTypes.GET_SINGLE_DIGITAL_INVITATION_CARD_ENTRY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default digitalInvitationCardEntryReducer;
