import React, { useEffect} from 'react';
import { Typography, } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrderCountData } from '../../redux/actions/orderCountActions';

function Dashboardgrid() {
    const dispatch = useDispatch();
    const data = useSelector((state) => state.orderCount?.data);
  

    console.log(data);

    useEffect(() => {
        const fetchOrderCount = async () => {
           
            await dispatch(fetchOrderCountData());
            
        };
        fetchOrderCount();
    }, [dispatch]);


    return (
        <>
            <Typography variant="h2" color="initial">Welcome to Internal Portal</Typography>
           
        </>

    );
}

export default Dashboardgrid;


