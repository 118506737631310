import * as actionTypes from '../actionTypes.js';

const initialState = {
    templateData: null,
    loading: false,
    error: null,
};

const templateReducer = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.GET_ALL_TEMPLATE_REQUEST:
            return{
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.GET_ALL_TEMPLATE_SUCCESS:            
            return {
                ...state,
                loading: false,
                templateData: action.payload,
            };
        case actionTypes.GET_ALL_TEMPLATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};
export default templateReducer;