import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";
import { toast } from "react-toastify";

export const fetchCardFromFieldOptionsRequest = () => ({
    type: actionTypes.FETCH_CARD_FROM_FIELD_OPTIONS_REQUEST,
});
export const fetchCardFromFieldOptionsSuccess = (data) => ({
    type: actionTypes.FETCH_CARD_FROM_FIELD_OPTIONS_SUCCESS,
    payload: data,
});
export const fetchCardFromFieldOptionsFailure = (error) => ({
    type: actionTypes.FETCH_CARD_FROM_FIELD_OPTIONS_FAILURE,
    payload: error,
});

export const fetchCardFromFieldOptionsData = (cardsData) => {

    return async (dispatch) => {
        dispatch(fetchCardFromFieldOptionsRequest());
        try {
            const {data} = await api.cardFromFieldOptions(cardsData);
            console.log("inside cards action", data);
            dispatch(fetchCardFromFieldOptionsSuccess(data));
            return data;
        } catch (error) {
            dispatch(fetchCardFromFieldOptionsFailure(error));
        }
    };
};

export const createCardFromFieldOptionsRequest = () => ({
    type: actionTypes.CREATE_CARD_FROM_FIELD_OPTIONS_REQUEST,
});
export const createCardFromFieldOptionsSuccess = (data) => ({
    type: actionTypes.CREATE_CARD_FROM_FIELD_OPTIONS_SUCCESS,
    payload: data
});
export const createCardFromFieldOptionsFailure = (error) => ({
    type: actionTypes.CREATE_CARD_FROM_FIELD_OPTIONS_FAILURE,
    payload: error,
});

export const createCardFromFieldOptionsData = (newCardData) => {
    return async (dispatch) => {
        dispatch(createCardFromFieldOptionsRequest());
        try{
            const {data} = await api.createCardFromFieldOptions(newCardData);
            if (data?.status === 'success') {
                toast.success('Card Form Field Options submitted successfully!', {
                    autoClose: 3000,
                });
            } else {
                toast.error('Failed to submitted card Form Field Options. Please try again.', {
                    autoClose: 3000,
                });
            }
            dispatch(createCardFromFieldOptionsSuccess(data));
            return data;
        }catch(error) {
            dispatch(createCardFromFieldOptionsFailure(error));
        }
    }
};

export const updateCardFromFieldOptionsRequest = () => ({
    type: actionTypes.UPDATE_CARD_FROM_FIELD_OPTIONS_REQUEST,
});
export const updateCardFromFieldOptionsSuccess = (data) => ({
    type: actionTypes.UPDATE_CARD_FROM_FIELD_OPTIONS_SUCCESS,
    payload: data,
});
export const updateCardFromFieldOptionsFailure = (error) => ({
    type: actionTypes.UPDATE_CARD_FROM_FIELD_OPTIONS_FAILURE,
    payload: error,
});

export const updateCardFromFieldOptionsData = async (updateData) => {

    const response = await api.updateCardFromFieldOptions(updateData.id, updateData);
    return response.data;
    // return async (dispatch) => {
    //     dispatch(updateCardFromFieldOptionsRequest());
    //     try{
    //         const response = await api.updateCardFromFieldOptions(updateData.id, updateData);
    //         const updatedCard = response?.data;
    //         const successAction = updateCardFromFieldOptionsSuccess(updatedCard);
    //         console.log("option data", successAction);
    //         dispatch(successAction);
    //         return successAction;

    //     } catch(error) {
    //         const failureAction = updateCardFromFieldOptionsFailure(error.message);
    //         dispatch(failureAction);
    //         return failureAction;
    //     }
    // };
};

export const deleteCardFromFieldOptionsRequest = () => ({
    type: actionTypes.DELETE_CARD_FROM_FIELD_OPTIONS_REQUEST,
});
export const deleteCardFromFieldOptionsSuccess = (id) => ({
    type: actionTypes.DELETE_CARD_FROM_FIELD_OPTIONS_SUCCESS,
    payload: id,
});
export const deleteCardFromFieldOptionsFailure = (error) => ({
    type: actionTypes.DELETE_CARD_FROM_FIELD_OPTIONS_FAILURE,
    payload: error,
});

export const deleteCardFromFieldOptionsData = (id) => {
    return async (dispatch) => {
        dispatch(deleteCardFromFieldOptionsRequest());
        try{
            const data = await api.deleteCardFromFieldOptions(id);
            dispatch(deleteCardFromFieldOptionsSuccess(id));
            return data;
        } catch(error) {
            dispatch(deleteCardFromFieldOptionsFailure(error));
        }
    };
};
