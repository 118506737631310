import React, { useState, useEffect } from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Button, Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function SuccessScreen({ message, redirectPath, buttonLabel, countdownTime = 30,setShowSuccess,setShowEditSuccess }) {
    const navigate = useNavigate();
    const [timer, setTimer] = useState(countdownTime);

    useEffect(() => {
        const countdown = setInterval(() => {
            setTimer((prevTimer) => {
                if (prevTimer <= 1) {
                    clearInterval(countdown);
                    handleButtonClick(); 
                    return 0;
                }
                return prevTimer - 1;
            });
        }, 1000);

        return () => clearInterval(countdown); 
    }, [navigate, redirectPath]);

    const handleButtonClick = () => {
        setShowEditSuccess(false);
        setShowSuccess(false);
        navigate(redirectPath); // Navigate when button is clicked
    };

    return (
        <Container maxWidth="sm" style={{ textAlign: 'center', marginTop: '50px' }}>
            <CheckCircleOutlineIcon style={{ fontSize: 80, color: 'green' }} />
            <Typography variant="h4" style={{ marginTop: '20px' }}>
                {message.title}
            </Typography>
            <Typography variant="body1" style={{ margin: '20px 0' }}>
                {message.body}
            </Typography>
            <Typography variant="body1" style={{ margin: '20px 0' }}>
                Redirecting you to {buttonLabel.toLowerCase()} page in {timer} seconds...
            </Typography>
            <Button
                onClick={handleButtonClick}
                variant="contained"
                color="primary"
                sx={{ marginBottom: 5 }}
            >
                {buttonLabel}
            </Button>
        </Container>
    );
}

export default SuccessScreen;
