import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";


export const fetchCardGroupRequest = () => ({
    type: actionTypes.FETCH_CARD_GROUP_REQUEST,
});
export const fetchCardGroupSuccess = (data) => ({
    type: actionTypes.FETCH_CARD_GROUP_SUCCESS,
    payload: data,
});
export const fetchCardGroupFailure = (error) => ({
    type: actionTypes.FETCH_CARD_GROUP_FAILURE,
    payload: error,
});

export const fetchCardGroup = (page, limit,search= '') => {

    return async (dispatch) => {
        dispatch(fetchCardGroupRequest());
        try {
            const {data} = await api.cardGroup(page, limit,search);
            dispatch(fetchCardGroupSuccess(data));
            return data;
        } catch (error) {
            dispatch(fetchCardGroupFailure(error));
        }
    };
};

export const createCardGrouprequest = () => ({
    type: actionTypes.CREATE_CARD_GROUP_REQUEST,
});
export const createCardgroupSuccess = (data) => ({
    type: actionTypes.CREATE_CARD_GROUP_SUCCESS,
    payload: data,
});
export const createCardGroupFailure = (error) => ({
    type: actionTypes.CREATE_CARD_GROUP_FAILURE,
    payload: error,
});

export const createCardGroupData = (newCardData) => {
    return async (dispatch) => {
        dispatch(createCardGrouprequest());
        try{
            const {data} = await api.createCardGroup(newCardData);
            dispatch(createCardgroupSuccess(data));
            return data;
        }catch(error) {
            dispatch(createCardGroupFailure(error));
        }
    }
};

export const updateCardGroupRequest = () => ({
    type: actionTypes.EDIT_CARD_GROUP_REQUEST,
});
export const updateCardGroupSuccess = (data) => ({
    type: actionTypes.EDIT_CARD_GROUP_SUCCESS,
    payload: data,
});
export const updateCardGroupFailure = (error) => ({
    type: actionTypes.EDIT_CARD_GROUP_FAILURE,
    payload: error,
});

export const updateCardGroupData = (updateData) => {
    return async (dispatch) => {
        dispatch(updateCardGroupRequest());
        try{
            const response = await api.editCardGroup(updateData.id, updateData);
            const updatedCard = response?.data;
            const successAction = updateCardGroupSuccess(updatedCard);
            dispatch(successAction);
            return successAction;

        } catch(error) {
            const failureAction = updateCardGroupFailure(error.message);
            dispatch(failureAction);
            return failureAction;
        }
    };
};

export const deleteCardGroupRequest = () => ({
    type: actionTypes.DELETE_CARD_GROUP_REQUEST,
});
export const deleteCardGroupSuccess = (id) => ({
    type: actionTypes.DELETE_CARD_GROUP_SUCCESS,
    payload: id,
});
export const deleteCardGroupFailure = (error) => ({
    type: actionTypes.DELETE_CARD_GROUP_FAILURE,
    payload: error,
});

export const deleteCardGroupData = (id) => {
    return async (dispatch) => {
        dispatch(deleteCardGroupRequest());
        try{
            await api.deleteCardGroup(id);
            dispatch(deleteCardGroupSuccess(id));
        } catch(error) {
            dispatch(deleteCardGroupFailure(error));
        }
    };
};