import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
    Avatar,
    Box,
    IconButton,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    useMediaQuery,
} from '@mui/material';

import ProfileMenu from '../menus/ProfileMenu';
import { useTheme } from '@emotion/react';
import MenuLinks from './MenuLinks';
import { Logout } from '../../redux/actions/authActions';

const TopNavigation = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [profileMenuOpen, setProfileMenuOpen] = useState(false);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [profileMenuAnchor, setProfileMenuAnchor] = useState(null);
    const [profile, setProfile] = useState(null);

    const handleLogout = () => {
        setConfirmationModalOpen(true);
    };

    const confirmLogout = async () => {
        await dispatch(Logout(navigate));
        
    };

    const cancelLogout = () => {
        setConfirmationModalOpen(false);
    };

    const [language, setLanguage] = useState(() => {
        return localStorage.getItem('language') || 'en';
    });

    useEffect(() => {
        localStorage.setItem('language', language);
    }, [language]);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            {isMobile ? (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        overflowX: 'hidden', // Prevent bottom scrollbar
                        padding: 0, // Reduced padding for mobile
                        margin: 0,
                        width: '100%',
                        maxWidth: '100vw', // Prevent exceeding viewport width
                        boxSizing: 'border-box',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            borderRadius: '20px',
                            width: '100%', // Ensure it fits the screen width
                            maxWidth: '100%', // Prevent child components from overflowing
                            flexWrap: 'wrap', // Wrap content if it exceeds the width
                            padding: 0, // Reduced padding for mobile
                            margin: 0,
                            boxSizing: 'border-box',
                        }}
                    >

                        <ProfileMenu
                            open={profileMenuOpen}
                            anchor={profileMenuAnchor}
                            profile={profile}
                            onClose={() => setProfileMenuOpen(false)}
                            navigate={navigate}
                            onLogout={handleLogout}
                        />

                        

                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                width: 'auto', // Adjust width as needed
                                flexShrink: 0, // Prevent shrinking
                                padding: 0, // Reduced padding for mobile
                                margin: 0,
                                boxSizing: 'border-box',
                            }}
                        >

                            <IconButton
                                onClick={(e) => {
                                    setProfileMenuAnchor(e.currentTarget);
                                    setProfileMenuOpen(true);
                                }}
                            >
                                <Avatar sx={{ backgroundColor: "#004182" }}>
                                    <Typography color="white">Me</Typography>
                                </Avatar>
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                        overflowX: 'hidden', // Prevent bottom scrollbar
                        overflowY: 'hidden',
                    }}
                >
                    <Typography variant="body1" color="initial"></Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', pr: 1, borderRadius: '20px' }}>
                        <ProfileMenu
                            open={profileMenuOpen}
                            anchor={profileMenuAnchor}
                            profile={profile}
                            onClose={() => setProfileMenuOpen(false)}
                            navigate={navigate}
                            onLogout={handleLogout}
                        />

                        <Dialog
                            open={confirmationModalOpen}
                            onClose={cancelLogout}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            maxWidth="xs" // Smaller size
                            fullWidth
                        >
                            <DialogTitle id="alert-dialog-title">
                                Log out
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Are you sure you want to log out?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={cancelLogout} color="primary" variant="outlined">
                                    Cancel
                                </Button>
                                <Button onClick={confirmLogout} color="primary" variant="contained" autoFocus>
                                    Confirm
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}> {/* Reduced gap */}


                            <IconButton onClick={(e) => {
                                setProfileMenuAnchor(e.currentTarget);
                                setProfileMenuOpen(true);
                            }}>
                                <Avatar sx={{ backgroundColor: "#004182" }}>
                                    <Typography color="white">Me</Typography>
                                </Avatar>
                            </IconButton>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}> {/* Reduced gap */}


                           <MenuLinks/>
                        
                        </Box>
                    </Box>
                </Box>
            )}
            <Dialog
                open={confirmationModalOpen}
                onClose={cancelLogout}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="xs" // Smaller size
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">
                    Log out
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to log out?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={cancelLogout} color="primary" variant="outlined">
                        Cancel
                    </Button>
                    <Button onClick={confirmLogout} color="primary" variant="contained" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default TopNavigation;