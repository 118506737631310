import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { getSingleDigitalInvitationCardEntryData, updateDigitalCardEntryData } from '../../redux/actions/digitalInvitationCardEntryActions';
import { FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material';

const ChangePaymentStatus = ({ open, handleClose, card }) => {

  const [newData, setNewData] = useState({
    id: '', payment_status: ''
  });
  useEffect(() => {
    const fetchSingleDigitalCard = async () => {
      const response = await getSingleDigitalInvitationCardEntryData(card);
      console.log("data in digital card edit", response);
      setNewData(response?.data?.data);

    }
    fetchSingleDigitalCard();
  }, [card]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewData({ ...newData, [name]: value });
    console.log("New Data", newData);
  };

  const handleSubmit = async () => {
    await updateDigitalCardEntryData(newData);
    handleClose();
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Edit Basic Details</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <FormControl fullWidth variant="outlined" required>
              <InputLabel id="select-label">Title</InputLabel>
              <Select
                labelId="select-label"
                name="payment_status"
                value={newData.payment_status}
                onChange={handleChange}
                label="Payment Status"
              >
                <MenuItem value="not_needed">Not Needed</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Cancel</Button>
          <Button onClick={handleSubmit} color="primary">Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ChangePaymentStatus