import React, { useEffect, useRef, useState } from 'react';
import * as VideoEngineAction from '../../../redux/actions/videoEngineActions';
import { useNavigate, useParams } from 'react-router-dom';
import { TextField, Button, Box, Typography, Grid, Grid2, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { Label } from '@mui/icons-material';
import { toast } from 'react-toastify';

const VideoEngineConfigurationEdit = () => {
    const [configurationData, setConfigurationData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [imageSrcs, setImageSrcs] = useState({});
    const videoRefs = useRef([]);
    const canvasRefs = useRef([]);
    const navigate = useNavigate();
    const params = useParams();

    // Get the video URL from the first element of video_items or use the fallback URL
    // let fallbackUrl = ""; // "https://innovators.blr1.cdn.digitaloceanspaces.com/wedding/wedding_invite_2_v.mp4";
    // let videoUrl = fallbackUrl;

    const [videoUrl, setVideoUrl] = useState(''); // Define videoUrl in state


    useEffect(() => {
        const fetchData = async () => {
            try {
                console.log("fetching data");
                const response = await VideoEngineAction.fetchSingleVideoEngineTemplates(params.templateId);
                console.log("response.data.content", response.data.content);
                let content = JSON.parse(response.data.content);
                console.log("content", content?.processing_artifacts?.data?.video_items);
                setConfigurationData(content);

                setVideoUrl(content?.processing_artifacts?.data?.video_items?.[0]?.properties?.video_url);
                console.log("videoUrl", videoUrl);
                console.log("videoUrl", videoUrl);

                // Capture frames for each video item after data is fetched
                content?.processing_artifacts?.data?.video_items.forEach((item, index) => {
                    captureFrameFromVideo(index, item.properties.start_time, item);
                });
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [params.templateId]);

    const handleInputChange = (index, field, value, field_data_type) => {
        const updatedData = { ...configurationData };
        if (field_data_type == 'number') {
            updatedData.processing_artifacts.data.video_items[index].properties[field] = Number(value);
        } else {
            updatedData.processing_artifacts.data.video_items[index].properties[field] = value;
        }
        setConfigurationData(updatedData);

        // if (field === 'start_time') {
        //     captureFrameFromVideo(index, value, updatedData.processing_artifacts.data.video_items[index]);
        // }
        captureFrameFromVideo(index,
            updatedData.processing_artifacts.data.video_items[index].properties.start_time,
            updatedData.processing_artifacts.data.video_items[index]);
    };

    const handlePositionInputChange = (index, field, value, field_data_type) => {
        const updatedData = { ...configurationData };
        if (field_data_type == 'number') {
            updatedData.processing_artifacts.data.video_items[index].properties.position[field] = Number(value);
        } else {
            updatedData.processing_artifacts.data.video_items[index].properties.position[field] = value;
        }
        setConfigurationData(updatedData);

        // if (field === 'start_time') {
        //     captureFrameFromVideo(index, value, updatedData.processing_artifacts.data.video_items[index]);
        // }
        captureFrameFromVideo(index,
            updatedData.processing_artifacts.data.video_items[index].properties.start_time,
            updatedData.processing_artifacts.data.video_items[index]);
    };

    const captureFrameFromVideo = (index, time, item1) => {
        // const updatedData = { ...configurationData };
        const updatedData = JSON.parse(JSON.stringify(configurationData));
        const video = videoRefs.current[index];
        const canvas = canvasRefs.current[index];

        if (!video || !canvas) return;
        const context = canvas.getContext('2d');

        video.currentTime = time;
        video.onseeked = () => {
            // context.drawImage(video, 0, 0, canvas.width, canvas.height);
            // console.log("item", item);

            // if (!item) {
            //     const dataURL = canvas.toDataURL('image/png');
            //     setImageSrcs(prevState => ({ ...prevState, [index]: dataURL }));
            // } else {
            //     if (item.item_type == 'image') {
            //         const img = new Image();
            //         img.crossOrigin = "anonymous"; // Set crossOrigin attribute
            //         img.src = item.properties.image_url;
            //         img.onload = () => {
            //             context.drawImage(img, item.properties.position.x, item.properties.position.y, item.properties.width, item.properties.height);
            //             const dataURL = canvas.toDataURL('image/png');
            //             setImageSrcs(prevState => ({ ...prevState, [index]: dataURL }));
            //         };
            //     } else if (item.item_type == 'text') {

            //         let text = item.properties.text;
            //         let textWidth = item.properties.width; // context.measureText(text).width;
            //         let textHeight = item.properties.height; //item.properties.font_size;
            //         let textAlign = item.properties.text_align;
            //         if (!textAlign) textAlign = "center";
            //         let url1 = "https://generator.rajoffset.com/s2s/v1/text2-image?text=" + text + "&color=" + item["properties"]["color"] + "&artCode=simple&strokeWidth=0&strokeColor=red,black&fontSize=" + item["properties"]["font_size"] + "px&textAlign=" + textAlign + "&width=" + textWidth;

            //         const img = new Image();
            //         img.crossOrigin = "anonymous"; // Set crossOrigin attribute
            //         img.src = url1;
            //         img.onload = () => {
            //             context.drawImage(img, item.properties.position.x, item.properties.position.y);
            //             const dataURL = canvas.toDataURL('image/png');
            //             setImageSrcs(prevState => ({ ...prevState, [index]: dataURL }));
            //         };
            //     } else {
            //         const dataURL = canvas.toDataURL('image/png');
            //         setImageSrcs(prevState => ({ ...prevState, [index]: dataURL }));
            //     }
            // }

            context.drawImage(video, 0, 0, canvas.width, canvas.height);

            // Filter items where time falls between start_time and end_time
            const itemsToDraw = updatedData.processing_artifacts.data.video_items.filter(
                item => time >= item.properties.start_time && time <= item.properties.end_time
            );

            // Draw each filtered item
            itemsToDraw.forEach(item => {
                if (item.item_type === 'image') {
                    const img = new Image();
                    img.crossOrigin = "anonymous"; // Set crossOrigin attribute
                    img.src = item.properties.image_url;
                    img.onload = () => {
                        context.drawImage(img, item.properties.position.x, item.properties.position.y, item.properties.width, item.properties.height);
                        const dataURL = canvas.toDataURL('image/png');
                        setImageSrcs(prevState => ({ ...prevState, [index]: dataURL }));
                    };
                } else if (item.item_type === 'text') {
                    let text = item.properties.text;
                    let textWidth = item.properties.width; // context.measureText(text).width;
                    let textHeight = item.properties.height; //item.properties.font_size;
                    let textAlign = item.properties.text_align;
                    let fontName = item.properties.font || "Arial";
                    let FontWeight = item.properties.font_weight || "normal";
                    if (!textAlign) textAlign = "center";
                    let url1 = "https://generator.rajoffset.com/s2s/v1/text2-image?text=" + text + "&fontName=" + fontName + "&FontWeight=" + FontWeight + "&color=" + item["properties"]["color"] + "&artCode=simple&strokeWidth=0&strokeColor=red,black&fontSize=" + item["properties"]["font_size"] + "px&textAlign=" + textAlign + "&width=" + textWidth;

                    const img = new Image();
                    img.crossOrigin = "anonymous"; // Set crossOrigin attribute
                    img.src = url1;
                    img.onload = () => {
                        context.drawImage(img, item.properties.position.x, item.properties.position.y);
                        const dataURL = canvas.toDataURL('image/png');
                        setImageSrcs(prevState => ({ ...prevState, [index]: dataURL }));
                    };
                }
            });

            const dataURL = canvas.toDataURL('image/png');
            setImageSrcs(prevState => ({ ...prevState, [index]: dataURL }));
        };
    };

    const addTextItem = (index) => {
        const updatedData = { ...configurationData };
        const dataAddIndex = updatedData.processing_artifacts.data.video_items[index];
        const newItem = {
            "item_type": "text",
            "properties": {
                "start_time": dataAddIndex.properties.start_time || 0,
                "end_time": dataAddIndex.properties.end_time || 0,
                "field_name": "",
                "text": "New Text To show",
                "font": "Arial",
                "font_size": 40,
                "font_weight": "normal",
                "color": "darkred",
                "width": 200,
                "height": 200,
                "position": {
                    "x": 100,
                    "y": 100
                },
                "rotation": 0,
                "start_animation": "fadein",
                "align": "center",
                "font_family": "",
                "background_color": "",
                "opacity": 0,
                "start_animation_end_time": 0,
                "end_animation": "",
                "end_animation_start_time": 0,
                "animation_iteration": 0,
                "animation_direction": ""
            }
        };


        updatedData.processing_artifacts.data.video_items.splice(index + 1, 0, newItem);
        setConfigurationData(updatedData);
    }

    const addImageItem = (index) => {
        const updatedData = { ...configurationData };
        const dataAddIndex = updatedData.processing_artifacts.data.video_items[index];
        const newItem = {
            "item_type": "image",
            "properties": {
                "start_time": dataAddIndex.properties.start_time || 0,
                "end_time": dataAddIndex.properties.end_time || 0,
                "image_url": "https://digital-invitation.s3.amazonaws.com/engine/other/lineart/77476_lineartA.png",
                "position": {
                    "x": 100,
                    "y": 100
                },
                "width": 200,
                "height": 200,
                "rotation": 0,
                "field_name": "",
                "opacity": 0,
                "start_animation": "",
                "start_animation_end_time": 0,
                "end_animation": "",
                "end_animation_start_time": 0,
                "animation_iteration": 0,
                "animation_direction": ""
            }
        };

        updatedData.processing_artifacts.data.video_items.splice(index + 1, 0, newItem);
        setConfigurationData(updatedData);
    }

    const duplicateItem = (index) => {
        const updatedData = { ...configurationData };
        const dataAtIndex = updatedData.processing_artifacts.data.video_items[index];

        updatedData.processing_artifacts.data.video_items.splice(index + 1, 0, dataAtIndex);
        setConfigurationData(updatedData);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        // Handle form submission logic here
        console.log("Updated Configuration Data:", configurationData);
        const response = await VideoEngineAction.updateSingleVideoEngineTemplates(params.templateId, {
            content: JSON.stringify(configurationData)
        });
        console.log("response", response);
        toast.success("Configuration updated successfully");
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;



    return (
        <form onSubmit={handleSubmit}>
            {configurationData.processing_artifacts.data.video_items.map((item, index) => (
                <Grid container spacing={2} key={index} sx={{ marginBottom: '20px', borderBottom: '1px solid #ccc', paddingBottom: '20px' }}>
                    <Grid item xs={12} md={4}>
                        <Box>
                            {videoUrl.length > 0 && (
                                <>
                                    <video
                                        ref={el => videoRefs.current[index] = el}
                                        crossOrigin="anonymous"
                                        // src="https://innovators.blr1.cdn.digitaloceanspaces.com/ve/2024-10-20/542a142d-7845-43de-a407-5911b7c59ed8/final.mp4"
                                        src={videoUrl}
                                        style={{ display: 'none' }}
                                    />
                                    <canvas
                                        ref={el => canvasRefs.current[index] = el}
                                        width="720"
                                        height="1280"
                                        style={{ display: 'none' }}
                                    />
                                </>
                            )}

                            {imageSrcs[index] && (
                                <img
                                    src={imageSrcs[index]}
                                    alt="Captured frame"
                                    style={{ width: '100%', height: 'auto', backgroundColor: 'red' }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Typography variant="h6">Item {index + 1} ({item.item_type}) - Group: Video Items</Typography>

                        <TextField
                            size='small'
                            label="Start Time"
                            type="number"
                            value={item.properties.start_time}
                            onChange={(e) => handleInputChange(index, 'start_time', e.target.value, 'number')}
                            margin="normal"
                        />
                        <TextField
                            size='small'
                            label="End Time"
                            type="number"
                            value={item.properties.end_time}
                            onChange={(e) => handleInputChange(index, 'end_time', e.target.value, 'number')}
                            margin="normal"
                        />

                        <TextField
                            size='small'
                            label="Rotation"
                            type="number"
                            value={item.properties.rotation}
                            onChange={(e) => handleInputChange(index, 'rotation', e.target.value, 'number')}
                            margin="normal"
                        />

                        {item.item_type === 'video' && (
                            <>
                                <TextField
                                    size='small'
                                    label="Video URL"
                                    type="url"
                                    value={item.properties.video_url}
                                    fullWidth
                                    onChange={(e) => handleInputChange(index, 'video_url', e.target.value, 'string')}
                                    margin="normal"
                                />
                                <TextField
                                    size='small'
                                    label="Clip Start Time"
                                    type="number"
                                    value={item.properties.clip_start_time}
                                    onChange={(e) => handleInputChange(index, 'clip_start_time', e.target.value, 'number')}
                                    margin="normal"
                                />
                                <TextField
                                    size='small'
                                    label="Clip End Time"
                                    type="number"
                                    value={item.properties.clip_end_time}
                                    onChange={(e) => handleInputChange(index, 'clip_end_time', e.target.value, 'number')}
                                    margin="normal"
                                />
                            </>
                        )}

                        {item.item_type === 'image' && (
                            <>
                                <TextField
                                    size='small'
                                    label="Image URL"
                                    type="url"
                                    value={item.properties.image_url}
                                    onChange={(e) => handleInputChange(index, 'image_url', e.target.value, 'string')}
                                    margin="normal"
                                    fullWidth
                                />
                            </>
                        )}

                        {item.item_type === 'text' && (
                            <>
                                <TextField
                                    size='small'
                                    label="Text"
                                    type="text"
                                    value={item.properties.text}
                                    onChange={(e) => handleInputChange(index, 'text', e.target.value, 'string')}
                                    margin="normal"
                                />
                                <TextField
                                    size='small'
                                    label="Font Size"
                                    type="number"
                                    value={item.properties.font_size}
                                    onChange={(e) => handleInputChange(index, 'font_size', e.target.value, 'number')}
                                    margin="normal"
                                />
                                <TextField
                                    size='small'
                                    label="Color"
                                    type="text"
                                    value={item.properties.color}
                                    onChange={(e) => handleInputChange(index, 'color', e.target.value, 'string')}
                                    margin="normal"
                                />
                            </>
                        )}

                        {/* <TextField
                            size='small'
                            label="Position X"
                            type="number"
                            value={item.properties.position.x}
                            onChange={(e) => handlePositionInputChange(index, 'x', e.target.value)}
                            margin="normal"
                        />
                        <TextField
                            size='small'
                            label="Position Y"
                            type="number"
                            value={item.properties.position.y}
                            onChange={(e) => handlePositionInputChange(index, 'y', e.target.value)}
                            margin="normal"
                        /> */}

                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    size='small'
                                    label="Position X"
                                    type="number"
                                    value={item.properties.position.x}
                                    onChange={(e) => handlePositionInputChange(index, 'x', e.target.value, 'number')}
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    size='small'
                                    label="Position Y"
                                    type="number"
                                    value={item.properties.position.y}
                                    onChange={(e) => handlePositionInputChange(index, 'y', e.target.value, 'number')}
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    size='small'
                                    label="Width"
                                    type="number"
                                    value={item.properties.width}
                                    onChange={(e) => handleInputChange(index, 'width', e.target.value, 'number')}
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    size='small'
                                    label="Height"
                                    type="number"
                                    value={item.properties.height}
                                    onChange={(e) => handleInputChange(index, 'height', e.target.value, 'number')}
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>
                        </Grid>


                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <TextField
                                    size='small'
                                    label="Font Weight"
                                    type="number"
                                    value={item.properties.font_weight || 300}
                                    onChange={(e) => handleInputChange(index, 'font_weight', e.target.value, 'number')}
                                    margin="normal"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    size='small'
                                    label="Opacity"
                                    type="number"
                                    value={item.properties.opacity}
                                    onChange={(e) => handleInputChange(index, 'opacity', e.target.value, 'number')}
                                    margin="normal"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    size='small'
                                    label="Animation Direction"
                                    type="text"
                                    value={item.properties.animation_direction}
                                    onChange={(e) => handleInputChange(index, 'animation_direction', e.target.value, 'string')}
                                    margin="normal"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    size='small'
                                    label="Animation Iteration"
                                    type="number"
                                    value={item.properties.animation_iteration}
                                    onChange={(e) => handleInputChange(index, 'animation_iteration', e.target.value, 'number')}
                                    margin="normal"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>


                        <TextField
                            size='small'
                            label="Field Name"
                            type="text"
                            value={item.properties.field_name}
                            onChange={(e) => handleInputChange(index, 'field_name', e.target.value, 'string')}
                            margin="normal"
                            fullWidth
                        />
                        {/* <TextField
                            size='small'
                            label="Font Name"
                            type="text"
                            value={item.properties.font}
                            onChange={(e) => handleInputChange(index, 'font', e.target.value, 'string')}
                            margin="normal"
                            fullWidth
                        /> */}

                        <FormControl fullWidth margin="normal" size="small">
                            <InputLabel>Font Name</InputLabel>
                            <Select
                                value={item.properties.font}
                                onChange={(e) => handleInputChange(index, 'font', e.target.value)}
                            >
                                <MenuItem value="ParisienneRegular">En- ParisienneRegular</MenuItem>
                                <MenuItem value="AllessaPersonalUs">En- AllessaPersonalUs</MenuItem>
                                <MenuItem value="AulyarsItalic">En- AulyarsItalic</MenuItem>
                                <MenuItem value="AulyarsRegular">En- AulyarsRegular</MenuItem>
                                <MenuItem value="BigdeyDemo">En- BigdeyDemo</MenuItem>
                                <MenuItem value="BSCalligraphy">En- BSCalligraphy</MenuItem>
                                <MenuItem value="ChopinScript">En- ChopinScript</MenuItem>
                                <MenuItem value="ChristmasTimePersonalUse">En- ChristmasTimePersonalUse</MenuItem>
                                <MenuItem value="GreatDayPersonalUse">En- GreatDayPersonalUse</MenuItem>
                                <MenuItem value="HandycheeraRegular">En- HandycheeraRegular</MenuItem>
                                <MenuItem value="LucySaid">En- LucySaid</MenuItem>
                                <MenuItem value="MessengerPigeons">En- MessengerPigeons</MenuItem>
                                <MenuItem value="ILoveGlitter">En- ILoveGlitter</MenuItem>
                                <MenuItem value="Precious">En- Precious</MenuItem>
                                <MenuItem value="TheHeartofEverything">En- TheHeartofEverything</MenuItem>
                                <MenuItem value="WinterCalligraphy">En- WinterCalligraphy</MenuItem>
                                <MenuItem value="Aaleyah">En- Aaleyah</MenuItem>
                                <MenuItem value="MotherTongue">En- MotherTongue</MenuItem>
                                <MenuItem value="AgendaKing">En- AgendaKing</MenuItem>
                                <MenuItem value="AlexBrushRegular">En- AlexBrushRegular</MenuItem>
                                <MenuItem value="Andhyta">En- Andhyta</MenuItem>
                                <MenuItem value="BacktoBlackBold">En- BacktoBlackBold</MenuItem>
                                <MenuItem value="BubbleLove">En- BubbleLove</MenuItem>
                                <MenuItem value="BullettoKilla">En- BullettoKilla</MenuItem>
                                <MenuItem value="ChocolateBar">En- ChocolateBar</MenuItem>
                                <MenuItem value="CocogooseNarrowRegularItalic">En- CocogooseNarrowRegularItalic</MenuItem>
                                <MenuItem value="FOFBB">En- FOFBB</MenuItem>
                                <MenuItem value="FOFBB_ITALIC">En- FOFBB_ITALIC</MenuItem>
                                <MenuItem value="Freakshow">En- Freakshow</MenuItem>
                                <MenuItem value="FROSTY">En- FROSTY</MenuItem>
                                <MenuItem value="Gaby">En- Gaby</MenuItem>
                                <MenuItem value="HughisLife">En- HughisLife</MenuItem>
                                <MenuItem value="LoveLetters">En- LoveLetters</MenuItem>
                                <MenuItem value="mexcellent3d">En- mexcellent3d</MenuItem>
                                <MenuItem value="MISTV">En- MISTV</MenuItem>
                                <MenuItem value="musicals">En- musicals</MenuItem>
                                <MenuItem value="MySunshine">En- MySunshine</MenuItem>
                                <MenuItem value="Natyl">En- Natyl</MenuItem>
                                <MenuItem value="PleaseDonotTakeMyMan">En- PleaseDonotTakeMyMan</MenuItem>
                                <MenuItem value="Regista">En- Regista</MenuItem>
                                <MenuItem value="Streetwear">En- Streetwear</MenuItem>
                                <MenuItem value="VirgiEllaRegular">En- VirgiEllaRegular</MenuItem>

                                <MenuItem value="5YEAROLD"> En- 5YEAROLD </MenuItem>
                                <MenuItem value="8YEAROLD"> En- 8YEAROLD </MenuItem>
                                <MenuItem value="Absalom_"> En- Absalom_ </MenuItem>
                                <MenuItem value="ADEXECUT"> En- ADEXECUT </MenuItem>
                                <MenuItem value="AERNE___"> En- AERNE___ </MenuItem>
                                <MenuItem value="AKRON___"> En- AKRON___ </MenuItem>
                                <MenuItem value="ALAN____"> En- ALAN____ </MenuItem>
                                <MenuItem value="Alibi___"> En- Alibi___ </MenuItem>
                                <MenuItem value="ALJO____"> En- ALJO____ </MenuItem>
                                <MenuItem value="ALMAGRO_"> En- ALMAGRO_ </MenuItem>
                                <MenuItem value="ALTON___"> En- ALTON___ </MenuItem>
                                <MenuItem value="AMHERST_"> En- AMHERST_ </MenuItem>
                                <MenuItem value="ANDRES__"> En- ANDRES__ </MenuItem>
                                <MenuItem value="ANDREW__"> En- ANDREW__ </MenuItem>
                                <MenuItem value="ANKENY__"> En- ANKENY__ </MenuItem>
                                <MenuItem value="ANNE____"> En- ANNE____ </MenuItem>
                                <MenuItem value="ARBOR___"> En- ARBOR___ </MenuItem>
                                <MenuItem value="ARCHITE1"> En- ARCHITE1 </MenuItem>
                                <MenuItem value="ARCHITE2"> En- ARCHITE2 </MenuItem>
                                <MenuItem value="ARMOUR__"> En- ARMOUR__ </MenuItem>
                                <MenuItem value="ARTISTE1"> En- ARTISTE1 </MenuItem>
                                <MenuItem value="ARTISTE2"> En- ARTISTE2 </MenuItem>
                                <MenuItem value="ARTURO__"> En- ARTURO__ </MenuItem>
                                <MenuItem value="ASA_____"> En- ASA_____ </MenuItem>
                                <MenuItem value="ASIAN___"> En- ASIAN___ </MenuItem>
                                <MenuItem value="AUSTIN__"> En- AUSTIN__ </MenuItem>
                                <MenuItem value="BADE____"> En- BADE____ </MenuItem>
                                <MenuItem value="BADGER__"> En- BADGER__ </MenuItem>
                                <MenuItem value="BARRON__"> En- BARRON__ </MenuItem>
                                <MenuItem value="BASHA___"> En- BASHA___ </MenuItem>
                                <MenuItem value="BASING__"> En- BASING__ </MenuItem>
                                <MenuItem value="Batavia_"> En- Batavia_ </MenuItem>
                                <MenuItem value="BEACBB__"> En- BEACBB__ </MenuItem>
                                <MenuItem value="BEACBN__"> En- BEACBN__ </MenuItem>
                                <MenuItem value="BEATTY__"> En- BEATTY__ </MenuItem>
                                <MenuItem value="BENDIX__"> En- BENDIX__ </MenuItem>
                                <MenuItem value="BENITO__"> En- BENITO__ </MenuItem>
                                <MenuItem value="BERT____"> En- BERT____ </MenuItem>
                                <MenuItem value="BIRDLAK_"> En- BIRDLAK_ </MenuItem>
                                <MenuItem value="BOBBO___"> En- BOBBO___ </MenuItem>
                                <MenuItem value="BOBCAT__"> En- BOBCAT__ </MenuItem>
                                <MenuItem value="BOBZ____"> En- BOBZ____ </MenuItem>
                                <MenuItem value="BOODS___"> En- BOODS___ </MenuItem>
                                <MenuItem value="BOOKWORM"> En- BOOKWORM </MenuItem>
                                <MenuItem value="BOWFIN__"> En- BOWFIN__ </MenuItem>
                                <MenuItem value="BOYKEN__"> En- BOYKEN__ </MenuItem>
                                <MenuItem value="BRAD____"> En- BRAD____ </MenuItem>
                                <MenuItem value="BRIGHTN_"> En- BRIGHTN_ </MenuItem>
                                <MenuItem value="BRION___"> En- BRION___ </MenuItem>
                                <MenuItem value="BROWN___"> En- BROWN___ </MenuItem>
                                <MenuItem value="BRUSHSTR"> En- BRUSHSTR </MenuItem>
                                <MenuItem value="BUTTON__"> En- BUTTON__ </MenuItem>
                                <MenuItem value="BYFIELD_"> En- BYFIELD_ </MenuItem>
                                <MenuItem value="CALLB___"> En- CALLB___ </MenuItem>
                                <MenuItem value="CALLI___"> En- CALLI___ </MenuItem>
                                <MenuItem value="CALLIGR2"> En- CALLIGR2 </MenuItem>
                                <MenuItem value="CALLN___"> En- CALLN___ </MenuItem>
                                <MenuItem value="CALLT___"> En- CALLT___ </MenuItem>
                                <MenuItem value="CAMERON_"> En- CAMERON_ </MenuItem>
                                <MenuItem value="CARLA___"> En- CARLA___ </MenuItem>
                                <MenuItem value="CARLOS__"> En- CARLOS__ </MenuItem>
                                <MenuItem value="CARNES__"> En- CARNES__ </MenuItem>
                                <MenuItem value="CART2BI_"> En- CART2BI_ </MenuItem>
                                <MenuItem value="CART2C__"> En- CART2C__ </MenuItem>
                                <MenuItem value="CART2N__"> En- CART2N__ </MenuItem>
                                <MenuItem value="CART2T__"> En- CART2T__ </MenuItem>
                                <MenuItem value="CART2WB_"> En- CART2WB_ </MenuItem>
                                <MenuItem value="CARTB___"> En- CARTB___ </MenuItem>
                                <MenuItem value="CARTI___"> En- CARTI___ </MenuItem>
                                <MenuItem value="CARTN___"> En- CARTN___ </MenuItem>
                                <MenuItem value="CARTNC__"> En- CARTNC__ </MenuItem>
                                <MenuItem value="CARTWI__"> En- CARTWI__ </MenuItem>
                                <MenuItem value="CASEY___"> En- CASEY___ </MenuItem>
                                <MenuItem value="CASMIRA_"> En- CASMIRA_ </MenuItem>
                                <MenuItem value="CASTLE__"> En- CASTLE__ </MenuItem>
                                <MenuItem value="CAVEMAN_"> En- CAVEMAN_ </MenuItem>
                                <MenuItem value="CHAPMAN_"> En- CHAPMAN_ </MenuItem>
                                <MenuItem value="CHARLES_"> En- CHARLES_ </MenuItem>
                                <MenuItem value="CHAS____"> En- CHAS____ </MenuItem>
                                <MenuItem value="CHIPPER_"> En- CHIPPER_ </MenuItem>
                                <MenuItem value="CHRIS___"> En- CHRIS___ </MenuItem>
                                <MenuItem value="CHRISTA_"> En- CHRISTA_ </MenuItem>
                                <MenuItem value="CHUCKIE_"> En- CHUCKIE_ </MenuItem>
                                <MenuItem value="CLARXN__"> En- CLARXN__ </MenuItem>
                                <MenuItem value="CLOWB___"> En- CLOWB___ </MenuItem>
                                <MenuItem value="CLOWN___"> En- CLOWN___ </MenuItem>
                                <MenuItem value="CLOWWBI_"> En- CLOWWBI_ </MenuItem>
                                <MenuItem value="CLYDE___"> En- CLYDE___ </MenuItem>
                                <MenuItem value="COBB____"> En- COBB____ </MenuItem>
                                <MenuItem value="CODY____"> En- CODY____ </MenuItem>
                                <MenuItem value="CONFUSED"> En- CONFUSED </MenuItem>
                                <MenuItem value="COOL____"> En- COOL____ </MenuItem>
                                <MenuItem value="CORBITT_"> En- CORBITT_ </MenuItem>
                                <MenuItem value="COVEY___"> En- COVEY___ </MenuItem>
                                <MenuItem value="CURLYQ__"> En- CURLYQ__ </MenuItem>
                                <MenuItem value="CURTIS__"> En- CURTIS__ </MenuItem>
                                <MenuItem value="DADO____"> En- DADO____ </MenuItem>
                                <MenuItem value="DAKOTA__"> En- DAKOTA__ </MenuItem>
                                <MenuItem value="DANA____"> En- DANA____ </MenuItem>
                                <MenuItem value="DANIEL__"> En- DANIEL__ </MenuItem>
                                <MenuItem value="DART____"> En- DART____ </MenuItem>
                                <MenuItem value="DAVE____"> En- DAVE____ </MenuItem>
                                <MenuItem value="DAVIS___"> En- DAVIS___ </MenuItem>
                                <MenuItem value="DAWSON__"> En- DAWSON__ </MenuItem>
                                <MenuItem value="DEBBIJO_"> En- DEBBIJO_ </MenuItem>
                                <MenuItem value="DELLS___"> En- DELLS___ </MenuItem>
                                <MenuItem value="DENNIS__"> En- DENNIS__ </MenuItem>
                                <MenuItem value="DERBY___"> En- DERBY___ </MenuItem>
                                <MenuItem value="DESIGNE1"> En- DESIGNE1 </MenuItem>
                                <MenuItem value="DESIGNE2"> En- DESIGNE2 </MenuItem>
                                <MenuItem value="DIK_____"> En- DIK_____ </MenuItem>
                                <MenuItem value="DINO____"> En- DINO____ </MenuItem>
                                <MenuItem value="DLNFONT_"> En- DLNFONT_ </MenuItem>
                                <MenuItem value="DOCTEB__"> En- DOCTEB__ </MenuItem>
                                <MenuItem value="DOCTECN_"> En- DOCTECN_ </MenuItem>
                                <MenuItem value="DOCTEN__"> En- DOCTEN__ </MenuItem>
                                <MenuItem value="DOCTEWN_"> En- DOCTEWN_ </MenuItem>
                                <MenuItem value="DOCTOR__"> En- DOCTOR__ </MenuItem>
                                <MenuItem value="DOM_____"> En- DOM_____ </MenuItem>
                                <MenuItem value="DOODN___"> En- DOODN___ </MenuItem>
                                <MenuItem value="DOUGLAS_"> En- DOUGLAS_ </MenuItem>
                                <MenuItem value="DUNSAY__"> En- DUNSAY__ </MenuItem>
                                <MenuItem value="EDDIE___"> En- EDDIE___ </MenuItem>
                                <MenuItem value="EDWARD__"> En- EDWARD__ </MenuItem>
                                <MenuItem value="ELECTRIC"> En- ELECTRIC </MenuItem>
                                <MenuItem value="Elegance"> En- Elegance </MenuItem>
                                <MenuItem value="Ellis___"> En- Ellis___ </MenuItem>
                                <MenuItem value="engli001"> En- engli001 </MenuItem>
                                <MenuItem value="engli002"> En- engli002 </MenuItem>
                                <MenuItem value="engli003"> En- engli003 </MenuItem>
                                <MenuItem value="engli004"> En- engli004 </MenuItem>
                                <MenuItem value="engli005"> En- engli005 </MenuItem>
                                <MenuItem value="engli006"> En- engli006 </MenuItem>
                                <MenuItem value="engli007"> En- engli007 </MenuItem>
                                <MenuItem value="engli008"> En- engli008 </MenuItem>
                                <MenuItem value="engli009"> En- engli009 </MenuItem>
                                <MenuItem value="engli010"> En- engli010 </MenuItem>
                                <MenuItem value="engli011"> En- engli011 </MenuItem>
                                <MenuItem value="engli012"> En- engli012 </MenuItem>
                                <MenuItem value="engli013"> En- engli013 </MenuItem>
                                <MenuItem value="engli014"> En- engli014 </MenuItem>
                                <MenuItem value="engli015"> En- engli015 </MenuItem>
                                <MenuItem value="engli016"> En- engli016 </MenuItem>
                                <MenuItem value="engli017"> En- engli017 </MenuItem>
                                <MenuItem value="engli018"> En- engli018 </MenuItem>
                                <MenuItem value="engli019"> En- engli019 </MenuItem>
                                <MenuItem value="engli020"> En- engli020 </MenuItem>
                                <MenuItem value="engli021"> En- engli021 </MenuItem>
                                <MenuItem value="engli022"> En- engli022 </MenuItem>
                                <MenuItem value="engli023"> En- engli023 </MenuItem>
                                <MenuItem value="engli024"> En- engli024 </MenuItem>
                                <MenuItem value="engli025"> En- engli025 </MenuItem>
                                <MenuItem value="engli026"> En- engli026 </MenuItem>
                                <MenuItem value="engli027"> En- engli027 </MenuItem>
                                <MenuItem value="engli028"> En- engli028 </MenuItem>
                                <MenuItem value="engli029"> En- engli029 </MenuItem>
                                <MenuItem value="engli030"> En- engli030 </MenuItem>
                                <MenuItem value="engli031"> En- engli031 </MenuItem>
                                <MenuItem value="engli032"> En- engli032 </MenuItem>
                                <MenuItem value="engli033"> En- engli033 </MenuItem>
                                <MenuItem value="engli034"> En- engli034 </MenuItem>
                                <MenuItem value="engli035"> En- engli035 </MenuItem>
                                <MenuItem value="engli036"> En- engli036 </MenuItem>
                                <MenuItem value="engli037"> En- engli037 </MenuItem>
                                <MenuItem value="engli038"> En- engli038 </MenuItem>
                                <MenuItem value="engli039"> En- engli039 </MenuItem>
                                <MenuItem value="engli040"> En- engli040 </MenuItem>
                                <MenuItem value="engli041"> En- engli041 </MenuItem>
                                <MenuItem value="engli042"> En- engli042 </MenuItem>
                                <MenuItem value="engli043"> En- engli043 </MenuItem>
                                <MenuItem value="engli044"> En- engli044 </MenuItem>
                                <MenuItem value="engli045"> En- engli045 </MenuItem>
                                <MenuItem value="engli046"> En- engli046 </MenuItem>
                                <MenuItem value="engli047"> En- engli047 </MenuItem>
                                <MenuItem value="engli048"> En- engli048 </MenuItem>
                                <MenuItem value="engli049"> En- engli049 </MenuItem>
                                <MenuItem value="engli050"> En- engli050 </MenuItem>
                                <MenuItem value="engli051"> En- engli051 </MenuItem>
                                <MenuItem value="engli052"> En- engli052 </MenuItem>
                                <MenuItem value="engli053"> En- engli053 </MenuItem>
                                <MenuItem value="engli054"> En- engli054 </MenuItem>
                                <MenuItem value="engli055"> En- engli055 </MenuItem>
                                <MenuItem value="engli056"> En- engli056 </MenuItem>
                                <MenuItem value="engli057"> En- engli057 </MenuItem>
                                <MenuItem value="engli058"> En- engli058 </MenuItem>
                                <MenuItem value="engli059"> En- engli059 </MenuItem>
                                <MenuItem value="engli060"> En- engli060 </MenuItem>
                                <MenuItem value="engli061"> En- engli061 </MenuItem>
                                <MenuItem value="engli062"> En- engli062 </MenuItem>
                                <MenuItem value="engli063"> En- engli063 </MenuItem>
                                <MenuItem value="engli064"> En- engli064 </MenuItem>
                                <MenuItem value="engli065"> En- engli065 </MenuItem>
                                <MenuItem value="engli066"> En- engli066 </MenuItem>
                                <MenuItem value="engli067"> En- engli067 </MenuItem>
                                <MenuItem value="engli068"> En- engli068 </MenuItem>
                                <MenuItem value="engli069"> En- engli069 </MenuItem>
                                <MenuItem value="engli070"> En- engli070 </MenuItem>
                                <MenuItem value="engli071"> En- engli071 </MenuItem>
                                <MenuItem value="engli072"> En- engli072 </MenuItem>
                                <MenuItem value="engli073"> En- engli073 </MenuItem>
                                <MenuItem value="engli074"> En- engli074 </MenuItem>
                                <MenuItem value="engli075"> En- engli075 </MenuItem>
                                <MenuItem value="engli076"> En- engli076 </MenuItem>
                                <MenuItem value="engli077"> En- engli077 </MenuItem>
                                <MenuItem value="engli078"> En- engli078 </MenuItem>
                                <MenuItem value="engli079"> En- engli079 </MenuItem>
                                <MenuItem value="engli080"> En- engli080 </MenuItem>
                                <MenuItem value="engli081"> En- engli081 </MenuItem>
                                <MenuItem value="engli082"> En- engli082 </MenuItem>
                                <MenuItem value="engli083"> En- engli083 </MenuItem>
                                <MenuItem value="engli085"> En- engli085 </MenuItem>
                                <MenuItem value="engli086"> En- engli086 </MenuItem>
                                <MenuItem value="engli087"> En- engli087 </MenuItem>
                                <MenuItem value="engli088"> En- engli088 </MenuItem>
                                <MenuItem value="engli0884"> En- engli0884 </MenuItem>
                                <MenuItem value="engli089"> En- engli089 </MenuItem>
                                <MenuItem value="engli090"> En- engli090 </MenuItem>
                                <MenuItem value="engli091"> En- engli091 </MenuItem>
                                <MenuItem value="engli092"> En- engli092 </MenuItem>
                                <MenuItem value="engli093"> En- engli093 </MenuItem>
                                <MenuItem value="engli094"> En- engli094 </MenuItem>
                                <MenuItem value="engli095"> En- engli095 </MenuItem>
                                <MenuItem value="engli096"> En- engli096 </MenuItem>
                                <MenuItem value="engli097"> En- engli097 </MenuItem>
                                <MenuItem value="engli098"> En- engli098 </MenuItem>
                                <MenuItem value="engli099"> En- engli099 </MenuItem>
                                <MenuItem value="engli100"> En- engli100 </MenuItem>
                                <MenuItem value="engli101"> En- engli101 </MenuItem>
                                <MenuItem value="engli102"> En- engli102 </MenuItem>
                                <MenuItem value="engli103"> En- engli103 </MenuItem>
                                <MenuItem value="engli104"> En- engli104 </MenuItem>
                                <MenuItem value="engli105"> En- engli105 </MenuItem>
                                <MenuItem value="engli106"> En- engli106 </MenuItem>
                                <MenuItem value="engli107"> En- engli107 </MenuItem>
                                <MenuItem value="engli108"> En- engli108 </MenuItem>
                                <MenuItem value="engli109"> En- engli109 </MenuItem>
                                <MenuItem value="engli110"> En- engli110 </MenuItem>
                                <MenuItem value="engli111"> En- engli111 </MenuItem>
                                <MenuItem value="engli112"> En- engli112 </MenuItem>
                                <MenuItem value="engli113"> En- engli113 </MenuItem>
                                <MenuItem value="engli114"> En- engli114 </MenuItem>
                                <MenuItem value="engli115"> En- engli115 </MenuItem>
                                <MenuItem value="engli116"> En- engli116 </MenuItem>
                                <MenuItem value="engli117"> En- engli117 </MenuItem>
                                <MenuItem value="engli118"> En- engli118 </MenuItem>
                                <MenuItem value="engli119"> En- engli119 </MenuItem>
                                <MenuItem value="engli120"> En- engli120 </MenuItem>
                                <MenuItem value="engli121"> En- engli121 </MenuItem>
                                <MenuItem value="engli122"> En- engli122 </MenuItem>
                                <MenuItem value="engli123"> En- engli123 </MenuItem>
                                <MenuItem value="engli124"> En- engli124 </MenuItem>
                                <MenuItem value="engli125"> En- engli125 </MenuItem>
                                <MenuItem value="engli126"> En- engli126 </MenuItem>
                                <MenuItem value="engli127"> En- engli127 </MenuItem>
                                <MenuItem value="engli128"> En- engli128 </MenuItem>
                                <MenuItem value="engli129"> En- engli129 </MenuItem>
                                <MenuItem value="engli130"> En- engli130 </MenuItem>
                                <MenuItem value="engli131"> En- engli131 </MenuItem>
                                <MenuItem value="engli132"> En- engli132 </MenuItem>
                                <MenuItem value="engli133"> En- engli133 </MenuItem>
                                <MenuItem value="engli134"> En- engli134 </MenuItem>
                                <MenuItem value="engli135"> En- engli135 </MenuItem>
                                <MenuItem value="engli136"> En- engli136 </MenuItem>
                                <MenuItem value="engli137"> En- engli137 </MenuItem>
                                <MenuItem value="engli138"> En- engli138 </MenuItem>
                                <MenuItem value="engli139"> En- engli139 </MenuItem>
                                <MenuItem value="engli140"> En- engli140 </MenuItem>
                                <MenuItem value="engli141"> En- engli141 </MenuItem>
                                <MenuItem value="engli142"> En- engli142 </MenuItem>
                                <MenuItem value="engli143"> En- engli143 </MenuItem>
                                <MenuItem value="engli144"> En- engli144 </MenuItem>
                                <MenuItem value="engli145"> En- engli145 </MenuItem>
                                <MenuItem value="engli146"> En- engli146 </MenuItem>
                                <MenuItem value="engli147"> En- engli147 </MenuItem>
                                <MenuItem value="engli148"> En- engli148 </MenuItem>
                                <MenuItem value="engli149"> En- engli149 </MenuItem>
                                <MenuItem value="engli150"> En- engli150 </MenuItem>
                                <MenuItem value="engli151"> En- engli151 </MenuItem>
                                <MenuItem value="engli152"> En- engli152 </MenuItem>
                                <MenuItem value="engli153"> En- engli153 </MenuItem>
                                <MenuItem value="engli154"> En- engli154 </MenuItem>
                                <MenuItem value="engli155"> En- engli155 </MenuItem>
                                <MenuItem value="engli156"> En- engli156 </MenuItem>
                                <MenuItem value="engli157"> En- engli157 </MenuItem>
                                <MenuItem value="engli158"> En- engli158 </MenuItem>
                                <MenuItem value="engli159"> En- engli159 </MenuItem>
                                <MenuItem value="engli160"> En- engli160 </MenuItem>
                                <MenuItem value="engli161"> En- engli161 </MenuItem>
                                <MenuItem value="engli162"> En- engli162 </MenuItem>
                                <MenuItem value="engli163"> En- engli163 </MenuItem>
                                <MenuItem value="engli164"> En- engli164 </MenuItem>
                                <MenuItem value="engli165"> En- engli165 </MenuItem>
                                <MenuItem value="engli166"> En- engli166 </MenuItem>
                                <MenuItem value="engli167"> En- engli167 </MenuItem>
                                <MenuItem value="engli168"> En- engli168 </MenuItem>
                                <MenuItem value="engli169"> En- engli169 </MenuItem>
                                <MenuItem value="engli170"> En- engli170 </MenuItem>
                                <MenuItem value="engli171"> En- engli171 </MenuItem>
                                <MenuItem value="engli172"> En- engli172 </MenuItem>
                                <MenuItem value="engli173"> En- engli173 </MenuItem>
                                <MenuItem value="engli174"> En- engli174 </MenuItem>
                                <MenuItem value="engli175"> En- engli175 </MenuItem>
                                <MenuItem value="engli176"> En- engli176 </MenuItem>
                                <MenuItem value="engli177"> En- engli177 </MenuItem>
                                <MenuItem value="engli178"> En- engli178 </MenuItem>
                                <MenuItem value="engli179"> En- engli179 </MenuItem>
                                <MenuItem value="engli180"> En- engli180 </MenuItem>
                                <MenuItem value="engli181"> En- engli181 </MenuItem>
                                <MenuItem value="engli182"> En- engli182 </MenuItem>
                                <MenuItem value="engli183"> En- engli183 </MenuItem>
                                <MenuItem value="engli184"> En- engli184 </MenuItem>
                                <MenuItem value="engli185"> En- engli185 </MenuItem>
                                <MenuItem value="engli186"> En- engli186 </MenuItem>
                                <MenuItem value="engli187"> En- engli187 </MenuItem>
                                <MenuItem value="engli188"> En- engli188 </MenuItem>
                                <MenuItem value="engli189"> En- engli189 </MenuItem>
                                <MenuItem value="engli190"> En- engli190 </MenuItem>
                                <MenuItem value="engli191"> En- engli191 </MenuItem>
                                <MenuItem value="engli192"> En- engli192 </MenuItem>
                                <MenuItem value="engli193"> En- engli193 </MenuItem>
                                <MenuItem value="engli194"> En- engli194 </MenuItem>
                                <MenuItem value="engli195"> En- engli195 </MenuItem>
                                <MenuItem value="engli196"> En- engli196 </MenuItem>
                                <MenuItem value="engli197"> En- engli197 </MenuItem>
                                <MenuItem value="engli198"> En- engli198 </MenuItem>
                                <MenuItem value="engli199"> En- engli199 </MenuItem>
                                <MenuItem value="engli200"> En- engli200 </MenuItem>
                                <MenuItem value="ERIC____"> En- ERIC____ </MenuItem>
                                <MenuItem value="ERNEST__"> En- ERNEST__ </MenuItem>
                                <MenuItem value="EVON____"> En- EVON____ </MenuItem>
                                <MenuItem value="Excess__"> En- Excess__ </MenuItem>
                                <MenuItem value="FARGO___"> En- FARGO___ </MenuItem>
                                <MenuItem value="FELTPEN_"> En- FELTPEN_ </MenuItem>
                                <MenuItem value="FINLEY__"> En- FINLEY__ </MenuItem>
                                <MenuItem value="FITZN___"> En- FITZN___ </MenuItem>
                                <MenuItem value="FLAPN___"> En- FLAPN___ </MenuItem>
                                <MenuItem value="FOULM___"> En- FOULM___ </MenuItem>
                                <MenuItem value="FOUNP___"> En- FOUNP___ </MenuItem>
                                <MenuItem value="FOX_____"> En- FOX_____ </MenuItem>
                                <MenuItem value="FRANCIS_"> En- FRANCIS_ </MenuItem>
                                <MenuItem value="FREDRIC_"> En- FREDRIC_ </MenuItem>
                                <MenuItem value="FRISCO__"> En- FRISCO__ </MenuItem>
                                <MenuItem value="FROGGER_"> En- FROGGER_ </MenuItem>
                                <MenuItem value="GAINES__"> En- GAINES__ </MenuItem>
                                <MenuItem value="GARY____"> En- GARY____ </MenuItem>
                                <MenuItem value="GAWKY___"> En- GAWKY___ </MenuItem>
                                <MenuItem value="GENE____"> En- GENE____ </MenuItem>
                                <MenuItem value="GENIN___"> En- GENIN___ </MenuItem>
                                <MenuItem value="Genuine_"> En- Genuine_ </MenuItem>
                                <MenuItem value="GEORGIA_"> En- GEORGIA_ </MenuItem>
                                <MenuItem value="GILBERT_"> En- GILBERT_ </MenuItem>
                                <MenuItem value="GIRLY___"> En- GIRLY___ </MenuItem>
                                <MenuItem value="GIVENS__"> En- GIVENS__ </MenuItem>
                                <MenuItem value="GLADYS__"> En- GLADYS__ </MenuItem>
                                <MenuItem value="GODUCKS_"> En- GODUCKS_ </MenuItem>
                                <MenuItem value="GOLDISH_"> En- GOLDISH_ </MenuItem>
                                <MenuItem value="GOOCH___"> En- GOOCH___ </MenuItem>
                                <MenuItem value="GRAFFITI"> En- GRAFFITI </MenuItem>
                                <MenuItem value="GREG____"> En- GREG____ </MenuItem>
                                <MenuItem value="GRETCH__"> En- GRETCH__ </MenuItem>
                                <MenuItem value="GROON___"> En- GROON___ </MenuItem>
                                <MenuItem value="GROVER__"> En- GROVER__ </MenuItem>
                                <MenuItem value="HADLEY__"> En- HADLEY__ </MenuItem>
                                <MenuItem value="HADRIAN_"> En- HADRIAN_ </MenuItem>
                                <MenuItem value="HAK_____"> En- HAK_____ </MenuItem>
                                <MenuItem value="HALL____"> En- HALL____ </MenuItem>
                                <MenuItem value="HANK____"> En- HANK____ </MenuItem>
                                <MenuItem value="HANX____"> En- HANX____ </MenuItem>
                                <MenuItem value="HARVARD_"> En- HARVARD_ </MenuItem>
                                <MenuItem value="HEATHER_"> En- HEATHER_ </MenuItem>
                                <MenuItem value="HEAVWN__"> En- HEAVWN__ </MenuItem>
                                <MenuItem value="Helte___"> En- Helte___ </MenuItem>
                                <MenuItem value="HERIN___"> En- HERIN___ </MenuItem>
                                <MenuItem value="Herman__"> En- Herman__ </MenuItem>
                                <MenuItem value="HEX_____"> En- HEX_____ </MenuItem>
                                <MenuItem value="HIGHS___"> En- HIGHS___ </MenuItem>
                                <MenuItem value="HINTO___"> En- HINTO___ </MenuItem>
                                <MenuItem value="HODGE___"> En- HODGE___ </MenuItem>
                                <MenuItem value="HOLITAS_"> En- HOLITAS_ </MenuItem>
                                <MenuItem value="HOTCH___"> En- HOTCH___ </MenuItem>
                                <MenuItem value="HUGHES__"> En- HUGHES__ </MenuItem>
                                <MenuItem value="IAN_____"> En- IAN_____ </MenuItem>
                                <MenuItem value="ILLINI__"> En- ILLINI__ </MenuItem>
                                <MenuItem value="INFORMAL"> En- INFORMAL </MenuItem>
                                <MenuItem value="IRA_____"> En- IRA_____ </MenuItem>
                                <MenuItem value="IRV_____"> En- IRV_____ </MenuItem>
                                <MenuItem value="ISAAC___"> En- ISAAC___ </MenuItem>
                                <MenuItem value="Isabelle"> En- Isabelle </MenuItem>
                                <MenuItem value="IVYLN___"> En- IVYLN___ </MenuItem>
                                <MenuItem value="JACKIE__"> En- JACKIE__ </MenuItem>
                                <MenuItem value="JAMES___"> En- JAMES___ </MenuItem>
                                <MenuItem value="JANET___"> En- JANET___ </MenuItem>
                                <MenuItem value="JANTZ___"> En- JANTZ___ </MenuItem>
                                <MenuItem value="JAPAB___"> En- JAPAB___ </MenuItem>
                                <MenuItem value="JDRFONT_"> En- JDRFONT_ </MenuItem>
                                <MenuItem value="JEFF____"> En- JEFF____ </MenuItem>
                                <MenuItem value="JERRY___"> En- JERRY___ </MenuItem>
                                <MenuItem value="JERSEY__"> En- JERSEY__ </MenuItem>
                                <MenuItem value="JESSA___"> En- JESSA___ </MenuItem>
                                <MenuItem value="JESSICA_"> En- JESSICA_ </MenuItem>
                                <MenuItem value="JIMBO___"> En- JIMBO___ </MenuItem>
                                <MenuItem value="Joan____"> En- Joan____ </MenuItem>
                                <MenuItem value="JOEL____"> En- JOEL____ </MenuItem>
                                <MenuItem value="JOHN____"> En- JOHN____ </MenuItem>
                                <MenuItem value="JONSON__"> En- JONSON__ </MenuItem>
                                <MenuItem value="JOSEPH__"> En- JOSEPH__ </MenuItem>
                                <MenuItem value="JULIE___"> En- JULIE___ </MenuItem>
                                <MenuItem value="Justice_"> En- Justice_ </MenuItem>
                                <MenuItem value="KABOB"> En- KABOB </MenuItem>
                                <MenuItem value="KABOBB"> En- KABOBB </MenuItem>
                                <MenuItem value="KABOBBI"> En- KABOBBI </MenuItem>
                                <MenuItem value="KABOBE"> En- KABOBE </MenuItem>
                                <MenuItem value="KABOBEI"> En- KABOBEI </MenuItem>
                                <MenuItem value="KABOBI"> En- KABOBI </MenuItem>
                                <MenuItem value="KABOBKI"> En- KABOBKI </MenuItem>
                                <MenuItem value="KABOBL"> En- KABOBL </MenuItem>
                                <MenuItem value="KADAMOL_"> En- KADAMOL_ </MenuItem>
                                <MenuItem value="KAEMPF__"> En- KAEMPF__ </MenuItem>
                                <MenuItem value="KASMIR"> En- KASMIR </MenuItem>
                                <MenuItem value="KATARIN"> En- KATARIN </MenuItem>
                                <MenuItem value="KATARINI"> En- KATARINI </MenuItem>
                                <MenuItem value="KATZOFF_"> En- KATZOFF_ </MenuItem>
                                <MenuItem value="KEDZIE__"> En- KEDZIE__ </MenuItem>
                                <MenuItem value="KELLY___"> En- KELLY___ </MenuItem>
                                <MenuItem value="KEN_____"> En- KEN_____ </MenuItem>
                                <MenuItem value="KENSGTB"> En- KENSGTB </MenuItem>
                                <MenuItem value="KENSGTBI"> En- KENSGTBI </MenuItem>
                                <MenuItem value="KENSGTI"> En- KENSGTI </MenuItem>
                                <MenuItem value="KEYPORT_"> En- KEYPORT_ </MenuItem>
                                <MenuItem value="KINDEL__"> En- KINDEL__ </MenuItem>
                                <MenuItem value="KINGTUT1"> En- KINGTUT1 </MenuItem>
                                <MenuItem value="KINGTUT2"> En- KINGTUT2 </MenuItem>
                                <MenuItem value="KIRASH"> En- KIRASH </MenuItem>
                                <MenuItem value="KIRASHB"> En- KIRASHB </MenuItem>
                                <MenuItem value="KIRASHBI"> En- KIRASHBI </MenuItem>
                                <MenuItem value="KIRASHI"> En- KIRASHI </MenuItem>
                                <MenuItem value="KIVETTS_"> En- KIVETTS_ </MenuItem>
                                <MenuItem value="KLAXON"> En- KLAXON </MenuItem>
                                <MenuItem value="KLINE___"> En- KLINE___ </MenuItem>
                                <MenuItem value="KOFFEE"> En- KOFFEE </MenuItem>
                                <MenuItem value="KOFFEEB"> En- KOFFEEB </MenuItem>
                                <MenuItem value="KOFFEEW"> En- KOFFEEW </MenuItem>
                                <MenuItem value="KOOLKD"> En- KOOLKD </MenuItem>
                                <MenuItem value="KOOLKDB"> En- KOOLKDB </MenuItem>
                                <MenuItem value="KOOLKDBI"> En- KOOLKDBI </MenuItem>
                                <MenuItem value="KOOLKDI"> En- KOOLKDI </MenuItem>
                                <MenuItem value="KOOLKH"> En- KOOLKH </MenuItem>
                                <MenuItem value="KOOLKHB"> En- KOOLKHB </MenuItem>
                                <MenuItem value="KOOLKHBI"> En- KOOLKHBI </MenuItem>
                                <MenuItem value="KOOLKHI"> En- KOOLKHI </MenuItem>
                                <MenuItem value="KOREANS"> En- KOREANS </MenuItem>
                                <MenuItem value="KOREANSB"> En- KOREANSB </MenuItem>
                                <MenuItem value="KRISTIN"> En- KRISTIN </MenuItem>
                                <MenuItem value="KRISTINI"> En- KRISTINI </MenuItem>
                                <MenuItem value="KRONE"> En- KRONE </MenuItem>
                                <MenuItem value="KRONEB"> En- KRONEB </MenuItem>
                                <MenuItem value="KRONEBI"> En- KRONEBI </MenuItem>
                                <MenuItem value="KRONEI"> En- KRONEI </MenuItem>
                                <MenuItem value="KRONEXB"> En- KRONEXB </MenuItem>
                                <MenuItem value="KUBIS___"> En- KUBIS___ </MenuItem>
                                <MenuItem value="KWIRKY"> En- KWIRKY </MenuItem>
                                <MenuItem value="KWIRKYB"> En- KWIRKYB </MenuItem>
                                <MenuItem value="KWIRKYBI"> En- KWIRKYBI </MenuItem>
                                <MenuItem value="KWIRKYI"> En- KWIRKYI </MenuItem>
                                <MenuItem value="KYLE____"> En- KYLE____ </MenuItem>
                                <MenuItem value="LADYBUG"> En- LADYBUG </MenuItem>
                                <MenuItem value="LADYBUGI"> En- LADYBUGI </MenuItem>
                                <MenuItem value="LANNY___"> En- LANNY___ </MenuItem>
                                <MenuItem value="LAP"> En- LAP </MenuItem>
                                <MenuItem value="LAP_B"> En- LAP_B </MenuItem>
                                <MenuItem value="LAP_BI"> En- LAP_BI </MenuItem>
                                <MenuItem value="LAP_D"> En- LAP_D </MenuItem>
                                <MenuItem value="LAP_DI"> En- LAP_DI </MenuItem>
                                <MenuItem value="LAP_E"> En- LAP_E </MenuItem>
                                <MenuItem value="LAP_EI"> En- LAP_EI </MenuItem>
                                <MenuItem value="LAP_I"> En- LAP_I </MenuItem>
                                <MenuItem value="LAP_L"> En- LAP_L </MenuItem>
                                <MenuItem value="LAP_LF"> En- LAP_LF </MenuItem>
                                <MenuItem value="LAP_LFB"> En- LAP_LFB </MenuItem>
                                <MenuItem value="LAP_LFD"> En- LAP_LFD </MenuItem>
                                <MenuItem value="LAP_LFE"> En- LAP_LFE </MenuItem>
                                <MenuItem value="LAP_LFL"> En- LAP_LFL </MenuItem>
                                <MenuItem value="LAP_LI"> En- LAP_LI </MenuItem>
                                <MenuItem value="LARIAT"> En- LARIAT </MenuItem>
                                <MenuItem value="LARIATL"> En- LARIATL </MenuItem>
                                <MenuItem value="LARISM"> En- LARISM </MenuItem>
                                <MenuItem value="LARISMB"> En- LARISMB </MenuItem>
                                <MenuItem value="LARISME"> En- LARISME </MenuItem>
                                <MenuItem value="LARISML"> En- LARISML </MenuItem>
                                <MenuItem value="LARISS"> En- LARISS </MenuItem>
                                <MenuItem value="LARISSB"> En- LARISSB </MenuItem>
                                <MenuItem value="LARISSE"> En- LARISSE </MenuItem>
                                <MenuItem value="LARISSL"> En- LARISSL </MenuItem>
                                <MenuItem value="LAURA___"> En- LAURA___ </MenuItem>
                                <MenuItem value="LAUREN__"> En- LAUREN__ </MenuItem>
                                <MenuItem value="LAWYER__"> En- LAWYER__ </MenuItem>
                                <MenuItem value="LEBANON_"> En- LEBANON_ </MenuItem>
                                <MenuItem value="LEE_____"> En- LEE_____ </MenuItem>
                                <MenuItem value="LEFTY___"> En- LEFTY___ </MenuItem>
                                <MenuItem value="LEISURC"> En- LEISURC </MenuItem>
                                <MenuItem value="LEMOND__"> En- LEMOND__ </MenuItem>
                                <MenuItem value="LEONARD_"> En- LEONARD_ </MenuItem>
                                <MenuItem value="LETRG"> En- LETRG </MenuItem>
                                <MenuItem value="LETRGB"> En- LETRGB </MenuItem>
                                <MenuItem value="LETRGBI"> En- LETRGBI </MenuItem>
                                <MenuItem value="LETRGCB"> En- LETRGCB </MenuItem>
                                <MenuItem value="LETRGCBI"> En- LETRGCBI </MenuItem>
                                <MenuItem value="LETRGCI"> En- LETRGCI </MenuItem>
                                <MenuItem value="LETRGCR"> En- LETRGCR </MenuItem>
                                <MenuItem value="LETRGI"> En- LETRGI </MenuItem>
                                <MenuItem value="LETRMN"> En- LETRMN </MenuItem>
                                <MenuItem value="LETRMNI"> En- LETRMNI </MenuItem>
                                <MenuItem value="LETRMNS"> En- LETRMNS </MenuItem>
                                <MenuItem value="LETRMNSI"> En- LETRMNSI </MenuItem>
                                <MenuItem value="LIDDY"> En- LIDDY </MenuItem>
                                <MenuItem value="LIDDYI"> En- LIDDYI </MenuItem>
                                <MenuItem value="LIDDYK"> En- LIDDYK </MenuItem>
                                <MenuItem value="LIDDYKI"> En- LIDDYKI </MenuItem>
                                <MenuItem value="LINDA___"> En- LINDA___ </MenuItem>
                                <MenuItem value="LINDY___"> En- LINDY___ </MenuItem>
                                <MenuItem value="LINUS_S"> En- LINUS_S </MenuItem>
                                <MenuItem value="LINUS_T"> En- LINUS_T </MenuItem>
                                <MenuItem value="LISSA___"> En- LISSA___ </MenuItem>
                                <MenuItem value="LITH"> En- LITH </MenuItem>
                                <MenuItem value="LITHB"> En- LITHB </MenuItem>
                                <MenuItem value="LITHBI"> En- LITHBI </MenuItem>
                                <MenuItem value="LITHI"> En- LITHI </MenuItem>
                                <MenuItem value="LITHL"> En- LITHL </MenuItem>
                                <MenuItem value="LITHLF"> En- LITHLF </MenuItem>
                                <MenuItem value="LITHLFB"> En- LITHLFB </MenuItem>
                                <MenuItem value="LITHLFE"> En- LITHLFE </MenuItem>
                                <MenuItem value="LITHLFL"> En- LITHLFL </MenuItem>
                                <MenuItem value="LITHLI"> En- LITHLI </MenuItem>
                                <MenuItem value="LIVELYC"> En- LIVELYC </MenuItem>
                                <MenuItem value="LIVELYCI"> En- LIVELYCI </MenuItem>
                                <MenuItem value="LIZARD__"> En- LIZARD__ </MenuItem>
                                <MenuItem value="LOGAN"> En- LOGAN </MenuItem>
                                <MenuItem value="LOGANI"> En- LOGANI </MenuItem>
                                <MenuItem value="LOGASH"> En- LOGASH </MenuItem>
                                <MenuItem value="LOGASHI"> En- LOGASHI </MenuItem>
                                <MenuItem value="LOOKOUT_"> En- LOOKOUT_ </MenuItem>
                                <MenuItem value="LOPPD"> En- LOPPD </MenuItem>
                                <MenuItem value="LOPPDI"> En- LOPPDI </MenuItem>
                                <MenuItem value="LOPPLR"> En- LOPPLR </MenuItem>
                                <MenuItem value="LOPPLRB"> En- LOPPLRB </MenuItem>
                                <MenuItem value="LOPPLRBI"> En- LOPPLRBI </MenuItem>
                                <MenuItem value="LOPPLRI"> En- LOPPLRI </MenuItem>
                                <MenuItem value="LOUBLUE_"> En- LOUBLUE_ </MenuItem>
                                <MenuItem value="LOUD____"> En- LOUD____ </MenuItem>
                                <MenuItem value="LOUISN"> En- LOUISN </MenuItem>
                                <MenuItem value="LOUISNI"> En- LOUISNI </MenuItem>
                                <MenuItem value="LUBLCK"> En- LUBLCK </MenuItem>
                                <MenuItem value="LUBLCKB"> En- LUBLCKB </MenuItem>
                                <MenuItem value="LUBLCKBI"> En- LUBLCKBI </MenuItem>
                                <MenuItem value="LUBLCKI"> En- LUBLCKI </MenuItem>
                                <MenuItem value="LUFTWAFF"> En- LUFTWAFF </MenuItem>
                                <MenuItem value="LUMPY___"> En- LUMPY___ </MenuItem>
                                <MenuItem value="LUX_____"> En- LUX_____ </MenuItem>
                                <MenuItem value="LYNNE___"> En- LYNNE___ </MenuItem>
                                <MenuItem value="MACEDO__"> En- MACEDO__ </MenuItem>
                                <MenuItem value="MACK____"> En- MACK____ </MenuItem>
                                <MenuItem value="MAITB___"> En- MAITB___ </MenuItem>
                                <MenuItem value="MAITE___"> En- MAITE___ </MenuItem>
                                <MenuItem value="MAITN___"> En- MAITN___ </MenuItem>
                                <MenuItem value="Mandela_"> En- Mandela_ </MenuItem>
                                <MenuItem value="MARGRET_"> En- MARGRET_ </MenuItem>
                                <MenuItem value="MARKA___"> En- MARKA___ </MenuItem>
                                <MenuItem value="MARKO___"> En- MARKO___ </MenuItem>
                                <MenuItem value="MARSETT_"> En- MARSETT_ </MenuItem>
                                <MenuItem value="MARSH___"> En- MARSH___ </MenuItem>
                                <MenuItem value="MARTIN__"> En- MARTIN__ </MenuItem>
                                <MenuItem value="Matte___"> En- Matte___ </MenuItem>
                                <MenuItem value="MAYNARD_"> En- MAYNARD_ </MenuItem>
                                <MenuItem value="MELORA__"> En- MELORA__ </MenuItem>
                                <MenuItem value="MENACE__"> En- MENACE__ </MenuItem>
                                <MenuItem value="METAHN__"> En- METAHN__ </MenuItem>
                                <MenuItem value="MICHAEL_"> En- MICHAEL_ </MenuItem>
                                <MenuItem value="MICKEY__"> En- MICKEY__ </MenuItem>
                                <MenuItem value="Microdot"> En- Microdot </MenuItem>
                                <MenuItem value="MKUMBA__"> En- MKUMBA__ </MenuItem>
                                <MenuItem value="MOGENZA_"> En- MOGENZA_ </MenuItem>
                                <MenuItem value="MOMCAT__"> En- MOMCAT__ </MenuItem>
                                <MenuItem value="MONSN___"> En- MONSN___ </MenuItem>
                                <MenuItem value="MOOK____"> En- MOOK____ </MenuItem>
                                <MenuItem value="MOONER__"> En- MOONER__ </MenuItem>
                                <MenuItem value="MORGAN__"> En- MORGAN__ </MenuItem>
                                <MenuItem value="MRSDOG__"> En- MRSDOG__ </MenuItem>
                                <MenuItem value="MYSTERIO"> En- MYSTERIO </MenuItem>
                                <MenuItem value="NASS____"> En- NASS____ </MenuItem>
                                <MenuItem value="Natur___"> En- Natur___ </MenuItem>
                                <MenuItem value="NEATFB__"> En- NEATFB__ </MenuItem>
                                <MenuItem value="NEATFI__"> En- NEATFI__ </MenuItem>
                                <MenuItem value="NEATFN__"> En- NEATFN__ </MenuItem>
                                <MenuItem value="NEENAH__"> En- NEENAH__ </MenuItem>
                                <MenuItem value="NEIL____"> En- NEIL____ </MenuItem>
                                <MenuItem value="NELSON__"> En- NELSON__ </MenuItem>
                                <MenuItem value="Neolith_"> En- Neolith_ </MenuItem>
                                <MenuItem value="NIAGARA_"> En- NIAGARA_ </MenuItem>
                                <MenuItem value="NICOLAS_"> En- NICOLAS_ </MenuItem>
                                <MenuItem value="NICOLE__"> En- NICOLE__ </MenuItem>
                                <MenuItem value="NINJN___"> En- NINJN___ </MenuItem>
                                <MenuItem value="NIP_____"> En- NIP_____ </MenuItem>
                                <MenuItem value="OAKLAND_"> En- OAKLAND_ </MenuItem>
                                <MenuItem value="OCONNOR_"> En- OCONNOR_ </MenuItem>
                                <MenuItem value="OHI_____"> En- OHI_____ </MenuItem>
                                <MenuItem value="OLGA____"> En- OLGA____ </MenuItem>
                                <MenuItem value="Openc___"> En- Openc___ </MenuItem>
                                <MenuItem value="ORINDA__"> En- ORINDA__ </MenuItem>
                                <MenuItem value="ORKAND__"> En- ORKAND__ </MenuItem>
                                <MenuItem value="OYSTER__"> En- OYSTER__ </MenuItem>
                                <MenuItem value="OZ______"> En- OZ______ </MenuItem>
                                <MenuItem value="PAGE____"> En- PAGE____ </MenuItem>
                                <MenuItem value="PALI____"> En- PALI____ </MenuItem>
                                <MenuItem value="PARIB___"> En- PARIB___ </MenuItem>
                                <MenuItem value="PARICN__"> En- PARICN__ </MenuItem>
                                <MenuItem value="PARIN___"> En- PARIN___ </MenuItem>
                                <MenuItem value="PARIWN__"> En- PARIWN__ </MenuItem>
                                <MenuItem value="PAUL____"> En- PAUL____ </MenuItem>
                                <MenuItem value="PAULSON_"> En- PAULSON_ </MenuItem>
                                <MenuItem value="PEANUT__"> En- PEANUT__ </MenuItem>
                                <MenuItem value="PEEJAY__"> En- PEEJAY__ </MenuItem>
                                <MenuItem value="PELOTA__"> En- PELOTA__ </MenuItem>
                                <MenuItem value="PETER___"> En- PETER___ </MenuItem>
                                <MenuItem value="PHAM____"> En- PHAM____ </MenuItem>
                                <MenuItem value="PHILLIP_"> En- PHILLIP_ </MenuItem>
                                <MenuItem value="PIERRE__"> En- PIERRE__ </MenuItem>
                                <MenuItem value="PIETRO__"> En- PIETRO__ </MenuItem>
                                <MenuItem value="PIIKOI__"> En- PIIKOI__ </MenuItem>
                                <MenuItem value="PLANO___"> En- PLANO___ </MenuItem>
                                <MenuItem value="PLAYB___"> En- PLAYB___ </MenuItem>
                                <MenuItem value="PLAYN___"> En- PLAYN___ </MenuItem>
                                <MenuItem value="PLAYT___"> En- PLAYT___ </MenuItem>
                                <MenuItem value="POLITICI"> En- POLITICI </MenuItem>
                                <MenuItem value="Pretext_"> En- Pretext_ </MenuItem>
                                <MenuItem value="PRICE___"> En- PRICE___ </MenuItem>
                                <MenuItem value="PUNKN___"> En- PUNKN___ </MenuItem>
                                <MenuItem value="Puppy___"> En- Puppy___ </MenuItem>
                                <MenuItem value="QUERY___"> En- QUERY___ </MenuItem>
                                <MenuItem value="QUIGLEY_"> En- QUIGLEY_ </MenuItem>
                                <MenuItem value="QUIRK___"> En- QUIRK___ </MenuItem>
                                <MenuItem value="RABIN___"> En- RABIN___ </MenuItem>
                                <MenuItem value="Radagund"> En- Radagund </MenuItem>
                                <MenuItem value="RAI_____"> En- RAI_____ </MenuItem>
                                <MenuItem value="RANDY___"> En- RANDY___ </MenuItem>
                                <MenuItem value="RANSOM__"> En- RANSOM__ </MenuItem>
                                <MenuItem value="Realv___"> En- Realv___ </MenuItem>
                                <MenuItem value="REFIN___"> En- REFIN___ </MenuItem>
                                <MenuItem value="REG-JOE_"> En- REG-JOE_ </MenuItem>
                                <MenuItem value="REID____"> En- REID____ </MenuItem>
                                <MenuItem value="REP_____"> En- REP_____ </MenuItem>
                                <MenuItem value="RICHARD_"> En- RICHARD_ </MenuItem>
                                <MenuItem value="RIGGS___"> En- RIGGS___ </MenuItem>
                                <MenuItem value="ROCKR___"> En- ROCKR___ </MenuItem>
                                <MenuItem value="ROCKY___"> En- ROCKY___ </MenuItem>
                                <MenuItem value="ROEL____"> En- ROEL____ </MenuItem>
                                <MenuItem value="ROLAND__"> En- ROLAND__ </MenuItem>
                                <MenuItem value="ROMANTIC"> En- ROMANTIC </MenuItem>
                                <MenuItem value="RONNIE__"> En- RONNIE__ </MenuItem>
                                <MenuItem value="ROSS____"> En- ROSS____ </MenuItem>
                                <MenuItem value="RUSSELL_"> En- RUSSELL_ </MenuItem>
                                <MenuItem value="SADDLE__"> En- SADDLE__ </MenuItem>
                                <MenuItem value="SAMITCH_"> En- SAMITCH_ </MenuItem>
                                <MenuItem value="SANDRA__"> En- SANDRA__ </MenuItem>
                                <MenuItem value="SCOTT___"> En- SCOTT___ </MenuItem>
                                <MenuItem value="SEATTLE_"> En- SEATTLE_ </MenuItem>
                                <MenuItem value="SEWELL__"> En- SEWELL__ </MenuItem>
                                <MenuItem value="SHARON__"> En- SHARON__ </MenuItem>
                                <MenuItem value="Shelman_"> En- Shelman_ </MenuItem>
                                <MenuItem value="SHER____"> En- SHER____ </MenuItem>
                                <MenuItem value="SHERYL__"> En- SHERYL__ </MenuItem>
                                <MenuItem value="SHIFTY__"> En- SHIFTY__ </MenuItem>
                                <MenuItem value="SHIPPER_"> En- SHIPPER_ </MenuItem>
                                <MenuItem value="SIMKINS_"> En- SIMKINS_ </MenuItem>
                                <MenuItem value="SIMPLE__"> En- SIMPLE__ </MenuItem>
                                <MenuItem value="SLATER__"> En- SLATER__ </MenuItem>
                                <MenuItem value="SONNI___"> En- SONNI___ </MenuItem>
                                <MenuItem value="SPACEY__"> En- SPACEY__ </MenuItem>
                                <MenuItem value="SPARKY__"> En- SPARKY__ </MenuItem>
                                <MenuItem value="SPEAR___"> En- SPEAR___ </MenuItem>
                                <MenuItem value="STARLET_"> En- STARLET_ </MenuItem>
                                <MenuItem value="STEPHEN_"> En- STEPHEN_ </MenuItem>
                                <MenuItem value="STEVO___"> En- STEVO___ </MenuItem>
                                <MenuItem value="STJOHN__"> En- STJOHN__ </MenuItem>
                                <MenuItem value="STONE___"> En- STONE___ </MenuItem>
                                <MenuItem value="STUBBS__"> En- STUBBS__ </MenuItem>
                                <MenuItem value="SUE_____"> En- SUE_____ </MenuItem>
                                <MenuItem value="SUSANNE_"> En- SUSANNE_ </MenuItem>
                                <MenuItem value="SWING___"> En- SWING___ </MenuItem>
                                <MenuItem value="TASHA___"> En- TASHA___ </MenuItem>
                                <MenuItem value="TEACHER_"> En- TEACHER_ </MenuItem>
                                <MenuItem value="TEB_____"> En- TEB_____ </MenuItem>
                                <MenuItem value="TEENG1__"> En- TEENG1__ </MenuItem>
                                <MenuItem value="TEENG2__"> En- TEENG2__ </MenuItem>
                                <MenuItem value="TEENG3__"> En- TEENG3__ </MenuItem>
                                <MenuItem value="TELEGRAP"> En- TELEGRAP </MenuItem>
                                <MenuItem value="TERFONT_"> En- TERFONT_ </MenuItem>
                                <MenuItem value="TERI____"> En- TERI____ </MenuItem>
                                <MenuItem value="TEX_____"> En- TEX_____ </MenuItem>
                                <MenuItem value="THAD____"> En- THAD____ </MenuItem>
                                <MenuItem value="THOMAS__"> En- THOMAS__ </MenuItem>
                                <MenuItem value="THRASHR_"> En- THRASHR_ </MenuItem>
                                <MenuItem value="TIMOTHY_"> En- TIMOTHY_ </MenuItem>
                                <MenuItem value="TOBY____"> En- TOBY____ </MenuItem>
                                <MenuItem value="TOMAS___"> En- TOMAS___ </MenuItem>
                                <MenuItem value="TOMO____"> En- TOMO____ </MenuItem>
                                <MenuItem value="TOPPER__"> En- TOPPER__ </MenuItem>
                                <MenuItem value="Trendy__"> En- Trendy__ </MenuItem>
                                <MenuItem value="TWENTY__"> En- TWENTY__ </MenuItem>
                                <MenuItem value="TYPICN__"> En- TYPICN__ </MenuItem>
                                <MenuItem value="TYPIN___"> En- TYPIN___ </MenuItem>
                                <MenuItem value="ULSTER__"> En- ULSTER__ </MenuItem>
                                <MenuItem value="ULTRASH"> En- ULTRASH </MenuItem>
                                <MenuItem value="ULTRASHI"> En- ULTRASHI </MenuItem>
                                <MenuItem value="UMPQUE__"> En- UMPQUE__ </MenuItem>
                                <MenuItem value="UNCIALM"> En- UNCIALM </MenuItem>
                                <MenuItem value="UNCIALN"> En- UNCIALN </MenuItem>
                                <MenuItem value="UNCIALR"> En- UNCIALR </MenuItem>
                                <MenuItem value="UNVRS"> En- UNVRS </MenuItem>
                                <MenuItem value="UNVRSBI"> En- UNVRSBI </MenuItem>
                                <MenuItem value="UNVRSC"> En- UNVRSC </MenuItem>
                                <MenuItem value="UNVRSCB"> En- UNVRSCB </MenuItem>
                                <MenuItem value="UNVRSCI"> En- UNVRSCI </MenuItem>
                                <MenuItem value="UNVRSCK"> En- UNVRSCK </MenuItem>
                                <MenuItem value="UNVRSCKI"> En- UNVRSCKI </MenuItem>
                                <MenuItem value="UNVRSI"> En- UNVRSI </MenuItem>
                                <MenuItem value="UNVRSK"> En- UNVRSK </MenuItem>
                                <MenuItem value="UNVRSKI"> En- UNVRSKI </MenuItem>
                                <MenuItem value="UNVRSL"> En- UNVRSL </MenuItem>
                                <MenuItem value="UNVRSTY"> En- UNVRSTY </MenuItem>
                                <MenuItem value="URARA___"> En- URARA___ </MenuItem>
                                <MenuItem value="URKLE"> En- URKLE </MenuItem>
                                <MenuItem value="URKLE_I"> En- URKLE_I </MenuItem>
                                <MenuItem value="UVRSTYB"> En- UVRSTYB </MenuItem>
                                <MenuItem value="UVRSTYBI"> En- UVRSTYBI </MenuItem>
                                <MenuItem value="UVRSTYI"> En- UVRSTYI </MenuItem>
                                <MenuItem value="UVRSTYV"> En- UVRSTYV </MenuItem>
                                <MenuItem value="UVRSTYVI"> En- UVRSTYVI </MenuItem>
                                <MenuItem value="VAGBND"> En- VAGBND </MenuItem>
                                <MenuItem value="VAGBNDBI"> En- VAGBNDBI </MenuItem>
                                <MenuItem value="VAGBNDI"> En- VAGBNDI </MenuItem>
                                <MenuItem value="VAGBNDO"> En- VAGBNDO </MenuItem>
                                <MenuItem value="VAGBNDOI"> En- VAGBNDOI </MenuItem>
                                <MenuItem value="VAGBNDSI"> En- VAGBNDSI </MenuItem>
                                <MenuItem value="VALLEY__"> En- VALLEY__ </MenuItem>
                                <MenuItem value="VAMPIRE_"> En- VAMPIRE_ </MenuItem>
                                <MenuItem value="VAN_____"> En- VAN_____ </MenuItem>
                                <MenuItem value="VANDUYN_"> En- VANDUYN_ </MenuItem>
                                <MenuItem value="VANESSA_"> En- VANESSA_ </MenuItem>
                                <MenuItem value="VANGRD"> En- VANGRD </MenuItem>
                                <MenuItem value="VANGRDI"> En- VANGRDI </MenuItem>
                                <MenuItem value="VANGRDOI"> En- VANGRDOI </MenuItem>
                                <MenuItem value="VANGRDS"> En- VANGRDS </MenuItem>
                                <MenuItem value="VANGRDSI"> En- VANGRDSI </MenuItem>
                                <MenuItem value="VARNELL_"> En- VARNELL_ </MenuItem>
                                <MenuItem value="VAUGHN__"> En- VAUGHN__ </MenuItem>
                                <MenuItem value="VERONA__"> En- VERONA__ </MenuItem>
                                <MenuItem value="VESTRA__"> En- VESTRA__ </MenuItem>
                                <MenuItem value="VICKI___"> En- VICKI___ </MenuItem>
                                <MenuItem value="VIENNA__"> En- VIENNA__ </MenuItem>
                                <MenuItem value="VILLA___"> En- VILLA___ </MenuItem>
                                <MenuItem value="VINYLC"> En- VINYLC </MenuItem>
                                <MenuItem value="VINYLCH"> En- VINYLCH </MenuItem>
                                <MenuItem value="VINYLCHI"> En- VINYLCHI </MenuItem>
                                <MenuItem value="VIVACS"> En- VIVACS </MenuItem>
                                <MenuItem value="VOCKEL__"> En- VOCKEL__ </MenuItem>
                                <MenuItem value="VOSTREY"> En- VOSTREY </MenuItem>
                                <MenuItem value="VRANISH_"> En- VRANISH_ </MenuItem>
                                <MenuItem value="WACKY___"> En- WACKY___ </MenuItem>
                                <MenuItem value="WACKYC"> En- WACKYC </MenuItem>
                                <MenuItem value="WACKYCB"> En- WACKYCB </MenuItem>
                                <MenuItem value="WADDLE__"> En- WADDLE__ </MenuItem>
                                <MenuItem value="WADELLCB"> En- WADELLCB </MenuItem>
                                <MenuItem value="WALTER__"> En- WALTER__ </MenuItem>
                                <MenuItem value="WANKLE"> En- WANKLE </MenuItem>
                                <MenuItem value="WANTDPST"> En- WANTDPST </MenuItem>
                                <MenuItem value="WARREN__"> En- WARREN__ </MenuItem>
                                <MenuItem value="WEBSTER_"> En- WEBSTER_ </MenuItem>
                                <MenuItem value="WEEKNIP"> En- WEEKNIP </MenuItem>
                                <MenuItem value="WEEKNIPI"> En- WEEKNIPI </MenuItem>
                                <MenuItem value="WEIRDOW"> En- WEIRDOW </MenuItem>
                                <MenuItem value="WENDLE__"> En- WENDLE__ </MenuItem>
                                <MenuItem value="WENDY___"> En- WENDY___ </MenuItem>
                                <MenuItem value="WESTM"> En- WESTM </MenuItem>
                                <MenuItem value="WESTMI"> En- WESTMI </MenuItem>
                                <MenuItem value="WESTMN"> En- WESTMN </MenuItem>
                                <MenuItem value="WESTMNI"> En- WESTMNI </MenuItem>
                                <MenuItem value="WESTRNS"> En- WESTRNS </MenuItem>
                                <MenuItem value="WESTRNSI"> En- WESTRNSI </MenuItem>
                                <MenuItem value="WESTRNSL"> En- WESTRNSL </MenuItem>
                                <MenuItem value="WILDWEST"> En- WILDWEST </MenuItem>
                                <MenuItem value="WILLIAM_"> En- WILLIAM_ </MenuItem>
                                <MenuItem value="WILLOW__"> En- WILLOW__ </MenuItem>
                                <MenuItem value="WINSTNS"> En- WINSTNS </MenuItem>
                                <MenuItem value="WINSTNSA"> En- WINSTNSA </MenuItem>
                                <MenuItem value="WIZZARD"> En- WIZZARD </MenuItem>
                                <MenuItem value="WOBBLES"> En- WOBBLES </MenuItem>
                                <MenuItem value="WOLF____"> En- WOLF____ </MenuItem>
                                <MenuItem value="WOLFGC"> En- WOLFGC </MenuItem>
                                <MenuItem value="WOLFGCB"> En- WOLFGCB </MenuItem>
                                <MenuItem value="WOLFGCBI"> En- WOLFGCBI </MenuItem>
                                <MenuItem value="WOLFGCI"> En- WOLFGCI </MenuItem>
                                <MenuItem value="WOOSTER_"> En- WOOSTER_ </MenuItem>
                                <MenuItem value="WROGHTN"> En- WROGHTN </MenuItem>
                                <MenuItem value="WROGHTNI"> En- WROGHTNI </MenuItem>
                                <MenuItem value="WUNDERA_"> En- WUNDERA_ </MenuItem>
                                <MenuItem value="WYATI___"> En- WYATI___ </MenuItem>
                                <MenuItem value="WYATN___"> En- WYATN___ </MenuItem>
                                <MenuItem value="XPRSV"> En- XPRSV </MenuItem>
                                <MenuItem value="XPRSVB"> En- XPRSVB </MenuItem>
                                <MenuItem value="XPRSVBI"> En- XPRSVBI </MenuItem>
                                <MenuItem value="XPRSVE"> En- XPRSVE </MenuItem>
                                <MenuItem value="XPRSVEI"> En- XPRSVEI </MenuItem>
                                <MenuItem value="XPRSVH"> En- XPRSVH </MenuItem>
                                <MenuItem value="XPRSVHI"> En- XPRSVHI </MenuItem>
                                <MenuItem value="XPRSVI"> En- XPRSVI </MenuItem>
                                <MenuItem value="XPRSVK"> En- XPRSVK </MenuItem>
                                <MenuItem value="XPRSVKI"> En- XPRSVKI </MenuItem>
                                <MenuItem value="XPRSVL"> En- XPRSVL </MenuItem>
                                <MenuItem value="XPRSVLI"> En- XPRSVLI </MenuItem>
                                <MenuItem value="YANZEL__"> En- YANZEL__ </MenuItem>
                                <MenuItem value="YUCATAN"> En- YUCATAN </MenuItem>
                                <MenuItem value="YUCATANI"> En- YUCATANI </MenuItem>
                                <MenuItem value="ZUERBIG_"> En- ZUERBIG_ </MenuItem>


                                <MenuItem value="SaralaBold">Hi- SaralaBold</MenuItem>
                                <MenuItem value="SaralaRegular">Hi- SaralaRegular</MenuItem>
                                <MenuItem value="NotoBold">Hi- NotoBold</MenuItem>
                                <MenuItem value="NotoBoldItalic">Hi- NotoBoldItalic</MenuItem>
                                <MenuItem value="NotoItalic">Hi- NotoItalic</MenuItem>
                                <MenuItem value="NotoRegular">Hi- NotoRegular</MenuItem>
                                <MenuItem value="Alkatra">Hi- Alkatra</MenuItem>
                                <MenuItem value="BakbakOne">Hi- BakbakOne</MenuItem>
                                <MenuItem value="Modak">Hi- Modak</MenuItem>
                                <MenuItem value="PalanquinDarkBold">Hi- PalanquinDarkBold</MenuItem>
                                <MenuItem value="PalanquinDarkMedium">Hi- PalanquinDarkMedium</MenuItem>
                                <MenuItem value="PalanquinDarkRegular">Hi- PalanquinDarkRegular</MenuItem>
                                <MenuItem value="PalanquinDarkSemiBold">Hi- PalanquinDarkSemiBold</MenuItem>
                                <MenuItem value="TiroDevanagariSanskritItalic">Hi- TiroDevanagariSanskritItalic</MenuItem>
                                <MenuItem value="TiroDevanagariSanskritRegular">Hi- TiroDevanagariSanskritRegular</MenuItem>
                                <MenuItem value="YatraOne">Hi- YatraOne</MenuItem>


                            </Select>
                        </FormControl>

                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    size='small'
                                    label="Start Animation"
                                    type="text"
                                    value={item.properties.start_animation}
                                    onChange={(e) => handleInputChange(index, 'start_animation', e.target.value, 'string')}
                                    margin="normal"
                                    fullWidth
                                />

                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    size='small'
                                    label="Start Animation End Time"
                                    type="number"
                                    value={item.properties.start_animation_end_time}
                                    onChange={(e) => handleInputChange(index, 'start_animation_end_time', e.target.value, 'number')}
                                    margin="normal"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    size='small'
                                    label="End Animation"
                                    type="text"
                                    value={item.properties.end_animation}
                                    onChange={(e) => handleInputChange(index, 'end_animation', e.target.value, 'string')}
                                    margin="normal"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    size='small'
                                    label="End Animation Start Time"
                                    type="number"
                                    value={item.properties.end_animation_start_time}
                                    onChange={(e) => handleInputChange(index, 'end_animation_start_time', e.target.value, 'number')}
                                    margin="normal"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<Label />}
                                    onClick={() => addTextItem(index)}
                                    fullWidth
                                >
                                    Add Text
                                </Button>
                            </Grid>
                            <Grid item xs={4}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<Label />}
                                    onClick={() => addImageItem(index)}
                                    fullWidth
                                >
                                    Add Image
                                </Button>
                            </Grid>
                            <Grid item xs={4}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<Label />}
                                    onClick={() => duplicateItem(index)}
                                    fullWidth
                                >
                                    Duplicate
                                </Button>
                            </Grid>



                            <Grid item xs={4}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<Label />}
                                    onClick={() => {
                                        console.log("Move item to up in array", index);
                                        if (index === 0) return;
                                        const updatedData = { ...configurationData };
                                        const temp = updatedData.processing_artifacts.data.video_items[index];
                                        updatedData.processing_artifacts.data.video_items[index] = updatedData.processing_artifacts.data.video_items[index - 1];
                                        updatedData.processing_artifacts.data.video_items[index - 1] = temp;
                                    }}
                                    fullWidth
                                >
                                    Move Up
                                </Button>
                            </Grid>
                            <Grid item xs={4}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<Label />}
                                    onClick={() => {
                                        console.log("Move item to up in array", index);
                                        if (index === 0) return;
                                        const updatedData = { ...configurationData };
                                        const temp = updatedData.processing_artifacts.data.video_items[index];
                                        updatedData.processing_artifacts.data.video_items[index] = updatedData.processing_artifacts.data.video_items[index + 1];
                                        updatedData.processing_artifacts.data.video_items[index + 1] = temp;
                                    }}
                                    fullWidth
                                >
                                    Move Down
                                </Button>
                            </Grid>

                            <Grid item xs={4}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<Label />}
                                    onClick={() => {
                                        const updatedData = { ...configurationData };
                                        updatedData.processing_artifacts.data.video_items.splice(index, 1);
                                        setConfigurationData(updatedData);
                                    }}
                                    fullWidth
                                >
                                    Delete
                                </Button>
                            </Grid>
                        </Grid>




                    </Grid>
                </Grid>
            ))}
            <Button type="submit" variant="contained" color="primary" sx={{ padding: '10px 20px', fontSize: '16px' }}>
                Save
            </Button>
        </form>
    );
};


export default VideoEngineConfigurationEdit;