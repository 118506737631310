import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";


export const fetchPartnerCardsRequest = () => ({
    type: actionTypes.FETCH_PARTNER_CARDS_DATA_REQUEST,
});
export const fetchPartnerCardsSuccess = (data) => ({
    type: actionTypes.FETCH_PARTNER_CARDS_DATA_SUCCESS,
    payload: data,
});
export const fetchPartnerCardsFailure = (error) => ({
    type: actionTypes.FETCH_PARTNER_CARD_GROUP_DATA_FAILURE,
    payload: error,
});

export const fetchPartnerCardsData = (page, limit, search= '', id) => {
    console.log("group id", id);

    return async (dispatch) => {
        
        dispatch(fetchPartnerCardsRequest());
        try {
            const {data} = await api.partnerCards(page, limit,search, id);
            
            dispatch(fetchPartnerCardsSuccess(data));
            console.log("data in partner card action", data);
            return data;
        } catch (error) {
            dispatch(fetchPartnerCardsFailure(error));
        }
    };
};

export const createPartnerCardData = async (newCardData) => {
    const {data} = await api.createPartnerCards(newCardData);
    console.log("data in partner card", data);
};

export const updatePartnerCardsRequest = () => ({
    type: actionTypes.UPDATE_PARTNER_CARDS_DATA_REQUEST,
});
export const updatePartnerCardsSuccess = (data) => ({
    type: actionTypes.UPDATE_PARTNER_CARDS_DATA_SUCCESS,
    payload: data,
});
export const updatePartnerCardsFailure = (error) => ({
    type: actionTypes.UPDATE_PARTNER_CARDS_DATA_FAILURE,
    payload: error,
});

export const updatePartnerCardsData = (updateData) => {
    return async (dispatch) => {
        dispatch(updatePartnerCardsRequest());
        try{
            const response = await api.updatePartnerCards(updateData.id, updateData);
            const updatedCard = response?.data;
            const successAction = updatePartnerCardsSuccess(updatedCard);
            dispatch(successAction);
            return successAction;

        } catch(error) {
            const failureAction = updatePartnerCardsFailure(error.message);
            dispatch(failureAction);
            return failureAction;
        }
    };
};

export const deletePartnerCardsData = async (id) => {
    await api.deletePartnerCards(id);
};

export const sendDuplicatePartnerCardRequest = async (card_id) => {
    const {data} = await api.duplicatePartnerCard(card_id);
    return data;
};

export const sendMapTemplatePartnerCardId = async (formData) => {
    const response = await api.mapTemplateInPartnerCard(formData.card_id, formData.template_id);
}