import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";

export const fetchAllTemplateRequest = () => ({
    type: actionTypes.GET_ALL_TEMPLATE_REQUEST,
});

export const fetchAllTemplateSuccess = (data) => ({
    type: actionTypes.GET_ALL_TEMPLATE_SUCCESS,
    payload: data,
});

export const fetchAllTemplateFailure = (error) => ({
    type: actionTypes.GET_ALL_TEMPLATE_FAILURE,
    payload: error,
});

export const fetchAllTemplateData = () => {
    return async (dispatch) => {
        dispatch(fetchAllTemplateRequest());
        try{
            const data = await api.getAllTemplate();
            console.log("template data in action", data);
            dispatch(fetchAllTemplateSuccess(data));
            return data;
        }catch (error) {
            dispatch(fetchAllTemplateFailure(error));
        }
    };
};

export const createTemplateData = async (formData) => {
    const data = await api.createTemplate(formData);
    return data;
};

export const updateTemplateData = async (formData) => {
    const data = await api.updateTemplate(formData.id, formData);
    return data;
};

export const deleteTemplateData = async (id) => {
    const data = await api.deleteTemplate(id);
    return data;
};
