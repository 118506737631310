import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import DashboardIcon from '@mui/icons-material/Dashboard';
import TopNavigation from './TopNavigation';
import { Typography, Button, useMediaQuery } from '@mui/material';
import Logo from '../../../src/assets/icons/Logo.jpg';
import packageJson from '../../../package.json';
import clearLocalStorage from '../../redux/reducers/authReducers';
import * as actionTypes from "./../../redux/actionTypes";
import Collapse from '@mui/material/Collapse';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: 'white',
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);


export default function MiniDrawer() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const navigate = useNavigate();
  const [open, setOpen] = useState(() => !isMobile);

  const [collapsedMenus, setCollapsedMenus] = useState({
    digitalCard: false,
    digitalInvite: false,
    partnerDigitalCard: false,
    common: false,
    quickUploader: false,
    videoEngine: false,
    bundle: false,
    politicalFrames: false,
  });

  const handleCollapse = (menu) => {
    setCollapsedMenus((prevState) => ({
      ...prevState,
      [menu]: !prevState[menu],
    }));
  };

  useEffect(() => {
    setOpen(!isMobile);
  }, [isMobile]);
  const appVersion = packageJson.version;



  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    // Add your logout logic here
    console.log('Logged out');
    await clearLocalStorage({
      isAuthenticated: false
    }, {
      type: actionTypes.LOG_OUT
    });
    navigate('/auth'); // redirect to login page after logout
  };

  const [language, setLanguage] = useState(() => {
    return localStorage.getItem('language') || 'en';
  });
  const dashboard = {
    en: 'Dashboard',
    hi: 'डैशबोर्ड'
  }
  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar sx={{
            justifyContent: 'space-between',
            ...(open && {
              paddingRight: '240px',
            }),
          }}>
            <IconButton
              color="gray"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
              {!open && (
                <Typography variant="h6" noWrap component="div" sx={{ paddingLeft: 0, color: '#003366', fontWeight: 'bold' }}>
                  <img src={Logo} height={'50px'} alt="Logo" />
                </Typography>
              )}
            </IconButton>
            <TopNavigation open={open} />
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <Typography variant="h6" noWrap component="div" sx={{ paddingLeft: 0, color: '#003366', fontWeight: 'bold' }}>
              {open && <img src={Logo} height={'50px'} alt="Logo" />}
            </Typography>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {[
              { text: dashboard[language], icon: <DashboardIcon />, route: '/dashboard' },

            ].map((item) => (
              <ListItem
                key={item.text}
                disablePadding
                sx={{ display: 'block' }}
                onClick={() => navigate(item.route)}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,

                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.text} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>

              </ListItem>
            ))}



            {/*Digital Card*/}
            <ListItem disablePadding sx={{ display: 'block' }} onClick={() => handleCollapse('digitalCard')}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 4.5,
                  backgroundColor: '#9e9e9e',
                  mt: 0.2
                }}
              >

                <ListItemText primary="Digital Card" sx={{ opacity: open ? 1 : 0 }} />
                {collapsedMenus.digitalCard ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItemButton>
            </ListItem>
            <Collapse in={collapsedMenus.digitalCard} timeout="auto" onmounntOnExit sx={{ backgroundColor: "lightgray" }}>
              {[
                { text: 'Card Group', icon: <ArrowForwardIosIcon />, route: '/cards/card-group' },
                { text: 'Card', icon: <ArrowForwardIosIcon />, route: '/cards/card-group/card' },
                { text: 'Card Entry', icon: <ArrowForwardIosIcon />, route: '/cards/card-entry' },

              ].map((item) => (
                <ListItem
                  key={item.text}
                  disablePadding
                  sx={{ display: 'block' }}
                  onClick={() => navigate(item.route)}
                >
                  <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5 }}>
                    <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                      {React.cloneElement(item.icon, {
                        sx: {
                          fontSize: '0.825rem'
                        }
                      })}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.text}
                      sx={{ opacity: open ? 1 : 0, }}
                      primaryTypographyProps={{
                        style: {
                          whiteSpace: 'normal',
                          wordWrap: 'break-word',
                          fontSize: '0.825rem'
                        }
                      }}
                    />
                  </ListItemButton>

                </ListItem>
              ))}
            </Collapse>


            {/*Political Frames*/}
            <ListItem disablePadding sx={{ display: 'block' }} onClick={() => handleCollapse('politicalFrames')}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 4.5,
                  backgroundColor: '#9e9e9e',
                  mt: 0.2,
                }}
              >
                <ListItemText primary="Political Frames" sx={{ opacity: open ? 1 : 0 }} />
                {collapsedMenus.politicalFrames ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItemButton>
            </ListItem>

            <Collapse in={collapsedMenus.politicalFrames} timeout="auto" unmountOnExit sx={{ backgroundColor: 'lightgray' }}>
              {[
                { text: 'Frame Group', icon: <ArrowForwardIosIcon />, route: '/frame-group' },
                { text: 'Frame Template', icon: <ArrowForwardIosIcon />, route: '/frame-group/frame-template' },
              ].map((item) => (
                <ListItem key={item.text} disablePadding sx={{ display: 'block' }} onClick={() => navigate(item.route)}>
                  <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5 }}>
                    <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                      {React.cloneElement(item.icon, {
                        sx: { fontSize: '0.825rem' },
                      })}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.text}
                      sx={{ opacity: open ? 1 : 0 }}
                      primaryTypographyProps={{
                        style: {
                          whiteSpace: 'normal',
                          wordWrap: 'break-word',
                          fontSize: '0.825rem',
                        },
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </Collapse>

            {/* Digital Invitation */}
            <ListItem disablePadding sx={{ display: 'block' }} onClick={() => handleCollapse('digitalInvite')}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 4.5,
                  backgroundColor: '#9e9e9e',
                  mt: 0.2,
                }}
              >
                <ListItemText primary="Digital Invitation" sx={{ opacity: open ? 1 : 0 }} />
                {collapsedMenus.digitalInvite ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItemButton>
            </ListItem>

            <Collapse in={collapsedMenus.digitalInvite} timeout="auto" unmountOnExit sx={{ backgroundColor: 'lightgray' }}>
              {[
                { text: 'Digital Invite Card Entry', icon: <ArrowForwardIosIcon />, route: '/digital-invite-card-value' },
              ].map((item) => (
                <ListItem key={item.text} disablePadding sx={{ display: 'block' }} onClick={() => navigate(item.route)}>
                  <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5 }}>
                    <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                      {React.cloneElement(item.icon, {
                        sx: { fontSize: '0.825rem' },
                      })}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.text}
                      sx={{ opacity: open ? 1 : 0 }}
                      primaryTypographyProps={{
                        style: {
                          whiteSpace: 'normal',
                          wordWrap: 'break-word',
                          fontSize: '0.825rem',
                        },
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </Collapse>


            {/* Partner Digital Card */}
            <ListItem disablePadding sx={{ display: 'block' }} onClick={() => handleCollapse('partnerDigitalCard')}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 4.5,
                  backgroundColor: '#9e9e9e',
                  mt: 0.2
                }}
              >
                <ListItemText primary="Partner Digital Card" sx={{ opacity: open ? 1 : 0 }} />
                {collapsedMenus.partnerDigitalCard ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItemButton>
            </ListItem>
            <Collapse in={collapsedMenus.partnerDigitalCard} timeout="auto" unmountOnExit sx={{ backgroundColor: 'lightgray' }}>
              {[
                { text: 'Partner Card Group', icon: <ArrowForwardIosIcon />, route: '/cards/partner-card-group' },
                { text: 'Partner Card', icon: <ArrowForwardIosIcon />, route: '/cards/partner-card-group/partner-card' },
                { text: 'Partner Card Entry', icon: <ArrowForwardIosIcon />, route: '/cards/partner-card-entry' },
              ].map((item) => (
                <ListItem
                  key={item.text}
                  disablePadding
                  sx={{ display: 'block' }}
                  onClick={() => navigate(item.route)}
                >
                  <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5 }}>
                    <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                      {React.cloneElement(item.icon, {
                        sx: {
                          fontSize: '0.825rem'
                        }
                      })}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.text}
                      sx={{ opacity: open ? 1 : 0 }}
                      primaryTypographyProps={{
                        style: {
                          whiteSpace: 'normal',
                          wordWrap: 'break-word',
                          fontSize: '0.825rem'
                        }
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </Collapse>

            {/*Offers*/}

            <ListItem disablePadding sx={{ display: 'block' }} onClick={() => handleCollapse('offers')}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 4.5,
                  backgroundColor: '#9e9e9e',
                  mt: 0.2
                }}
              >

                <ListItemText primary="Offers" sx={{ opacity: open ? 1 : 0 }} />
                {collapsedMenus.offers ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItemButton>
            </ListItem>
            <Collapse in={collapsedMenus.offers} timeout="auto" onmounntOnExit sx={{ backgroundColor: "lightgray" }}>
              {[
                { text: 'Diwali Offer', icon: <ArrowForwardIosIcon />, route: '/Diwali-offer' },



              ].map((item) => (
                <ListItem
                  key={item.text}
                  disablePadding
                  sx={{ display: 'block' }}
                  onClick={() => navigate(item.route)}
                >
                  <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5 }}>
                    <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                      {React.cloneElement(item.icon, {
                        sx: {
                          fontSize: '0.825rem'
                        }
                      })}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.text}
                      sx={{ opacity: open ? 1 : 0, }}
                      primaryTypographyProps={{
                        style: {
                          whiteSpace: 'normal',
                          wordWrap: 'break-word',
                          fontSize: '0.825rem'
                        }
                      }}
                    />
                  </ListItemButton>

                </ListItem>
              ))}
            </Collapse>



            {/*Common*/}
            <ListItem disablePadding sx={{ display: 'block' }} onClick={() => handleCollapse('common')}>
              <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 4.5, backgroundColor: '#9e9e9e', mt: 0.2 }}>

                <ListItemText primary="Common" sx={{ opacity: open ? 1 : 0 }} />
                {collapsedMenus.common ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItemButton>
            </ListItem>
            <Collapse in={collapsedMenus.common} timeout="auto" unmountOnExit sx={{ backgroundColor: 'lightgray' }}>
              {[
                { text: 'Download', icon: <ArrowForwardIosIcon />, route: '/common/download' },
                { text: 'Font Style', icon: <ArrowForwardIosIcon />, route: '/font-style' },
              ].map((item) => (
                <ListItem key={item.text} disablePadding sx={{ display: 'block' }} onClick={() => navigate(item.route)}>
                  <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5 }}>
                    <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                      {React.cloneElement(item.icon, {
                        sx: {
                          fontSize: '0.825rem'
                        }
                      })}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.text}
                      sx={{ opacity: open ? 1 : 0, }}
                      primaryTypographyProps={{
                        style: {
                          whiteSpace: 'normal',
                          wordWrap: 'break-word',
                          fontSize: '0.825rem'
                        }
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </Collapse>

            {/*Quick Uploader*/}
            <ListItem disablePadding sx={{ display: 'block' }} onClick={() => handleCollapse('quickUploader')}>
              <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 4.5, backgroundColor: '#9e9e9e', mt: 0.2 }}>

                <ListItemText primary="Quick Uploader" sx={{ opacity: open ? 1 : 0 }} />
                {collapsedMenus.quickUploader ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItemButton>
            </ListItem>
            <Collapse in={collapsedMenus.quickUploader} timeout="auto" unmountOnExit sx={{ backgroundColor: 'lightgray' }}>
              {[
                { text: 'Simple Design Upload(Only One Image)', icon: <ArrowForwardIosIcon />, route: '/quick-uploader/direct-create' },
                { text: 'Foreground and Background Image Upload', icon: <ArrowForwardIosIcon />, route: '/quick-uploader/bg-fg-create' },
                { text: 'Political Image Upload(Only One Image)', icon: <ArrowForwardIosIcon />, route: '/quick-uploader/political-direct-create' },
              ].map((item) => (
                <ListItem key={item.text} disablePadding sx={{ display: 'block' }} onClick={() => navigate(item.route)}>
                  <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5 }}>
                    <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                      {React.cloneElement(item.icon, {
                        sx: {
                          fontSize: '0.825rem'
                        }
                      })}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.text}
                      sx={{ opacity: open ? 1 : 0, }}
                      primaryTypographyProps={{
                        style: {
                          whiteSpace: 'normal',
                          wordWrap: 'break-word',
                          fontSize: '0.825rem'
                        }
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </Collapse>


            {/*Video Engine*/}
            <ListItem disablePadding sx={{ display: 'block' }} onClick={() => handleCollapse('videoEngine')}>
              <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 4.5, backgroundColor: '#9e9e9e', mt: 0.2 }}>

                <ListItemText primary="Video Engine" sx={{ opacity: open ? 1 : 0 }} />
                {collapsedMenus.videoEngine ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItemButton>
            </ListItem>
            <Collapse in={collapsedMenus.videoEngine} timeout="auto" unmountOnExit sx={{ backgroundColor: 'lightgray' }}>
              {[
                { text: 'All Video Templates', icon: <ArrowForwardIosIcon />, route: '/video-engine' },
              ].map((item) => (
                <ListItem key={item.text} disablePadding sx={{ display: 'block' }} onClick={() => navigate(item.route)}>
                  <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5 }}>
                    <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                      {React.cloneElement(item.icon, {
                        sx: {
                          fontSize: '0.825rem'
                        }
                      })}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.text}
                      sx={{ opacity: open ? 1 : 0, }}
                      primaryTypographyProps={{
                        style: {
                          whiteSpace: 'normal',
                          wordWrap: 'break-word',
                          fontSize: '0.825rem'
                        }
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </Collapse>

            {/*Bundle*/}
            <ListItem disablePadding sx={{ display: 'block' }} onClick={() => handleCollapse('bundle')}>
              <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 4.5, backgroundColor: '#9e9e9e', mt: 0.2 }}>
                <ListItemText primary="Bundle" sx={{ opacity: open ? 1 : 0 }} />
                {collapsedMenus.bundle ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItemButton>
            </ListItem>
            <Collapse in={collapsedMenus.bundle} timeout="auto" unmountOnExit sx={{ backgroundColor: 'lightgray' }}>
              {[
                { text: 'Bundle Data', icon: <ArrowForwardIosIcon />, route: '/bundle-data' },
              ].map((item) => (
                <ListItem key={item.text} disablePadding sx={{ display: 'block' }} onClick={() => navigate(item.route)}>
                  <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5 }}>
                    <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                      {React.cloneElement(item.icon, { sx: { fontSize: '0.825rem' } })}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.text}
                      sx={{ opacity: open ? 1 : 0 }}
                      primaryTypographyProps={{
                        style: { whiteSpace: 'normal', wordWrap: 'break-word', fontSize: '0.825rem' }
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </Collapse>
            {/*Political Frames*/}
            <ListItem disablePadding sx={{ display: 'block' }} onClick={() => handleCollapse('politicalFrames')}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 4.5,
                  backgroundColor: '#9e9e9e',
                  mt: 0.2,
                }}
              >
                <ListItemText primary="Political Frames" sx={{ opacity: open ? 1 : 0 }} />
                {collapsedMenus.politicalFrames ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItemButton>
            </ListItem>

            <Collapse in={collapsedMenus.politicalFrames} timeout="auto" unmountOnExit sx={{ backgroundColor: 'lightgray' }}>
              {[
                { text: 'Frame Group', icon: <ArrowForwardIosIcon />, route: '/frame-group' },
                { text: 'Frame Template', icon: <ArrowForwardIosIcon />, route: '/frame-group/frame-template' },
              ].map((item) => (
                <ListItem key={item.text} disablePadding sx={{ display: 'block' }} onClick={() => navigate(item.route)}>
                  <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5 }}>
                    <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                      {React.cloneElement(item.icon, {
                        sx: { fontSize: '0.825rem' },
                      })}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.text}
                      sx={{ opacity: open ? 1 : 0 }}
                      primaryTypographyProps={{
                        style: {
                          whiteSpace: 'normal',
                          wordWrap: 'break-word',
                          fontSize: '0.825rem',
                        },
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </Collapse>

          </List>

          <Box sx={{ flexGrow: 1 }} /> {/* Spacer to push the logout button to the bottom */}
          <Divider />
          {open && (
            <Box sx={{ textAlign: 'center', p: 2 }}>
              <Typography variant="body2" color="textSecondary">
                Version {appVersion}
              </Typography>
              <Button
                variant="contained"
                onClick={handleLogout}
                sx={{
                  mt: 1,
                  width: '100%',
                  textTransform: 'none',
                  fontSize: '1rem',
                  borderRadius: 4,
                  backgroundColor: '#555555',
                }}
              >
                Logout
              </Button>
            </Box>
          )}

        </Drawer>
      </Box>
    </>
  );
}
