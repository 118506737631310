import * as actionTypes from '../actionTypes.js';

const initialState = {
    partnerCardsData: null,
    loading: false,
    error: null,
};

const partnerCardsReducer = (state= initialState, action) => {
    switch(action.type) {
        case actionTypes.FETCH_PARTNER_CARDS_DATA_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.FETCH_PARTNER_CARDS_DATA_SUCCESS:
            return{
                ...state,
                loading: false,
                partnerCardsData: action.payload,
            };
        case actionTypes.FETCH_PARTNER_CARDS_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case actionTypes.UPDATE_PARTNER_CARDS_DATA_REQUEST:
            return {
                ...state,
                loading: false,
                error: null,
            };
        case actionTypes.UPDATE_PARTNER_CARDS_DATA_SUCCESS:
            state.partnerCardsData.data.rows = state.partnerCardsData?.data?.rows.map(card =>
                card.id === action.payload.id ? action.payload : card)
            return {
                ...state,
                loading: false,
                partnerCardsData: state.partnerCardsData
            };
        case actionTypes.UPDATE_PARTNER_CARDS_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;   
    }
};
export default partnerCardsReducer;
