import * as actionTypes from '../actionTypes.js';

const initialState = {
    allPartnerCardsData: null,
    loading: false,
    error: null,
};

const allPartnerCardsReducer = (state= initialState, action) => {
    switch(action.type) {
       
        case actionTypes.FETCH_ALL_PARTNER_CARDS_DATA_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.FETCH_ALL_PARTNER_CARDS_DATA_SUCCESS:
            console.log("data in all card reducer", action.payload)
            return {
                ...state,
                loading: false,
                allPartnerCardsData: action.payload,
            };
        case actionTypes.FETCH_ALL_PARTNER_CARDS_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
      
           

        default:
            return state;
    }
};

export default allPartnerCardsReducer;
