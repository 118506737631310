import * as actionTypes from '../actionTypes.js';

const initialState = {
    partnerCardEntryValueData: null,
    loading: false,
    error: null,
};

const partnerCardEntryValueReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_PAERTNER_CARD_ENTRY_VALUE_DATA_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.FETCH_PAERTNER_CARD_ENTRY_VALUE_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                partnerCardEntryValueData: action.payload,
            };
        case actionTypes.FETCH_PAERTNER_CARD_ENTRY_VALUE_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case actionTypes.UPDATE_PARTNER_CARD_ENTRY_VALUE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.UPDATE_PARTNER_CARD_ENTRY_VALUE_SUCCESS:
            state.partnerCardEntryValueData.data.rows = state.partnerCardEntryValueData?.data?.rows.map(card =>
                card.id === action.payload.id ? action.payload : card)
            return {
                ...state,
                loading: false,
                partnerCardEntryValueData: state.partnerCardEntryValueData
            };
        case actionTypes.UPDATE_PARTNER_CARD_ENTRY_VALUE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case actionTypes.DELETE_PARTNER_CARD_ENTRY_VALUE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case actionTypes.DELETE_PARTNER_CARD_ENTRY_VALUE_SUCCESS:
            state.partnerCardEntryValueData.data.rows = state.partnerCardEntryValueData?.data?.rows.filter(card => card.id !== action.payload)
            return {
                ...state,
                loading: false,
                partnerCardEntryValueData: state.partnerCardEntryValueData,
            };
        case actionTypes.DELETE_PARTNER_CARD_ENTRY_VALUE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        default:
            return state
    }
};
export default partnerCardEntryValueReducer;