import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { createPartnerCardFormFieldOptionsData, deletePartnerCardFormFieldOptionsData, fetchPartnerCardFormFieldOptionsData, updatePartnerCardFormFieldOptionsData } from '../../redux/actions/partnerCardFormFieldOptionActions';
import Loading from '../../components/Loading';

function PartnerCardFromFieldOption() {
  const dispatch = useDispatch();

  const partnerFieldOptions = useSelector((state) => state?.partnerCardFormFieldOptions?.partnerCardFormFieldOptionsData?.data);
  const partnerCardFormField = useSelector((state) => state?.partnerCardFormFields?.partnerCardFormFieldData?.data);
  console.log("field Options", partnerFieldOptions);
  const [loading, setLoading] = useState(true);


  // Fetch card sample data on component mount
  useEffect(() => {
    const fetchPartnerCardFromFieldOptions = async () => {
      setLoading(true);
      await dispatch(fetchPartnerCardFormFieldOptionsData());

      setLoading(false);
    };

    fetchPartnerCardFromFieldOptions();
  }, [dispatch]);

  const columns = [
    { id: 'optionId', name: 'Id' },
    { id: 'optionName', name: 'Option Name' },
    { id: 'optionValue', name: 'Option Value' },
    { id: 'optionOrder', name: 'Option Order' },
    { id: 'optionStatus', name: 'Option Status' },
    { id: 'actions', name: 'Actions' }
  ];

  // Add functionality
  const [open, setOpen] = useState(false);
  const [fieldId, setFieldId] = useState('');
  const [optionName, setOptionName] = useState('');
  const [optionValue, setOptionValue] = useState('');
  const [optionOrder, setOptionOrder] = useState('');

  const functionAdd = () => {
    openPopup();
  };

  const closePopup = () => {
    setOpen(false);
  };

  const openPopup = () => {
    setOpen(true);
  };

  const handlePartnerCardFromFieldOptionsSubmit = async (e) => {
    e.preventDefault();
    const newOption = {
      field_id: fieldId, option_name: optionName, option_value: optionValue,
      option_order: optionOrder
    }
    await createPartnerCardFormFieldOptionsData(newOption);
    await dispatch(fetchPartnerCardFormFieldOptionsData());
    
    setFieldId('');
    setOptionName('');
    setOptionOrder('');
    setOptionValue('');
    closePopup();
  }

  // Edit functionality
  const [editOpen, setEditOpen] = useState(false);
  const [editOptionId, setEditOptionId] = useState();
  const [editFieldId, setEditFieldId] = useState('');
  const [editOptionName, setEditOptionName] = useState('');
  const [editOptionValue, setEditOptionValue] = useState('');
  const [editOptionOrder, setEditOptionOrder] = useState('');

  const functionEdit = useCallback((card) => {
    console.log("inside Edit", card);
    setEditOptionId(card.id);
    setEditFieldId(card.field_id);
    setEditOptionName(card.option_name);
    setEditOptionOrder(card.option_order);
    setEditOptionValue(card.option_value);

    setEditOpen(true);
  }, []);

  const closeEditPopup = () => {
    setEditOpen(false);
  };

  const handlePartnerCardFromFieldOptionsEditSubmit = async (e) => {
    e.preventDefault();
    const updatedOptions = {
      id: editOptionId, field_id: editFieldId, option_name: editOptionName,
      option_order: editOptionOrder, option_value: editOptionValue,
    }
    await updatePartnerCardFormFieldOptionsData(updatedOptions);
    await dispatch(fetchPartnerCardFormFieldOptionsData());
    closeEditPopup();
  };



  // Delete functionality
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteOptionId, setDeleteOptionId] = useState('');

  const functionDelete = useCallback((id) => {
    setDeleteOptionId(id);
    setDeleteOpen(true);
  }, []);
  const closeDeletePopup = () => {
    setDeleteOpen(false);
  };
  const handlePartnerCardFromFieldOptionsDelete = async () => {
    if (deleteOptionId) {
      await deletePartnerCardFormFieldOptionsData(deleteOptionId);
      await dispatch(fetchPartnerCardFormFieldOptionsData());
      closeDeletePopup();
    }
  };

  // validation 
  const validateCreateFields = () => {
    return optionOrder !== '' && !isNaN(optionOrder)
  }

  return (
    <div>
      <Box position="static" sx={{ borderRadius: 3, color: 'black' }}>
        <Toolbar sx={{ borderRadius: 2, flexWrap: 'wrap', display: 'flex', alignItems: 'center' }}>
          <Box sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: { xs: 'flex-start', md: 'space-between' },
            width: '100%'
          }}>
            <Typography sx={{
              fontSize: { xs: '20px', sm: '24px', md: '28px' },
              mb: { xs: 2, md: 0 },
              textAlign: {}
            }}>
              Card From Field Options
            </Typography>

            <Box sx={{
              display: 'flex',
              gap: { xs: 1, md: 0.5 },
              flexDirection: { xs: 'column', md: 'row' },
              justifyContent: { xs: 'center', md: 'flex-end' },
              width: { xs: '100%', md: 'auto' },
              alignItems: 'center'
            }}>
              <Button variant='contained' sx={{
                backgroundColor: 'midnightblue',
                textTransform: 'none',
                fontSize: { xs: '12px', sm: '14px', md: '16px' },
                width: { xs: '100%', sm: 'auto' }
              }} onClick={functionAdd}>Add Field Option </Button>
            </Box>
          </Box>
        </Toolbar>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow >
              {columns.map((column) => (
                <TableCell key={column.id} >{column.name}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  <Loading/>
                </TableCell>
              </TableRow>
            ) : (
              partnerFieldOptions && partnerFieldOptions?.rows.map((card) => (
                <TableRow key={card.id}>
                  <TableCell>{card.id}</TableCell>
                  <TableCell>{card.option_name}</TableCell>
                  <TableCell>{card.option_value}</TableCell>
                  <TableCell>{card.option_order}</TableCell>
                  <TableCell>{card.option_status}</TableCell>

                  <TableCell>
                    <Button variant='contained' onClick={() => functionEdit(card)} style={{ backgroundColor: 'green', color: 'white', textTransform: 'none' }}>Edit</Button>
                    <Button variant='outlined' onClick={() => functionDelete(card.id)} style={{ color: 'red', textTransform: 'none' }}>Delete</Button>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Add Dialog */}
      <Dialog open={open} fullWidth maxWidth="sm">
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <DialogTitle sx={{ flexGrow: 1, textAlign: 'center', marginTop: 3 }}>
            <Typography variant='h5' sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Create Option</Typography>
          </DialogTitle>
          <IconButton onClick={closePopup}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent>
          <form onSubmit={handlePartnerCardFromFieldOptionsSubmit}>
            <Stack spacing={2} margin={2}>
              <FormControl>
                <InputLabel id="demo-simple-select-label">Card Id</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Group Id"
                  onChange={e => setFieldId(e.target.value)}
                >
                  {partnerCardFormField && partnerCardFormField?.rows && partnerCardFormField?.rows.length > 0 ? (
                    partnerCardFormField?.rows.map((card) => (
                      <MenuItem key={card.id} value={card.id}>{card.field_name}</MenuItem>
                    ))
                  ) : (
                    <Typography variant="body1" color="initial"></Typography>
                  )}
                </Select>
              </FormControl>
              <TextField variant='outlined' value={optionName} onChange={e => setOptionName(e.target.value)} label="Option Name" />
              <TextField type='number' required error={isNaN(optionOrder)} variant='outlined' value={optionOrder} onChange={e => setOptionOrder(e.target.value)} label="Option Order" />
              <TextField variant='outlined' value={optionValue} onChange={e => setOptionValue(e.target.value)} label="Option value" />


              <Stack sx={{ flexDirection: 'row', justifyContent: 'center', gap: 2 }}>
                <Button variant='outlined' sx={{ textTransform: 'none' }} onClick={closePopup}>Cancel</Button>
                <Button variant='contained' sx={{ textTransform: 'none' }} disabled={!validateCreateFields()} type='submit'>Submit</Button>
              </Stack>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>

      {/* Edit Dialog */}
      <Dialog open={editOpen} fullWidth maxWidth="sm">
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <DialogTitle sx={{ flexGrow: 1, textAlign: 'center', marginTop: 3 }}>
            <Typography variant='h5' sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Edit Option</Typography>
          </DialogTitle>
          <IconButton onClick={closeEditPopup}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent>
          <form onSubmit={handlePartnerCardFromFieldOptionsEditSubmit}>
            <Stack spacing={2} margin={2}>
              <FormControl>
                <InputLabel id="demo-simple-select-label">Field Id</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Group Id"
                  value={editFieldId}
                  onChange={e => setEditFieldId(e.target.value)}
                >
                  {partnerCardFormField && partnerCardFormField?.rows && partnerCardFormField?.rows.length > 0 ? (
                    partnerCardFormField?.rows.map((card) => (
                      <MenuItem key={card.id} value={card.id}>{card.field_name}</MenuItem>
                    ))
                  ) : (
                    <Typography variant="body1" color="initial"></Typography>
                  )}
                </Select>
              </FormControl>
              <TextField variant='outlined' value={editOptionName} onChange={e => setEditOptionName(e.target.value)} label="Option Name" />
              <TextField variant='outlined' value={editOptionValue} onChange={e => setEditOptionValue(e.target.value)} label="Option Value" />
              <TextField variant='outlined' type='number' value={editOptionOrder} onChange={e => setEditOptionOrder(e.target.value)} label="Option Order" />

              <Stack sx={{ flexDirection: 'row', justifyContent: 'center', gap: 2 }}>
                <Button variant='outlined' sx={{ textTransform: 'none' }} onClick={closeEditPopup}>Cancel</Button>
                <Button variant='contained' sx={{ textTransform: 'none' }} type='submit'>Update</Button>
              </Stack>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>

      {/* Delete Dialog */}
      <Dialog open={deleteOpen} fullWidth maxWidth="sm">
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <DialogTitle sx={{ flexGrow: 1, textAlign: 'center', marginTop: 3 }}>
            <Typography variant='h5' sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Delete Card</Typography>
          </DialogTitle>
          <IconButton onClick={closeDeletePopup}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent sx={{ marginTop: 4 }}>
          <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
            Are you sure you want to delete this card group?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button variant="outlined" onClick={closeDeletePopup}>Cancel</Button>
          <Button variant="contained" color="error" onClick={handlePartnerCardFromFieldOptionsDelete}>Delete</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default PartnerCardFromFieldOption