import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";



export const fetchAllPartnerCardsRequest = () => ({
    type: actionTypes.FETCH_ALL_PARTNER_CARDS_DATA_REQUEST,
});
export const fetchAllPartnerCardsSuccess = (data) => ({
    type: actionTypes.FETCH_ALL_PARTNER_CARDS_DATA_SUCCESS,
    payload: data,
});
export const fetchAllPartnerCardsFailure = (error) => ({
    type: actionTypes.FETCH_ALL_PARTNER_CARDS_DATA_FAILURE,
    payload: error,
});

export const fetchAllPartnerCardsData = (page, limit, search= '') => {

    return async (dispatch) => {
        
        dispatch(fetchAllPartnerCardsRequest());
        try {
            const {data} = await api.allPartnerCards(page, limit,search);
            
            dispatch(fetchAllPartnerCardsSuccess(data));
            return data;
        } catch (error) {
            dispatch(fetchAllPartnerCardsFailure(error));
        }
    };
};