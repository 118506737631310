import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, IconButton, InputLabel, Menu, MenuItem, Paper, Select, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { fetchCardsData } from '../../redux/actions/cardsActions';
import { createCardFromFieldeData, deleteCardFromFieldData, fetchCardFromFieldData, updateCardFromFieldData } from '../../redux/actions/cardFromFieldActions';
import { useNavigate, useParams } from 'react-router-dom';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SaveIcon from "@mui/icons-material/Save";
import Loading from '../../components/Loading';
import { toast } from 'react-toastify';


function CardFromField() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const cardFromField = useSelector((state) => state?.cardFromField?.cardFromFieldData?.data);
    const [loading, setLoading] = useState(true);
    const [editedRows, setEditedRows] = useState({});


    const [anchorEl, setAnchorEl] = useState(null);
    const menuOpen = Boolean(anchorEl);

    const handleMenuClick = (event, id) => {
        setAnchorEl(event.currentTarget);
        setEditFieldId(id);
        setDeleteFieldId(id)
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
        setEditFieldId(null);
        setDeleteFieldId(null);
    };


    // Fetch card sample data on component mount
    useEffect(() => {
        const fetchCardFromField = async () => {
            setLoading(true);
            await dispatch(fetchCardsData());
            let data = await dispatch(fetchCardFromFieldData(params.card_id));
            console.log("data inside field", data)
            setLoading(false);
        };

        fetchCardFromField();
    }, [dispatch]);

    const columns = [
        { id: 'fieldId', name: 'Id' },
        { id: 'fieldName', name: 'Field Name' },
        { id: 'fieldType', name: 'Field Type' },
        { id: 'fieldOrder', name: 'Field Order' },
        { id: 'fieldStatus', name: 'Field Placeholder' },
        { id: 'fieldRequired', name: 'Field Required' },
        { id: 'actions', name: 'Actions' }
    ];

    // Add functionality
    const [open, setOpen] = useState(false);
    const [fieldName, setFieldName] = useState('');
    const [fieldType, setFieldtype] = useState('string');
    const [fieldOrder, setFieldOrder] = useState(50);
    const [fieldStatus, setFieldStatus] = useState('active');
    const [fieldDefault, setFieldDefault] = useState('');
    const [fieldRequired, setFieldRequired] = useState(true);
    const [fieldPlaceHolder, setFieldPlaceHolder] = useState('');


    const functionAdd = () => {
        openPopup();
    };

    const closePopup = () => {
        setOpen(false);
    };

    const openPopup = () => {
        setOpen(true);
    };

    const handleCardFromFieldSubmit = async (e) => {
        e.preventDefault();
        const newField = {
            card_id: params.card_id, field_name: fieldName, field_placeholder: fieldPlaceHolder,
            field_type: fieldType, field_order: fieldOrder, field_status: fieldStatus,
            field_default: fieldDefault, field_required: fieldRequired === true ? 'true' : 'false'
        }
        const response = await dispatch(createCardFromFieldeData(newField));
        console.log("response in form field", response);

        console.log("Inside Create Crad sample", response);
        await dispatch(fetchCardFromFieldData(params.card_id));
        setFieldName('');
        setFieldOrder('');
        setFieldtype('');
        setFieldStatus('');
        setFieldDefault('');
        setFieldRequired(false);
        setFieldPlaceHolder('');
        closePopup();
    }

    // Edit functionality
    const [editOpen, setEditOpen] = useState(false);
    const [editFieldId, setEditFieldId] = useState('');
    const [editfieldName, setEditFieldName] = useState('');
    const [editfieldType, setEditFieldType] = useState('');
    const [editfieldOrder, setEditFieldOrder] = useState('');
    const [editfieldStatus, setEditFieldStatus] = useState('');
    const [editfieldDefault, setEditFieldDefault] = useState('');
    const [editfieldRequired, setEditFieldRequired] = useState('');
    const [editfieldPlaceHolder, setEditFieldPlaceHolder] = useState('');

    const functionFieldEdit = useCallback((card) => {
        console.log("inside Edit", card);
        console.log("Field Id :-", card.id);
        setEditFieldId(card.id);
        setEditFieldName(card.field_name);
        setEditFieldType(card.field_type);
        setEditFieldOrder(card.field_order);
        setEditFieldStatus(card.field_status);
        setEditFieldDefault(card.field_default);
        setEditFieldRequired(card.field_required);
        setEditFieldPlaceHolder(card.field_placeholder);
        setEditOpen(true);
    }, []);

    const closeEditPopup = () => {
        setEditOpen(false);
    };

    const handleCardFromFieldEditSubmit = async (e) => {
        e.preventDefault();
        const updatedCards = {
            id: editFieldId, card_id: params.card_id, field_name: editfieldName,
            field_type: editfieldType, field_order: editfieldOrder, field_status: editfieldStatus,
            field_default: editfieldDefault, field_required: editfieldRequired,
            field_placeholder: editfieldPlaceHolder
        }
        await dispatch(updateCardFromFieldData(updatedCards))
        await dispatch(fetchCardFromFieldData(params.card_id));
        closeEditPopup();
    };



    // Delete functionality
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [deleteFieldId, setDeleteFieldId] = useState('');

    const functionDelete = useCallback((id) => {
        setDeleteFieldId(id);
        setDeleteOpen(true);
    }, []);
    const closeDeletePopup = () => {
        setDeleteOpen(false);
    };
    const handleCardFromFieldDelete = async () => {
        if (deleteFieldId) {
            await dispatch(deleteCardFromFieldData(deleteFieldId));
            await dispatch(fetchCardFromFieldData(params.card_id));
            closeDeletePopup();
        }
    };

    // validation 
    const validateCreateFields = () => {
        return fieldOrder !== '' && !isNaN(fieldOrder)
    };

    // Handle row editing
    const handleRowEdit = (rowId, field, value) => {
        setEditedRows((prevState) => ({
            ...prevState,
            [rowId]: {
                ...prevState[rowId],
                [field]: value,
            },
        }));
    };


    // Handle save changes for edited rows
    const handleSaveChanges = async () => {
        const updatedRows = Object.entries(editedRows).map(([id, updates]) => ({
            id,
            card_id: params.card_id,
            field_name: updates.fieldName,
            field_type: updates.fieldType,
            field_order: updates.fieldOrder,
            field_placeholder: updates.fieldPlaceholder,
            field_required: updates.fieldRequired,
        }));

        for (const row of updatedRows) {
            const response = await dispatch(updateCardFromFieldData(row));
            if (response?.payload?.status === 'success') {


                toast.success('Card updated successfully!', {
                    autoClose: 3000, // Auto-close after 3 seconds
                });
            } else {
                toast.error('Failed to update card. Please try again.', {
                    autoClose: 3000,
                });
            }
        }

        await dispatch(fetchCardFromFieldData(params.card_id));
        setEditedRows({});
        setCheckedRows([]);
    };

    // Assuming cardFromField.rows contains the data
    useEffect(() => {
        if (cardFromField?.rows) {
            const initialEditedRows = {};
            cardFromField.rows.forEach((row) => {
                initialEditedRows[row.id] = {
                    fieldName: row.field_name,
                    fieldType: row.field_type,
                    fieldOrder: row.field_order,
                    fieldPlaceholder: row.field_placeholder,
                    fieldRequired: row.field_required,
                };
            });
            setEditedRows(initialEditedRows);
        }
    }, [cardFromField]);

    const [checkedRows, setCheckedRows] = useState([]);

    const handleCheckboxChange = (cardId) => {
        setCheckedRows((prev) =>
            prev.includes(cardId) ? prev.filter((id) => id !== cardId) : [...prev, cardId]
        );
    };
    // bulk update
    const [bulkFieldType, setBulkFieldType] = useState(""); // Track bulk field type value
    const [isBulkEditDialogOpen, setIsBulkEditDialogOpen] = useState(false); // Track dialog open state


    const handleBulkUpdate = () => {
        setEditedRows((prevEditedRows) => {
            const updatedRows = { ...prevEditedRows };
            checkedRows.forEach((rowId) => {
                if (!updatedRows[rowId]) updatedRows[rowId] = {};
                updatedRows[rowId].fieldType = bulkFieldType;
            });
            return updatedRows;
        });
        setIsBulkEditDialogOpen(false);
    };


    // bulk delete

    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

    const handleBulkDelete = async () => {
        for (const rowId of checkedRows) {
            const data = await dispatch(deleteCardFromFieldData(rowId));
            if (data?.status === 200) {
                toast.success('Card From Field Deleted successfully!', {
                    autoClose: 3000,
                });
            } else {
                toast.error('Failed to Deleted Card From Field. Please try again.', {
                    autoClose: 3000,
                });
            }
        }

        await dispatch(fetchCardFromFieldData(params.card_id));
        setCheckedRows([]); // Clear selected rows after deletion
    };



    return (
        <>
            <Box position="static" sx={{ borderRadius: 3, color: 'black' }}>
                <Toolbar sx={{ borderRadius: 2, flexWrap: 'wrap', display: 'flex', alignItems: 'center' }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        justifyContent: { xs: 'flex-start', md: 'space-between' },
                        width: '100%'
                    }}>
                        <Typography sx={{
                            fontSize: { xs: '20px', sm: '24px', md: '28px' },
                            mb: { xs: 2, md: 0 },
                            textAlign: {}
                        }}>
                            Card Form Field
                        </Typography>

                        <Box sx={{
                            display: 'flex',
                            gap: { xs: 1, md: 0.5 },
                            flexDirection: { xs: 'column', md: 'row' },
                            justifyContent: { xs: 'center', md: 'flex-end' },
                            width: { xs: '100%', md: 'auto' },
                            alignItems: 'center'
                        }}>
                            <Button
                                variant="contained"
                                onClick={() => setIsBulkEditDialogOpen(true)}
                                disabled={checkedRows.length === 0}
                            >
                                Bulk Edit Operation
                            </Button>

                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => setIsDeleteDialogOpen(true)}
                                disabled={checkedRows.length === 0}
                            >
                                Bulk Delete
                            </Button>

                            <Button variant='contained' sx={{
                                backgroundColor: 'midnightblue',
                                textTransform: 'none',
                                fontSize: { xs: '12px', sm: '14px', md: '16px' },
                                width: { xs: '100%', sm: 'auto' }
                            }} onClick={functionAdd}>Add Field </Button>
                        </Box>
                    </Box>
                </Toolbar>
            </Box>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow >
                            {columns.map((column) => (
                                <TableCell key={column.id} >{column.name}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableRow>
                                <TableCell colSpan={columns.length} align="center">
                                    <Loading />
                                </TableCell>
                            </TableRow>
                        ) : (
                            cardFromField && cardFromField?.rows?.length === 0 ? (
                                <Typography>No Field Added Till Now</Typography>
                            ) : (
                                cardFromField && cardFromField?.rows?.map((card) => (
                                    <TableRow key={card.id}>
                                        <TableCell>{card.id}</TableCell>
                                        <TableCell>
                                            {checkedRows.includes(card.id) ? (
                                                <TextField
                                                    autoFocus
                                                    value={editedRows[card.id]?.fieldName || ""}
                                                    onChange={(e) => handleRowEdit(card.id, 'fieldName', e.target.value)}
                                                />
                                            ) : (
                                                card.field_name
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {checkedRows.includes(card.id) ? (
                                                <TextField
                                                    value={editedRows[card.id]?.fieldType || ""}
                                                    onChange={(e) => handleRowEdit(card.id, 'fieldType', e.target.value)}
                                                />
                                            ) : (
                                                card.field_type
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {checkedRows.includes(card.id) ? (
                                                <TextField
                                                    value={editedRows[card.id]?.fieldOrder || ""}
                                                    onChange={(e) => handleRowEdit(card.id, 'fieldOrder', e.target.value)}
                                                />
                                            ) : (
                                                card.field_order
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {checkedRows.includes(card.id) ? (
                                                <TextField
                                                    value={editedRows[card.id]?.fieldPlaceholder || ""}
                                                    onChange={(e) => handleRowEdit(card.id, 'fieldPlaceholder', e.target.value)}
                                                />
                                            ) : (
                                                card.field_placeholder
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {checkedRows.includes(card.id) ? (
                                                <TextField
                                                    value={editedRows[card.id]?.fieldRequired || ""}
                                                    onChange={(e) => handleRowEdit(card.id, 'fieldRequired', e.target.value)}
                                                />
                                            ) : (
                                                card.field_required === true ? "true" : card.field_required === false ? "false" : card.field_required
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                aria-controls="simple-menu"
                                                aria-haspopup="true"
                                                onClick={(event) => handleMenuClick(event, card.id)}
                                            >
                                                <MoreVertIcon />
                                            </IconButton>
                                            <Menu
                                                id="simple-menu"
                                                anchorEl={anchorEl}
                                                keepMounted
                                                open={menuOpen && editFieldId === card.id}
                                                onClose={handleMenuClose}
                                            >
                                                <MenuItem onClick={() => functionFieldEdit(card)}>Edit </MenuItem>
                                                <MenuItem onClick={() => functionDelete(card.id)}>Delete</MenuItem>
                                                <MenuItem onClick={() => navigate(`field-options/${card.id}`)}>Card form Field Option</MenuItem>
                                            </Menu>
                                        </TableCell>
                                        <TableCell>
                                            <Checkbox
                                                checked={checkedRows.includes(card.id)}
                                                onChange={() => handleCheckboxChange(card.id)}
                                            />

                                        </TableCell>
                                    </TableRow>
                                ))
                            )
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Save Changes Button */}
            {checkedRows.length > 0 && (
                <Box textAlign="center" mt={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<SaveIcon />}
                        onClick={handleSaveChanges}
                        disabled={Object.keys(editedRows).length === 0}
                    >
                        Save Changes
                    </Button>
                </Box>
            )}

            {/* Handle bulk update */}
            <Dialog open={isBulkEditDialogOpen} onClose={() => setIsBulkEditDialogOpen(false)}>
                <DialogTitle>Bulk Edit Field Type</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth>
                        <InputLabel>Field Type</InputLabel>
                        <Select
                            value={bulkFieldType}
                            onChange={(e) => setBulkFieldType(e.target.value)}
                        >
                            <MenuItem value="string">String</MenuItem>
                            <MenuItem value="array">Array</MenuItem>
                            {/* Add other field type options as needed */}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsBulkEditDialogOpen(false)}>Cancel</Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleBulkUpdate()}
                    >
                        Update
                    </Button>
                </DialogActions>
            </Dialog>

            {/* handle bulk delete */}
            <Dialog open={isDeleteDialogOpen} onClose={() => setIsDeleteDialogOpen(false)}>
                <DialogTitle>Confirm Bulk Delete</DialogTitle>
                <DialogContent>
                    Are you sure you want to delete these {checkedRows.length} selected rows?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsDeleteDialogOpen(false)}>Cancel</Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            handleBulkDelete();
                            setIsDeleteDialogOpen(false);
                        }}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>


            {/* Add Dialog */}
            <Dialog open={open} onClose={closePopup} fullWidth maxWidth="sm">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <DialogTitle sx={{ flexGrow: 1, textAlign: 'center', marginTop: 3 }}>
                        <Typography variant='h5' sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Create Card</Typography>
                    </DialogTitle>
                    <IconButton onClick={closePopup}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DialogContent>
                    <form onSubmit={handleCardFromFieldSubmit}>
                        <Stack spacing={2} margin={2}>
                            <TextField variant='outlined' value={fieldName} onChange={e => setFieldName(e.target.value)} label="Field Name" />
                            <TextField required variant='outlined' value={fieldOrder} onChange={e => setFieldOrder(e.target.value)} label="Field Order" />
                            <TextField variant='outlined' value={fieldType} onChange={e => setFieldtype(e.target.value)} label="Field Type" />
                            <TextField variant='outlined' value={fieldStatus} onChange={e => setFieldStatus(e.target.value)} label="Field Status" />
                            <TextField variant='outlined' value={fieldDefault} onChange={e => setFieldDefault(e.target.value)} label="Field Default" />
                            <TextField variant="outlined" value={fieldRequired ? 'true' : 'false'} label="Field Required"
                                InputProps={{
                                    readOnly: true, // Making it read-only since it's controlled by the switch
                                }}
                            />
                            <FormControlLabel
                                control={<Switch checked={fieldRequired} onChange={e => setFieldRequired(e.target.checked)} />}
                                label="Is Field Required"
                            />
                            <TextField variant='outlined' value={fieldPlaceHolder} onChange={e => setFieldPlaceHolder(e.target.value)} label="Field PlaceHolder" />

                            <Stack sx={{ flexDirection: 'row', justifyContent: 'center', gap: 2 }}>
                                <Button variant='outlined' sx={{ textTransform: 'none' }} onClick={closePopup}>Cancel</Button>
                                <Button variant='contained' sx={{ textTransform: 'none' }} disabled={!validateCreateFields()} type='submit'>Submit</Button>
                            </Stack>
                        </Stack>
                    </form>
                </DialogContent>
            </Dialog>

            {/* Edit Dialog */}
            <Dialog open={editOpen} fullWidth maxWidth="sm">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <DialogTitle sx={{ flexGrow: 1, textAlign: 'center', marginTop: 3 }}>
                        <Typography variant='h5' sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Edit Card Form Field</Typography>
                    </DialogTitle>
                    <IconButton onClick={closeEditPopup}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DialogContent>
                    <form onSubmit={handleCardFromFieldEditSubmit}>
                        <Stack spacing={2} margin={2}>

                            <TextField required variant='outlined' value={editfieldName} onChange={e => setFieldName(e.target.value)} label="Field Name" />
                            <TextField variant='outlined' value={editfieldType} onChange={e => setEditFieldType(e.target.value)} label="Field Type" />
                            <TextField variant='outlined' value={editfieldPlaceHolder} onChange={e => setEditFieldPlaceHolder(e.target.value)} label="Field Placeholder" />
                            <TextField variant='outlined' type='number' value={editfieldOrder} onChange={e => setEditFieldOrder(e.target.value)} label="Field Order" />
                            <TextField variant='outlined' value={editfieldStatus} onChange={e => setEditFieldStatus(e.target.value)} label="Field Status" />
                            <TextField variant='outlined' value={editfieldDefault} onChange={e => setEditFieldDefault(e.target.value)} label="Field Default Value" />
                            {/* <TextField variant="outlined" value={editfieldRequired ? 'true' : 'false'} label="Field Required"
                                InputProps={{
                                    readOnly: true, // Making it read-only since it's controlled by the switch
                                }}
                            /> */}
                            <FormControlLabel
                                control={<Switch checked={editfieldRequired} onChange={e => setEditFieldRequired(e.target.checked)} />}
                                label="Required"
                            />


                            <Stack sx={{ flexDirection: 'row', justifyContent: 'center', gap: 2 }}>
                                <Button variant='outlined' sx={{ textTransform: 'none' }} onClick={closeEditPopup}>Cancel</Button>
                                <Button variant='contained' sx={{ textTransform: 'none' }} type='submit'>Update</Button>
                            </Stack>
                        </Stack>
                    </form>
                </DialogContent>
            </Dialog>

            {/* Delete Dialog */}
            <Dialog open={deleteOpen} fullWidth maxWidth="sm">
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <DialogTitle sx={{ flexGrow: 1, textAlign: 'center', marginTop: 3 }}>
                        <Typography variant='h5' sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Delete Card</Typography>
                    </DialogTitle>
                    <IconButton onClick={closeDeletePopup}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DialogContent sx={{ marginTop: 4 }}>
                    <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
                        Are you sure you want to delete this card group?
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center' }}>
                    <Button variant="outlined" onClick={closeDeletePopup}>Cancel</Button>
                    <Button variant="contained" color="error" onClick={handleCardFromFieldDelete}>Delete</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default CardFromField;
