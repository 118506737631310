import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";


export const fetchPartnerCardSampleRequest = () => ({
    type: actionTypes.FETCH_PARTNER_CARD_SAMPLE_DATA_REQUEST,
});
export const fetchPartnerCardSampleSuccess = (data) => ({
    type: actionTypes.FETCH_PARTNER_CARD_SAMPLE_DATA_SUCCESS,
    payload: data,
});
export const fetchPartnerCardSampleFailure = (error) => ({
    type: actionTypes.FETCH_PARTNER_CARD_SAMPLE_DATA_FAILURE,
    payload: error,
});

export const fetchPartnerCardSampleData = (PartnerCardsData) => {

    return async (dispatch) => {
        console.log("step 1 inside PartnerCards Action")
        dispatch(fetchPartnerCardSampleRequest());
        try {
            const {data} = await api.partnerCardSample(PartnerCardsData);
            console.log("inside PartnerCards action", data);
            dispatch(fetchPartnerCardSampleSuccess(data));
            return data;
        } catch (error) {
            dispatch(fetchPartnerCardSampleFailure(error));
        }
    };
};

export const getSinglePartnerCardSampleRequest = () => ({
    type: actionTypes.GET_SINGLE_PARTNER_CARD_SAMPLE_DATA_REQUEST,
});
export const getSinglePartnerCardSampleSuccess = (PartnerCard) => ({
    type: actionTypes.GET_SINGLE_PARTNER_CARD_SAMPLE_DATA_SUCCESS,
    payload: PartnerCard,
});
export const getSinglePartnerCardSampleFailure = (error) => ({
    type: actionTypes.GET_SINGLE_PARTNER_CARD_SAMPLE_DATA_FAILURE,
    payload: error,
});
export const getSinglePartnerCardSampleData = (PartnerCardData) => {

    console.log("id in partner card sample", PartnerCardData);

    return async (dispatch) => {
        dispatch(getSinglePartnerCardSampleRequest());
        try {

            const response = await api.getSinglePartnerCardSample(PartnerCardData.card_id);
            const Item = response.data;
            const successAction = getSinglePartnerCardSampleSuccess(Item);
            dispatch(successAction);
            return successAction;

        } catch (error) {
            dispatch(getSinglePartnerCardSampleFailure(error));
        }
    };
};

export const createPartnerCardSampleRequest = () => ({
    type: actionTypes.CREATE_PARTNER_CARD_SAMPLE_REQUEST,
});
export const createPartnerCardSampleSuccess = (data) => ({
    type: actionTypes.CREATE_PARTNER_CARD_SAMPLE_SUCCESS,
    payload: data
});
export const createPartnerCardSampleFailure = (error) => ({
    type: actionTypes.CREATE_PARTNER_CARD_SAMPLE_FAILURE,
    payload: error,
});

export const createPartnerCardSampleData = (newPartnerCardData) => {
    return async (dispatch) => {
        dispatch(createPartnerCardSampleRequest());
        try{
            const {data} = await api.createPartnerCardSample(newPartnerCardData);
            dispatch(createPartnerCardSampleSuccess(data));
            return data;
        }catch(error) {
            dispatch(createPartnerCardSampleFailure(error));
        }
    }
};


export const updatePartnerCardSampleRequest = () => ({
    type: actionTypes.UPDATE_PARTNER_CARD_SAMPLE_REQUEST,
});
export const updatePartnerCardSampleSuccess = (data) => ({
    type: actionTypes.UPDATE_PARTNER_CARD_SAMPLE_SUCCESS,
    payload: data,
});
export const updatePartnerCardSampleFailure = (error) => ({
    type: actionTypes.UPDATE_PARTNER_CARD_SAMPLE_FAILURE,
    payload: error,
});

export const updatePartnerCardSampleData = (updateData) => {
    return async (dispatch) => {
        dispatch(updatePartnerCardSampleRequest());
        try{
            const response = await api.updatePartnerCardSample(updateData.id, updateData);
            const updatedCard = response?.data;
            const successAction = updatePartnerCardSampleSuccess(updatedCard);
            dispatch(successAction);
            return successAction;

        } catch(error) {
            const failureAction = updatePartnerCardSampleFailure(error.message);
            dispatch(failureAction);
            return failureAction;
        }
    };
};

export const deletePartnerCardSampleData = async (id) => {
    await api.deletePartnerCardSample(id);
}