import * as actionTypes from '../actionTypes.js';

const initialState = {
    partnerCardFormFieldData: null,
    loading: false,
    error: null,
};

const partnerCardFormFieldReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_PARTNER_CARD_FORM_FIELD_DATA_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.FETCH_PARTNER_CARD_FORM_FIELD_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                partnerCardFormFieldData: action.payload,
            };
        case actionTypes.FETCH_PARTNER_CARD_FORM_FIELD_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case actionTypes.CREATE_CARD_FROM_FIELD_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.CREATE_CARD_FROM_FIELD_SUCCESS:
            state.partnerCardFormFieldData.data.rows = [...state.partnerCardFormFieldData?.data?.rows, action.payload]
            return {
                ...state,
                loading: false,
                partnerCardFormFieldData: state.partnerCardFormFieldData,
            };
        case actionTypes.CREATE_CARD_FROM_FIELD_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case actionTypes.UPDATE_PARTNER_CARD_FORM_FIELD_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.UPDATE_PARTNER_CARD_FORM_FIELD_SUCCESS:
            state.partnerCardFormFieldData.data.rows = state.partnerCardFormFieldData?.data?.rows.map(card =>
                card.id === action.payload.id ? action.payload : card)
            return {
                ...state,
                loading: false,
                partnerCardFormFieldData: state.partnerCardFormFieldData
            }

        case actionTypes.UPDATE_PARTNER_CARD_FORM_FIELD_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case actionTypes.DELETE_CARD_FROM_FIELD_REQUEST:
            return{
                ...state,
                loading: true,
                error: null
            };
        case actionTypes.DELETE_CARD_FROM_FIELD_SUCCESS:
            state.partnerCardFormFieldData.data.rows = state.partnerCardFormFieldData?.data?.rows.filter(card => card.id !== action.payload );
            return{
                ...state,
                loading: false,
                partnerCardFormFieldData: state.partnerCardFormFieldData,
            }
        case actionTypes.DELETE_CARD_FROM_FIELD_FAILURE:
            return{
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
};
export default partnerCardFormFieldReducer;