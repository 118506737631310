import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";

export const fetchPartnerCardFormFieldRequest = () => ({
    type: actionTypes.FETCH_PARTNER_CARD_FORM_FIELD_DATA_REQUEST,
});
export const fetchPartnerCardFormFieldSuccess = (data) => ({
    type: actionTypes.FETCH_PARTNER_CARD_FORM_FIELD_DATA_SUCCESS,
    payload: data,
});
export const fetchPartnerCardFormFieldFailure = (error) => ({
    type: actionTypes.FETCH_PARTNER_CARD_FORM_FIELD_DATA_FAILURE,
    payload: error,
});

export const fetchPartnerCardFormFieldData = (card_id) => {

    return async (dispatch) => {
        console.log("step 1 inside cards Action")
        dispatch(fetchPartnerCardFormFieldRequest());
        try {
            const {data} = await api.partnerCardFormField(card_id);
            console.log("inside cards action", data);
            dispatch(fetchPartnerCardFormFieldSuccess(data));
            return data;
        } catch (error) {
            dispatch(fetchPartnerCardFormFieldFailure(error));
        }
    };
};

export const createPartnerCardFormField = async (newCardData) => {
    const {data} = await api.createPartnerCardFormField(newCardData);
    return data;
}

export const updatePartnerCardFormFieldRequest = () => ({
    type: actionTypes.UPDATE_PARTNER_CARD_FORM_FIELD_REQUEST,
});
export const updatePartnerCardFormFieldSuccess = (data) => ({
    type: actionTypes.UPDATE_PARTNER_CARD_FORM_FIELD_SUCCESS,
    payload: data,
});
export const updatePartnerCardFormFieldFailure = (error) => ({
    type: actionTypes.UPDATE_PARTNER_CARD_FORM_FIELD_FAILURE,
    payload: error,
});

export const updatePartnerCardFormFieldData = (updateData) => {
    return async (dispatch) => {
        dispatch(updatePartnerCardFormFieldRequest());
        try{
            const response = await api.updatePartnerCardFormField(updateData.id, updateData);
            const updatedCard = response?.data;
            const successAction = updatePartnerCardFormFieldSuccess(updatedCard);
            dispatch(successAction);
            return successAction;

        } catch(error) {
            const failureAction = updatePartnerCardFormFieldFailure(error.message);
            dispatch(failureAction);
            return failureAction;
        }
    };
};

export const deletePartnerCardFromFieldData = async (id) => {
    const data = await api.deletePartnerCardFromField(id);
    return data;
}



