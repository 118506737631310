import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";



export const fetchAllCardsRequest = () => ({
    type: actionTypes.FETCH_ALL_CARDS_DATA_REQUEST,
});
export const fetchAllCardsSuccess = (data) => ({
    type: actionTypes.FETCH_ALL_CARDS_DATA_SUCCESS,
    payload: data,
});
export const fetchAllCardsFailure = (error) => ({
    type: actionTypes.FETCH_ALL_CARDS_DATA_FAILURE,
    payload: error,
});

export const fetchAllCardsData = (page, limit, search= '') => {

    return async (dispatch) => {
        
        dispatch(fetchAllCardsRequest());
        try {
            const {data} = await api.allCards(page, limit,search);
            dispatch(fetchAllCardsSuccess(data));
            return data;
        } catch (error) {
            dispatch(fetchAllCardsFailure(error));
        }
    };
};