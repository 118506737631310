import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";

export const fetchPartnerCardEntryRequest = () => ({
    type: actionTypes.FETCH_PARTNER_CARD_ENTRY_DATA_REQUEST,
});
export const fetchPartnerCardEntrySuccess = (data) => ({
    type: actionTypes.FETCH_PARTNER_CARD_ENTRY_DATA_SUCCESS,
    payload: data,
});
export const fetchPartnerCardEntryFailure = (error) => ({
    type: actionTypes.FETCH_PARTNER_CARD_ENTRY_DATA_FAILURE,
    payload: error,
});

export const fetchPartnerCardEntryData = (page, limit,search= '') => {

    return async (dispatch) => {
        dispatch(fetchPartnerCardEntryRequest());
        try {
            const {data} = await api.partnerCardEntry(page, limit,search);
            console.log("data in card entry action", data);
            dispatch(fetchPartnerCardEntrySuccess(data));
            return data;
        } catch (error) {
            dispatch(fetchPartnerCardEntryFailure(error));
        }
    };
};

export const createPartnerCardEntryData = async (newCardData) => {
    const {data} = await api.createPartnerCardEntry(newCardData);
    return data;
};

export const updatePartnerCardEntryData = async (updatedData) => {
    const response = await api.updatePartnerCardEntry(updatedData.id, updatedData);
    return response?.data;
};

export const deletePartnerCardEntryData = async (id) => {
    const data = await api.deletePartnerCardEntry(id);
    return data;
}