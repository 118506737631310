import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";


export const fetchPartnerCardEntryValueRequest = () => ({
    type: actionTypes.FETCH_PAERTNER_CARD_ENTRY_VALUE_DATA_REQUEST,
});
export const fetchPartnerCardEntryValueSuccess = (data) => ({
    type: actionTypes.FETCH_PAERTNER_CARD_ENTRY_VALUE_DATA_SUCCESS,
    payload: data,
});
export const fetchPartnerCardEntryValueFailure = (error) => ({
    type: actionTypes.FETCH_PAERTNER_CARD_ENTRY_VALUE_DATA_FAILURE,
    payload: error,
});

export const fetchPartnerCardEntryValueData = (cardsData) => {

    return async (dispatch) => {
        dispatch(fetchPartnerCardEntryValueRequest());
        try {
            const {data} = await api.patnerCardEntryValues(cardsData);
            dispatch(fetchPartnerCardEntryValueSuccess(data));
            return data;
        } catch (error) {
            dispatch(fetchPartnerCardEntryValueFailure(error));
        }
    };
};

export const createPartnerCardEntryValueData = async (newCardData) =>{
    const {data} = await api.createPartnerCardEntryValues(newCardData);
    return data
};

export const updatePartnerCardEntryValueRequest = () => ({
    type: actionTypes.UPDATE_PARTNER_CARD_ENTRY_VALUE_REQUEST,
});
export const updatePartnerCardEntryValueSuccess = (data) => ({
    type: actionTypes.UPDATE_PARTNER_CARD_ENTRY_VALUE_SUCCESS,
    payload: data,
});
export const updatePartnerCardEntryValueFailure = (error) => ({
    type: actionTypes.UPDATE_PARTNER_CARD_ENTRY_VALUE_FAILURE,
    payload: error,
});

export const updatePartnerCardEntryValueData = (updateData) => {
    return async (dispatch) => {
        dispatch(updatePartnerCardEntryValueRequest());
        try{
            const response = await api.updatePartnerCardEntryValues(updateData.id, updateData);
            const updatedCard = response?.data;
            const successAction = updatePartnerCardEntryValueSuccess(updatedCard);
            dispatch(successAction);
            return successAction;

        } catch(error) {
            const failureAction = updatePartnerCardEntryValueFailure(error.message);
            dispatch(failureAction);
            return failureAction;
        }
    };
};

export const deletePartnerCardEntryValueRequest = () => ({
    type: actionTypes.DELETE_PARTNER_CARD_ENTRY_VALUE_REQUEST,
});
export const deletePartnerCardEntryValueSuccess = (id) => ({
    type: actionTypes.DELETE_PARTNER_CARD_ENTRY_VALUE_SUCCESS,
    payload: id,
});
export const deletePartnerCardEntryValueFailure = (error) => ({
    type: actionTypes.DELETE_PARTNER_CARD_ENTRY_VALUE_FAILURE,
    payload: error,
});

export const deletePartnerCardEntryValueData = (id) => {
    return async (dispatch) => {
        dispatch(deletePartnerCardEntryValueRequest());
        try{
            const data = await api.deletePartnerCardEntryValues(id);
            dispatch(deletePartnerCardEntryValueSuccess(id));
            return data;
        } catch(error) {
            dispatch(deletePartnerCardEntryValueFailure(error));
        }
    };
};