import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";

export const fetchPartnerCardFormFieldOptionsRequest = () => ({
    type: actionTypes.FETCH_PARTNER_CARD_FROM_FIELD_OPTION_REQUEST,
});
export const fetchPartnerCardFormFieldOptionsSuccess = (data) => ({
    type: actionTypes.FETCH_PARTNER_CARD_FROM_FIELD_OPTION_SUCCESS,
    payload: data,
});
export const fetchPartnerCardFormFieldOptionsFailure = (error) => ({
    type: actionTypes.FETCH_PARTNER_CARD_FROM_FIELD_OPTION_FAILURE,
    payload: error,
});

export const fetchPartnerCardFormFieldOptionsData = (cardsData) => {

    return async (dispatch) => {
        dispatch(fetchPartnerCardFormFieldOptionsRequest());
        try {
            const {data} = await api.partnerCardFormFieldOptions(cardsData);
            console.log("inside cards action", data);
            dispatch(fetchPartnerCardFormFieldOptionsSuccess(data));
            return data;
        } catch (error) {
            dispatch(fetchPartnerCardFormFieldOptionsFailure(error));
        }
    };
};



export const createPartnerCardFormFieldOptionsData = async (newCardData) => {
    const {data} = await api.createpartnerCardFormFieldOptions(newCardData);
    return data;
};



export const updatePartnerCardFormFieldOptionsData = async (updateData) => {
    const response = await api.updatepartnerCardFormFieldOptions(updateData.id, updateData);
    return response?.data;
};



export const deletePartnerCardFormFieldOptionsData = async (id) => {
    await api.deletepartnerCardFormFieldOptions(id);
};
