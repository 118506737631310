import * as actionTypes from '../actionTypes.js';


const initialState = {
    partnerCardGroupData: null,
    loading: false,
    error: null,
};

const partnerCardGroupReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_PARTNER_CARD_GROUP_DATA_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.FETCH_PARTNER_CARD_GROUP_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                partnerCardGroupData: action.payload,
            };
        case actionTypes.FETCH_PARTNER_CARD_GROUP_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case actionTypes.UPDATE_PARTNER_CARD_GROUP_DATA_REQUEST:
            return{
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.UPDATE_PARTNER_CARD_GROUP_DATA_SUCCESS:
            state.partnerCardGroupData.data.rows= state.partnerCardGroupData?.data?.rows.map(card => 
                card.id === action.payload.id ? action.payload : card)
            return{
                ...state,
                loading: false,
                partnerCardGroupData: state.partnerCardGroupData,
            };
        case actionTypes.UPDATE_PARTNER_CARD_GROUP_DATA_FAILURE:
            return{
                ...state,
                loading: false,
                error: action.payload
            };
        
        default:
            return state;
    }
};
export default partnerCardGroupReducer;