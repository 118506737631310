import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Dialog, DialogContent, Pagination, DialogTitle, FormControl, IconButton, InputLabel, Menu, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,  Toolbar, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ListItemIcon from '@mui/material/ListItemIcon';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createBundleData, fetchBundleData } from '../../redux/actions/bundleDataActions';
import Loading from '../../components/Loading';

function BundleData() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    const [anchorEl, setAnchorEl] = useState(null);
    const menuOpen = Boolean(anchorEl);

    const handleMenuClick = (event, id) => {
        setAnchorEl(event.currentTarget);
        setEditCardId(id)

    };
    const handleMenuClose = () => {
        setAnchorEl(null);
        setEditCardId(null)
    };

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [search, setSearch] = useState('');
    const limit = 50;

    const handlePageChange = async (event, value) => {
        console.log("value", value);
        setPage(value);
        await dispatch(fetchBundleData(value, limit, search));
    };

    const bundleCard = useSelector((state) => state?.bundle?.bundleData?.data);
    console.log("Bundle Data", bundleCard);

    useEffect(() => {
        const getBundleData = async () => {
            setLoading(true);
            let rsp = await dispatch(fetchBundleData(page, limit));
            let total = rsp?.data?.total;
            let pages = Math.ceil(total / Number(limit));
            setTotalPages(pages);
            setLoading(false);
        };
        getBundleData();
    }, [dispatch, page, limit])
    const columns = [
        { id: 'id', name: 'Bundle Entry Id' },
        { id: 'customer_details', name: 'Customer Details' },
        { id: 'saurce', name: 'Saurce' },
        { id: 'campaign_amount', name: 'Campaign Amount' },
        { id: 'payment_status', name: 'Payment Status' },
        { id: 'multi_card_entries', name: 'Multi Card Entries' },
        { id: 'actions', name: 'Actions' }
    ];

    const handleCopyToClipboard = (id) => {
        navigator.clipboard.writeText(id)
            .then(() => {
                console.log('Copied to clipboard:', id);
                toast.success('Copied to clipboard!');
            })
            .catch((err) => {
                console.error('Failed to copy:', err);
            });
    };



    //edit cards 
    const [editCardId, setEditCardId] = useState('');
    const [editOpen, setEditOpen] = useState(false);
    const [editPaymentStatus, setEditPaymentStatus] = useState('');


    const functionBundleEdit = useCallback((card) => {
        console.log("inside Edit", card);
        setEditCardId(card.id);
        setEditOpen(true);
        setEditPaymentStatus(card?.payment_status);
    }, []);

    const closeBundleEditPopup = () => {
        setEditOpen(false);
    };

    const handleCardsEditSubmit = async (e) => {
        e.preventDefault();
        const updatedCards = {
            id: editCardId, payment_status: editPaymentStatus
        }
        setLoading(true);
        await createBundleData(updatedCards);
        await dispatch(fetchBundleData(page, limit));
        setLoading(false);


        closeBundleEditPopup();
    };

    // toggle Button

    const [showMultiCardValuesBtn, setShowMultiCardValuedBtn] = useState(false);

    const handleMultiCardValues = () => {
        setShowMultiCardValuedBtn(!showMultiCardValuesBtn);
    };

    useEffect(() => {

    }, [showMultiCardValuesBtn]);

    return (
        <div>
            <Box >
                <Toolbar sx={{ borderRadius: 2, flexWrap: 'wrap', display: 'flex', alignItems: 'center' }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        alignItems: 'center',
                        width: '100%',
                        justifyContent: { xs: 'flex-start', md: 'space-between' }
                    }}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                        }}>
                            <Typography sx={{
                                fontSize: { xs: '20px', sm: '24px', md: '28px' },
                                mb: { xs: 2, md: 0 }
                            }}>
                                Bundles Data
                            </Typography>
                            <Button
                                variant='outlined'
                                sx={{
                                    color: showMultiCardValuesBtn ? '#424242' : '#616161',
                                    borderColor: showMultiCardValuesBtn ? '#bdbdbd' : '#e0e0e0',
                                    backgroundColor: showMultiCardValuesBtn ? '#bdbdbd' : '#eeeeee',
                                    borderRadius: 4
                                }}
                                onClick={handleMultiCardValues}
                            >
                                Multi-Card-Values
                            </Button>
                            {/* <TextField
                                size="small"
                                placeholder="Search"
                                sx={{
                                    width: { xs: '100%', sm: '200px' },
                                    borderRadius: '20px',
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '20px'
                                    }
                                }}
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon
                                                style={{ cursor: 'pointer' }}
                                                onClick={handleSearch}
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                            /> */}
                        </Box>

                        <Box sx={{
                            display: 'flex',
                            gap: { xs: 1, md: 0.5 },
                            flexDirection: { xs: 'column', md: 'row' },
                            justifyContent: { xs: 'center', md: 'flex-end' },
                            width: { xs: '100%', md: 'auto' },
                            alignItems: 'center'
                        }}>
                            <Button
                                variant='contained'
                                sx={{
                                    backgroundColor: 'midnightblue',
                                    textTransform: 'none',
                                    fontSize: { xs: '12px', sm: '14px', md: '16px' },
                                    width: { xs: '100%', sm: 'auto' }
                                }}

                            >
                                Add Card
                            </Button>
                        </Box>
                    </Box>
                </Toolbar>


                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow >
                                {columns.map((column) => (
                                    <TableCell key={column.id}>{column.name}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <TableRow>
                                    <TableCell colSpan={columns.length} align="center">
                                        <Loading/>
                                    </TableCell>
                                </TableRow>
                            ) : (
                                bundleCard && bundleCard?.rows?.map((card, index) => (
                                    <TableRow key={index}>
                                        <TableCell onClick={() => handleCopyToClipboard(card.id)} style={{ cursor: 'pointer' }}>{card.id}</TableCell>
                                        <TableCell>
                                            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                                <Typography key={index} variant="caption" color="initial">
                                                    <span style={{ color: '#616161' }}>Customer Name: </span>{card.customer_name}
                                                </Typography>
                                                <Typography key={index} variant="caption" color="initial">
                                                    <span style={{ color: '#616161' }}>Customer Mobile: </span>{card.customer_mobile}
                                                </Typography>
                                                <Typography key={index} variant="caption" color="initial">
                                                    <span style={{ color: '#616161' }}>Customer Email: </span>{card.customer_email}
                                                </Typography>
                                            </Box>


                                        </TableCell>

                                        <TableCell>{card?.source}</TableCell>

                                        <TableCell>{card?.campaign_amount}</TableCell>
                                        <TableCell>{card?.payment_status}</TableCell>
                                        {showMultiCardValuesBtn ? (
                                            <>
                                                {card?.multi_card_entries?.map((multiCard, index) => (
                                                    <Typography key={index} variant="caption" color="initial">
                                                        <span style={{ color: '#616161' }}>Field Name: </span>{multiCard?.field_name} | <span style={{ color: '#616161' }}>Field Name: </span>{multiCard?.field_value}
                                                    </Typography>
                                                ))}
                                            </>
                                        ) : (
                                            <Typography variant="body1" color="initial"></Typography>
                                        )}

                                        <TableCell>
                                            <IconButton
                                                aria-controls="simple-menu"
                                                aria-haspopup="true"
                                                onClick={(event) => handleMenuClick(event, card.id)}
                                            >
                                                <MoreVertIcon />
                                            </IconButton>


                                            <Menu
                                                id="simple-menu"
                                                anchorEl={anchorEl}
                                                keepMounted
                                                open={menuOpen && editCardId === card.id}
                                                onClose={handleMenuClose}
                                            >
                                                <MenuItem
                                                    onClick={() => functionBundleEdit(card)}
                                                >
                                                    <ListItemIcon>
                                                        <EditIcon />
                                                    </ListItemIcon>
                                                    Payment Status
                                                </MenuItem>



                                                <br />
                                                <MenuItem
                                                    // onClick={() => functionDelete(card.id)}
                                                    sx={{
                                                        color: 'red'
                                                    }}>
                                                    <ListItemIcon sx={{
                                                        color: 'red'
                                                    }}>
                                                        <DeleteIcon />
                                                    </ListItemIcon>
                                                    Delete
                                                </MenuItem>
                                            </Menu>
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '1%' }}>
                    <Pagination count={totalPages} page={page} onChange={handlePageChange} color="primary" />
                </Box>
            </Box>
            <Dialog open={editOpen} fullWidth maxWidth="sm">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <DialogTitle sx={{ flexGrow: 1, textAlign: 'center', marginTop: 3 }}>
                        <Typography variant='h5' sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Edit Bundle Card</Typography>
                    </DialogTitle>
                    <IconButton onClick={closeBundleEditPopup}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DialogContent>
                    <form onSubmit={handleCardsEditSubmit}>
                        <Stack spacing={2} margin={2}>
                            <FormControl>
                                <InputLabel id="demo-simple-select-label">Payment Status</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Payment Status"
                                    value={editPaymentStatus}
                                    onChange={e => setEditPaymentStatus(e.target.value)}
                                >
                                    <MenuItem value="not_needed">Not Needed</MenuItem>
                                    <MenuItem value="captured">Captured</MenuItem>
                                </Select>
                            </FormControl>

                            <Stack sx={{ flexDirection: 'row', justifyContent: 'center', gap: 2 }}>
                                <Button variant='outlined' sx={{ textTransform: 'none' }} onClick={closeBundleEditPopup}>Cancel</Button>
                                <Button variant='contained' sx={{ textTransform: 'none' }} type='submit'>Update</Button>
                            </Stack>
                        </Stack>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default BundleData