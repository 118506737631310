import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { getSingleDigitalInvitationCardEntryData, updateDigitalCardEntryData } from '../../redux/actions/digitalInvitationCardEntryActions';
import { Stack, TextField} from '@mui/material';

const EditCounterChange = ({ open, handleClose, card }) => {

  const [newData, setNewData] = useState({
    id: '', edit_counter: ''
  });

  useEffect(() => {
    const fetchSingleDigitalCard = async () => {
      const response = await getSingleDigitalInvitationCardEntryData(card);
      console.log("data in digital card edit", response);
      setNewData(response?.data?.data);

    }
    fetchSingleDigitalCard();
  }, [card]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewData({ ...newData, [name]: value });
    console.log("New Data", newData);
  };

  const handleSubmit = async () => {
    await updateDigitalCardEntryData(newData);
    handleClose();
  }
  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Change Edit Counter</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <TextField
              label="Edit Counter"
              name="edit_counter"
              type='number'
              variant="outlined"
              value={newData.edit_counter}
              onChange={handleChange}
              required
              InputLabelProps={{ shrink: true }}
            />
          </Stack>
          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Cancel</Button>
          <Button onClick={handleSubmit} color="primary">Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default EditCounterChange