import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, Menu, MenuItem, Pagination, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { createCardGroupData, fetchCardGroup, updateCardGroupData } from '../../redux/actions/cardGroupActions';
import SearchIcon from '@mui/icons-material/Search';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from 'react-router-dom';
import Loading from '../../components/Loading';
import { toast } from 'react-toastify';
import { createFrameGroupData, deleteFrameGroupData, fetchFrameGroupData, updateFrameGroupData } from '../../redux/actions/frameGroupActions';


function FrameGroup() {
    const dispatch = useDispatch();

    // Destructure with default values to avoid undefined errors
    const frameGroup = useSelector((state) => state?.frameGroup?.frameGroupData?.data?.data);
    console.log("card group data", frameGroup);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);
    const menuOpen = Boolean(anchorEl);

    const handleMenuClick = (event, id) => {
        setAnchorEl(event.currentTarget);
        setEditFrameId(id);
        setDeleteFrameId(id);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
        setEditFrameId(null);
        setDeleteFrameId(null);
    };

    // Fetch card group data on component mount
    useEffect(() => {
        const fetchFrameData = async () => {
            setLoading(true);
            await dispatch(fetchFrameGroupData());
            setLoading(false);
        };
        fetchFrameData();
    }, [dispatch]);

    const columns = [
        { id: 'frame-id', name: 'Frame Id' },
        { id: 'name', name: 'Frame Name' },
        { id: 'description', name: 'Frame Description' },
        { id: 'order', name: 'Frame Order' },
        { id: 'type', name: 'Frame Type' },
        { id: 'status', name: 'Frame Status' },
        { id: 'actions', name: 'Actions' }
    ];

    // Add functionality
    const [open, setOpen] = useState(false);
    const [frameName, setFrameName] = useState('');
    const [frameDescription, setFrameDescription] = useState('');
    const [frameOrder, setFrameOrder] = useState('');



    const functionAdd = () => {
        openPopup();
    };

    const closePopup = () => {
        setOpen(false);
    };

    const openPopup = () => {
        setOpen(true);
    };

    const handleFrameGroupSubmit = async (e) => {
        e.preventDefault();
        const newFrameGroupData = {
            name: frameName,
            description: frameDescription,
            order: frameOrder,
        };
        const response = await createFrameGroupData(newFrameGroupData);
        // if (response?.status === 'success') {
        //     toast.success('Frame Group submitted successfully!', {
        //         autoClose: 3000,
        //     });
        // } else {
        //     toast.error('Failed to submitted frame Group. Please try again.', {
        //         autoClose: 3000,
        //     });
        // }
        await dispatch(fetchFrameGroupData());
        closePopup();
    };

    // Edit functionality 

    const [editOpen, setEditOpen] = useState(false);
    const [editFrameId, setEditFrameId] = useState('');
    const [editFrameName, setEditFrameName] = useState('');
    const [editFrameDesc, setEditFrameDesc] = useState('');
    const [editFrameOrder, setEditFrameOrder] = useState('');

    const functionEdit = useCallback((card) => {
        setEditFrameId(card.id);
        setEditFrameName(card.name);
        setEditFrameDesc(card.description);
        setEditFrameOrder(card.order);
        setEditOpen(true);
    }, []);

    const closeEditPopup = () => {
        setEditOpen(false);
    };

    const handleFrameGroupEditSubmit = async (e) => {
        e.preventDefault();
        const updateCardGroup = {
            id: editFrameId, name: editFrameName, order: editFrameOrder,
            description: editFrameDesc
        }

        const response = await updateFrameGroupData(updateCardGroup);
        // if (response?.payload?.status === 'success') {


        //     toast.success('Card updated successfully!', {
        //         autoClose: 3000, // Auto-close after 3 seconds
        //     });
        // } else {
        //     toast.error('Failed to update card. Please try again.', {
        //         autoClose: 3000,
        //     });
        // }
        await dispatch(fetchFrameGroupData());
        closeEditPopup();
    };


    // validation 
    const validateCreateFields = () => {
        return frameOrder !== '' && !isNaN(frameOrder);
    };

    //delete frame group 
    const [deleteFrameId, setDeleteFrameId] = useState();
    const [deleteOpen, setDeleteOpen] = useState(false);

    const functionFrameGroupDelete = useCallback((id) => {
        setDeleteFrameId(id);
        setDeleteOpen(true);
    }, []);

    const closeDeleteFramePopup = () => {
        setDeleteOpen(false);
    };

    const handleFrameDelete = async () => {
        if (deleteFrameId) {

            const response = await deleteFrameGroupData(deleteFrameId);
            await dispatch(fetchFrameGroupData());
            closeDeleteFramePopup();
            handleMenuClose();
        }

    };


    //navigation 
    const handleNavigation = (id) => {
        const queryParams = new URLSearchParams({
            group_id: id,
        }).toString();
        navigate(`/frame-group/frame-template?${queryParams}`);
    }

    return (
        <Box>
            <Toolbar sx={{ borderRadius: 2, flexWrap: 'wrap', display: 'flex', alignItems: 'center' }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: 'center',
                    width: '100%',
                    justifyContent: { xs: 'flex-start', md: 'space-between' }
                }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                    }}>
                        <Typography sx={{
                            fontSize: { xs: '20px', sm: '24px', md: '28px' },
                            mb: { xs: 2, md: 0 }
                        }}>
                            Frame Group
                        </Typography>
                        {/* <TextField
                            size="small"
                            placeholder="Search"
                            sx={{
                                width: { xs: '100%', sm: '200px' },
                                borderRadius: '20px',
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '20px'
                                }
                            }}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon
                                            style={{ cursor: 'pointer' }}
                                            onClick={handleSearch}
                                        />
                                    </InputAdornment>
                                ),
                            }}
                        /> */}
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        gap: { xs: 1, md: 0.5 },
                        flexDirection: { xs: 'column', md: 'row' },
                        justifyContent: { xs: 'center', md: 'flex-end' },
                        width: { xs: '100%', md: 'auto' },
                        alignItems: 'center'
                    }}>
                        <Button
                            variant='contained'
                            sx={{
                                backgroundColor: 'midnightblue',
                                textTransform: 'none',
                                fontSize: { xs: '12px', sm: '14px', md: '16px' },
                                width: { xs: '100%', sm: 'auto' }
                            }}
                            onClick={functionAdd}
                        >
                            Add Frame Group
                        </Button>
                    </Box>
                </Box>
            </Toolbar>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell key={column.id}>{column.name}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableRow>
                                <TableCell colSpan={columns.length} align="center">
                                    <Loading />
                                </TableCell>
                            </TableRow>
                        ) : (
                            <>
                                {frameGroup?.groups?.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell>{row.id}</TableCell>
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell>{row.description}</TableCell>
                                        <TableCell>{row.order}</TableCell>
                                        <TableCell>{row.type}</TableCell>
                                        <TableCell>{String(row.is_premium)}</TableCell>
                                        <TableCell>
                                            <IconButton
                                                aria-controls="simple-menu"
                                                aria-haspopup="true"
                                                onClick={(event) => handleMenuClick(event, row.id)}
                                            >
                                                <MoreVertIcon />
                                            </IconButton>
                                            <Menu
                                                id="simple-menu"
                                                anchorEl={anchorEl}
                                                keepMounted
                                                open={menuOpen && editFrameId === row.id && deleteFrameId === row.id}
                                                onClose={handleMenuClose}
                                            >
                                                <MenuItem onClick={() => functionEdit(row)}>Edit</MenuItem>
                                                <MenuItem onClick={() => functionFrameGroupDelete(row.id)}>Delete</MenuItem>
                                                <MenuItem onClick={() => handleNavigation(row?.id)}>Cards</MenuItem>
                                            </Menu>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </>
                        )}

                    </TableBody>
                </Table>
            </TableContainer>
            {/* <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '1%' }}>
                <Pagination count={totalPages} page={page} onChange={handlePageChange} color="primary" />
            </Box> */}
            <Dialog open={open} onClose={closePopup}>
                <DialogTitle>
                    Add Frame Group
                    <IconButton onClick={closePopup}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <form onSubmit={handleFrameGroupSubmit}>
                        <TextField
                            label="Frame Name"
                            value={frameName}
                            onChange={(e) => setFrameName(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Frame Description"
                            value={frameDescription}
                            onChange={(e) => setFrameDescription(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            type='number'
                            required
                            label="Frame Order"
                            value={frameOrder}
                            error={isNaN(frameOrder)}
                            onChange={(e) => setFrameOrder(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <DialogActions>
                            <Button onClick={closePopup} color="primary">Cancel</Button>
                            <Button
                                type="submit"
                                color="primary"
                                disabled={!validateCreateFields()}
                            >
                                Submit
                            </Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>


            <Dialog open={editOpen} fullWidth maxWidth="sm">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <DialogTitle sx={{ flexGrow: 1, textAlign: 'center', marginTop: 3 }}>
                        <Typography variant='h5' sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Edit Frame Group</Typography>
                    </DialogTitle>
                    <IconButton onClick={closeEditPopup}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DialogContent>
                    <form onSubmit={handleFrameGroupEditSubmit}>
                        <Stack spacing={2} margin={2}>

                            <TextField required variant='outlined' value={editFrameName} onChange={e => setEditFrameName(e.target.value)} label="Frame Name" />
                            <TextField required variant='outlined' value={editFrameDesc} onChange={e => setEditFrameDesc(e.target.value)} label="Frame Description" />
                            <TextField required variant='outlined' value={editFrameOrder} onChange={e => setEditFrameOrder(e.target.value)} label="Frame Order" />

                            <Stack sx={{ flexDirection: 'row', justifyContent: 'center', gap: 2 }}>
                                <Button variant='outlined' sx={{ textTransform: 'none' }} onClick={closeEditPopup}>Cancel</Button>
                                <Button variant='contained' sx={{ textTransform: 'none' }} type='submit'>Update</Button>
                            </Stack>
                        </Stack>
                    </form>
                </DialogContent>
            </Dialog>

            <Dialog
                open={deleteOpen}
                fullWidth
                maxWidth="sm"
                PaperProps={{
                    sx: {
                        borderRadius: 3,
                        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                        padding: 3,
                        backgroundColor: '#f9f9f9',
                    },
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderBottom: '1px solid #ddd',
                        paddingBottom: 1,
                    }}
                >
                    <DialogTitle
                        sx={{
                            flexGrow: 1,
                            textAlign: 'center',
                            marginTop: 2,
                            fontSize: '1.5rem',
                            fontWeight: 'bold',
                            color: '#333',
                        }}
                    >
                        <Typography
                            variant="h5"
                            sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}
                        >
                            Delete Frame
                        </Typography>
                    </DialogTitle>
                    <IconButton onClick={closeDeleteFramePopup} sx={{ color: '#555' }}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DialogContent sx={{ marginTop: 3, textAlign: 'center' }}>
                    <Typography
                        variant="subtitle1"
                        sx={{
                            fontSize: '1.1rem',
                            color: '#666',
                        }}
                    >
                        Are you sure you want to delete this Frame?
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center', gap: 2, marginTop: 2 }}>
                    <Button
                        variant="outlined"
                        sx={{
                            borderColor: '#ddd',
                            color: '#333',
                            '&:hover': {
                                backgroundColor: '#f0f0f0',
                                borderColor: '#ccc',
                            },
                        }}
                        onClick={closeDeleteFramePopup}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        sx={{
                            color: '#fff',
                            boxShadow: '0px 4px 10px rgba(255, 0, 0, 0.3)',
                            '&:hover': {
                                backgroundColor: '#d32f2f',
                            },
                        }}
                        onClick={handleFrameDelete}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

        </Box>
    );
}

export default FrameGroup;