import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { getSingleDigitalInvitationCardEntryData, updateDigitalCardEntryData } from '../../redux/actions/digitalInvitationCardEntryActions';
import { Box, Stack, TextField } from '@mui/material';

const EditBasicDetails = ({ open, handleClose, card }) => {
    // const [newData, setNewData] = useState({ name: '', address: '', mobile: '' });
    const [newData, setNewData] = useState({
        title: '', mobile_number: '', thumbnail: '', final_url: '', final_mime: '',
        estimated_minutes_to_complete: '', watermarked_thumbnail: '', watermarked_url: '', watermarked_mime: '', id: ''
    });

    useEffect(() => {
        const fetchSingleDigitalCard = async () => {
            const response = await getSingleDigitalInvitationCardEntryData(card);
            console.log("data in digital card edit", response);
            setNewData(response?.data?.data);

        }
        fetchSingleDigitalCard();
    }, [card]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewData({ ...newData, [name]: value });
        console.log("New Data", newData);
    };

    const handleSubmit = async () => {
        await updateDigitalCardEntryData(newData);
        handleClose();
    }
    return (
        <>

            <Dialog open={open} onClose={handleClose} fullWidth>
                <DialogTitle>Edit Basic Details</DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <TextField
                            label="Title"
                            name="title"
                            variant="outlined"
                            value={newData.title}
                            onChange={handleChange}
                            required
                            InputLabelProps={{ shrink: true }}
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                            <TextField
                                label="Mobile Number"
                                name="mobile_number"
                                type='number'
                                variant="outlined"
                                value={newData.mobile_number}
                                onChange={handleChange}
                                required
                                InputLabelProps={{ shrink: true }}
                            />
                            <TextField
                                label="Final Mine"
                                name="final_mime"
                                variant="outlined"
                                value={newData.final_mime}
                                onChange={handleChange}
                                required
                                InputLabelProps={{ shrink: true }}
                            />
                            <TextField
                                label="Estimated Minutes To Complete"
                                name="estimated_minutes_to_complete"
                                variant="outlined"
                                value={newData.estimated_minutes_to_complete}
                                onChange={handleChange}
                                required
                                InputLabelProps={{ shrink: true }}
                            />
                        </Box>

                        <TextField
                            label="Thumbnail"
                            name="thumbnail"
                            variant="outlined"
                            value={newData.thumbnail}
                            onChange={handleChange}
                            required
                            InputLabelProps={{ shrink: true }}
                        />


                        <TextField
                            label="Final Url"
                            name="final_url"
                            variant="outlined"
                            value={newData.final_url}
                            onChange={handleChange}
                            required
                            InputLabelProps={{ shrink: true }}
                        />

                        <TextField
                            label="WaterMaked Thumbnail"
                            name="watermarked_thumbnail"
                            variant="outlined"
                            value={newData.watermarked_thumbnail}
                            onChange={handleChange}
                            required
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            label="Watermarked Url"
                            name="watermarked_url"
                            variant="outlined"
                            value={newData.watermarked_url}
                            onChange={handleChange}
                            required
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            label="Watermarked Mime"
                            name="watermarked_mime"
                            variant="outlined"
                            value={newData.watermarked_mime}
                            onChange={handleChange}
                            required
                            InputLabelProps={{ shrink: true }}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Cancel</Button>
                    <Button onClick={handleSubmit} color="primary">Save</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default EditBasicDetails;
