import * as actionTypes from '../actionTypes.js';

const initialState = {
    partnerCardSampleData: null,
    loading: false,
    error: null,
};

const partnerCardSampleReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_PARTNER_CARD_SAMPLE_DATA_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.FETCH_PARTNER_CARD_SAMPLE_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                partnerCardSampleData: action.payload,
            };
        case actionTypes.FETCH_PARTNER_CARD_SAMPLE_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case actionTypes.GET_SINGLE_PARTNER_CARD_SAMPLE_DATA_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.GET_SINGLE_PARTNER_CARD_SAMPLE_DATA_SUCCESS:
            return {
                ...state,
                partnerCardSampleData: action.payload,
                loading: false,
                error: null,
            };
        case actionTypes.GET_SINGLE_PARTNER_CARD_SAMPLE_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case actionTypes.CREATE_PARTNER_CARD_SAMPLE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.CREATE_PARTNER_CARD_SAMPLE_SUCCESS:
            state.partnerCardSampleData.data.rows = [...state.partnerCardSampleData?.data?.rows, action.payload]
            return {
                ...state,
                loading: false,
                partnerCardSampleData: state.partnerCardSampleData,
            };
        case actionTypes.CREATE_PARTNER_CARD_SAMPLE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case actionTypes.UPDATE_PARTNER_CARD_SAMPLE_REQUEST:
            return{
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.UPDATE_PARTNER_CARD_SAMPLE_SUCCESS:
            state.partnerCardSampleData.data.rows = state.partnerCardSampleData?.data?.rows.map(card =>
                card.id === action.payload.id ? action.payload : card)
            return{
                ...state,
                loading: false,
                partnerCardSampleData: state.partnerCardSampleData
            };
        case actionTypes.UPDATE_PARTNER_CARD_SAMPLE_FAILURE:
            return{
                ...state,
                loading: false,
                error: action.payload
            };


        default:
            return state
    }
};
export default partnerCardSampleReducer;