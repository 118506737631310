import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { fetchSingleDigitalInvitationCardsEntryData } from '../../redux/actions/digitalInvitationCardEntryActions';
import { Box, Card, Grid, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';

const ViewDigitalCard = () => {
    const params = useParams();

    console.log("params", params);
    useEffect(() => {
    
    }, [params.id]);

    const [newData, setNewData] = useState();
    const dispatch = useDispatch();
    useEffect(() => {
        const fetchSingleDigitalCard = async () => {
            const response = await dispatch(fetchSingleDigitalInvitationCardsEntryData(params.id));
            console.log("data in digital card view", response);
            setNewData(response?.data?.data);
        }
        fetchSingleDigitalCard();
    }, [dispatch]);


    useEffect(() => {
        console.log("Updated newData in digital card view", newData);
    }, [newData]);

    return (
        <div>
            <Typography variant="h5" sx={{ fontWeight: 'bold', marginTop: 2, textAlign: 'start' }}>
                Digital Card Details
            </Typography>
            <Grid container spacing={2} >
                <Grid item xs={12} md={12} >
                    <Card sx={{ display: 'flex', flexDirection: 'column', p: 1, borderRadius: 2 }}>
                        <Grid container spacing={6}>
                            <Grid item xs={12} md={2}>
                                <Box
                                    sx={{ width: '200px', height: '250px', flexShrink: 0 }}
                                // onClick={() => handleClickOpen(newData.final_image)}
                                >

                                    <img src={newData?.thumbnail} alt="No picture" style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '8px', cursor: 'pointer' }} />

                                </Box>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: 1 }}>
                                    <Typography variant="body1" color="initial" sx={{ textAlign: 'left' }}>
                                        <span style={{ color: '#616161' }}>Card name: </span> {newData?.title || '-'}
                                    </Typography>
                                    <Typography variant="body1" color="initial" sx={{ textAlign: 'left' }}>
                                        <span style={{ color: '#616161' }}>Status: </span> {newData?.status || '-'} |  <span style={{ color: '#616161' }}>Payment Status: </span> {newData?.payment_status || '-'}
                                    </Typography>

                                    <Typography variant="body1" color="initial" sx={{ textAlign: 'left' }}>
                                        <span style={{ color: '#616161' }}>Credit Total: </span>{newData?.credit_total} | <span style={{ color: '#616161' }}>Credit Used: </span>{newData?.credit_used}
                                    </Typography>

                                    <Typography variant="body1" color="initial" sx={{ textAlign: 'left' }}>
                                        {/* <span style={{ color: '#616161' }}>Any comments: </span> {newData.comment} */}
                                        <span style={{ color: "#616161" }}>Created at: </span>
                                        {new Date(newData?.createdAt).toLocaleDateString("en-GB", {
                                            day: "2-digit",
                                            month: "long",
                                            year: "numeric",
                                        })}{" "}
                                        {new Date(newData?.createdAt).toLocaleTimeString("en-US", {
                                            hour: "2-digit",
                                            minute: "2-digit",
                                            hour12: true,
                                        })}
                                    </Typography>
                                    <Typography variant="body1" color="initial" sx={{ textAlign: 'left' }}>
                                        <span style={{ color: "#616161" }}>Updated at: </span>
                                        {new Date(newData?.updatedAt).toLocaleDateString("en-GB", {
                                            day: "2-digit",
                                            month: "long",
                                            year: "numeric",
                                        })}{" "}
                                        {new Date(newData?.updatedAt).toLocaleTimeString("en-US", {
                                            hour: "2-digit",
                                            minute: "2-digit",
                                            hour12: true,
                                        })}
                                    </Typography>
                                    <Typography variant="body1" color="initial" sx={{ textAlign: 'left' }}>
                                        <span style={{ color: '#616161' }}>Coupon Code Amount: </span>{newData?.coupon_code_amount} | <span style={{ color: '#616161' }}>Actual Paid Amount: </span>{newData?.actual_paid_amount}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                {newData?.digital_invite_card_entries?.map((card, index) => (
                                    <Box key={index} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: 1 }}>
                                        <Typography variant="body1" color="initial" sx={{ textAlign: 'left' }}>
                                            <span style={{ color: '#616161' }}>{card?.field_name}: </span> {card?.field_value || '-'}
                                        </Typography>
                                    </Box>
                                ))}

                            </Grid>

                        </Grid>
                    </Card>
                </Grid>


            </Grid>
        </div>
    )
}

export default ViewDigitalCard