import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";


export const fetchPartnerCardGroupRequest = () => ({
    type: actionTypes.FETCH_PARTNER_CARD_GROUP_DATA_REQUEST,
});
export const fetchPartnerCardGroupSuccess = (data) => ({
    type: actionTypes.FETCH_PARTNER_CARD_GROUP_DATA_SUCCESS,
    payload: data,
});
export const fetchPartnerCardGroupFailure = (error) => ({
    type: actionTypes.FETCH_PARTNER_CARD_GROUP_DATA_FAILURE,
    payload: error,
});

export const fetchPartnerCardGroup = (page, limit) => {

    return async (dispatch) => {
        dispatch(fetchPartnerCardGroupRequest());
        try {
            const {data} = await api.partnerCardGroup(page, limit);
            dispatch(fetchPartnerCardGroupSuccess(data));
            console.log("data in partner action", data);
            return data;
        } catch (error) {
            dispatch(fetchPartnerCardGroupFailure(error));
        }
    };
};

export const createPartnerCardGroupData = async (newCardData) => {
    const {data} = await api.createPartnerCardGroup(newCardData);
    return data;
};

export const updatePartnerCardGroupRequest = () => ({
    type: actionTypes.UPDATE_PARTNER_CARD_GROUP_DATA_REQUEST,
});
export const updatePartnerCardGroupSuccess = (data) => ({
    type: actionTypes.UPDATE_PARTNER_CARD_GROUP_DATA_SUCCESS,
    payload: data,
});
export const updatePartnerCardGroupFailure = (error) => ({
    type: actionTypes.UPDATE_PARTNER_CARD_GROUP_DATA_FAILURE,
    payload: error,
});

export const updatePartnerCardGroupData = (updateData) => {
    return async (dispatch) => {
        dispatch(updatePartnerCardGroupRequest());
        try{
            const response = await api.editPartnerCardGroup(updateData.id, updateData);
            const updatedPartnerCard = response?.data;
            const successAction = updatePartnerCardGroupSuccess(updatedPartnerCard);
            dispatch(successAction);
            return successAction;

        } catch(error) {
            const failureAction = updatePartnerCardGroupFailure(error.message);
            dispatch(failureAction);
            return failureAction;
        }
    };
};





